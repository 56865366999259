.signupContainer{
    width: 720px;
    max-width: 100%;
    margin: 20px auto 30px;
    padding: 30px 40px;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 12px;
    background-color: #fff;
    @media (max-width: 640px) {
        margin: 20px auto 0px;
        padding: 20px 15px;
        box-shadow: none;
        border-radius: 0;
    }
    .title{
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        margin-bottom: 24px;
        @media (max-width: 640px) {
            font-size: 18px;
            line-height: 27px;
            margin-bottom: 20px;
        }
    }
}
.signUpWrapper {
    position: relative;
    .authBg{
        position: absolute;
        right: 0;
        z-index: -1;
        top: 0;
    }
}
.signUplogo{
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    .logo{
        height: 50px;
        width: auto;
        @media (max-width: 640px) {
            height: 40px;
        }
    }
    .signInTop{
        @media (max-width: 640px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        span{
            font-size: 14px;
            line-height: 21px;
            @media (max-width: 640px) {
                margin-bottom: 4px;
            }
        }
        a{
            font-size: 14px;
            font-weight: 600;
            padding: 8px 13px;
            display: inline-block;
            min-width: 86px;
            height: 32px;
            cursor: pointer;
            background-color: var(--primaryBlue);
            border-radius: 100px;
            color: #fff;
            text-align: center;
            transition: 0.3s ease;
            margin-left: 12px;
            &:hover{
                background-color: var(--btnHover);
            }
        }
    }
}
.signupForm{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 0 -12px;
    .signupFormCol{
        flex: 0 0 50%;
        padding: 0 12px;
        margin-bottom: 24px;
        @media (max-width: 640px) {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 16px;
        }
    }
    .signupFormCol12{
        flex: 0 0 100%;
        padding: 0 12px;
        margin-bottom: 24px;
    }
    .signupCta{
        flex: 0 0 364px;
        padding: 0 12px;
        margin: 12px auto 16px;
        margin-bottom: 16px;
        max-width: 100%;
    }
    .divider{
        width: 340px;
        margin: 0 auto;
        max-width: 100%;
    }
    .toggleLogin{
        margin-top: 10px;
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
        span{
            margin-right: 4px;
        }
    }
}
.reqCodeText{
    text-align: right;
    margin-top: 8px;
}
.referralCodeInput{
    height: 48px;
    border: 1px solid #B6B6B6;
    width: 300px;
    border-radius: 8px;
    margin-top: 10px;
    max-width: 100%;
    padding: 0 10px;
    font-size: 16px;
}

.termCheck{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .termText{
        font-size: 16px;
        line-height: 24px;
        flex: 1 0 0;
    }
    .checkError{
        color: var(--inputError);
        flex: 0 0 100%;
        margin-top: 10px;
        font-size: 14px;
        font-weight: 500;
    }
    :global{
        .MuiFormControlLabel-root{
            margin-right: 5px;
            margin-top: -6px;
        }
        .MuiButtonBase-root{
            &.Mui-checked{
                color: var(--secondaryOrange);
            }
        }
    }
}

.termsModalHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    background-color: #fff;
    padding: 24px 24px 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    .title {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      color: #000;
    }
    .closeCta {
      font-size: 35px;
      cursor: pointer;
      color: var(--primaryBlue);
    }
}
.termsContent{
    font-size: 14px;
    color: var(--textColor);
    line-height: 24px;
    .contentSec{
        margin-bottom: 20px;
    }
    .subHead{
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
        line-height: 20px;
    }
    p{
        margin-bottom: 10px;
    }
    ol, ul{
        padding-left: 25px;
        margin-bottom: 20px;
    }
}

.registrationDiv {
    width: 332px;
    max-width: 100%;
    position: relative;
    .registrationLoader{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #ffffffa1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}


.modalWrap {
    position: relative;
    // padding-top: 20px;
    // text-align: center;
    .title {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .closeCta {
      background-color: transparent;
      font-size: 30px;
      position: absolute;
      top: -5px;
      right: 0;
      height: 30px;
      width: 30px;
      line-height: 30px;
      padding: 0;
    }
    span {
      color: var(--inputError);
    }
  }