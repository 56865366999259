/* tabs css start */
.shalinaTabs{
    .custTabs{
        margin-bottom: 20px;
        :global{
            .MuiTabs-flexContainer{
                @media (max-width: 640px) {
                    overflow-x: auto;
                }
            }
            .MuiTab-root{
                padding: 0 13px;
                text-transform: capitalize;
                font-family: inherit;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                margin-right: 60px;
                color: #fff;
                min-width: auto;
                @media (max-width: 640px) {
                    margin-right: 24px;
                }
                &.Mui-selected{
                  font-weight: 600;
                }
            }
            .MuiTabs-indicator{
                height: 3px;
                background-color: var(--secondaryOrange);
            }
        }
    }
    .custTabContent{
        position: relative;
        :global{
            .MuiBox-root{
                padding: 24px 0 0;
            }
            .MuiTypography-root{
                font-family: var(--primaryFont);
            }
        }
    }
}
/* tabs css start */