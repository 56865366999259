.slideItem{
    padding: 0 8px;
}
.cardContainer{
    margin: 7px 0 10px;
}
.cardWrapper{
    padding: 12px;
    border-radius: 12px;
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.1490196078);
    background-color: #fff;
    position: relative;
    .contentType{
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-bottom: 12px;
        img{
            width: auto;
            height: 14px;
        }
        span{
            margin-left: 6px;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
        }
    }
    .cardTitle{
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        cursor: pointer;
        min-height: 60px;
        margin-bottom: 12px;
        transition: 0.2s ease;
        &:hover{
            color: var(--primaryBlue);
        }
    }
    .publisher{
        color: var(--neutral2);
        font-size: 12px;
        line-height: 18px;
        margin-top: 7px;
        padding-top: 7px;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        border-top: 1px solid #E0E0E0;
        span{
            color: var(--primaryBlue);
            font-weight: 500;
            margin-right: 4px;
        }
    }
    .dateWrapper{
        display: flex;
        align-items: center;
    }
    .dateTime{
        display: flex;
        align-items: center;
        font-size: 12px;
        color: var(--neutral2);
        svg{
            margin-right: 6px;
        }
        span{
            color: var(--textColor);
            font-weight: 500;
            margin-right: 4px;
        }
        &.date{
            margin-right: 18px;
        }
    }
}