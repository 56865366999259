.modifyModal {
    :global {
        .MuiPaper-root {
            width: 630px;
            max-width: 100%;
            border-radius: 12px;
            margin: 0 16px;
        }

        .MuiDialogContent-root {
            padding: 24px;
            color: var(--textColor);

            @media (max-width: 640px) {
                padding: 16px;
            }
        }
    }

    .helpdeskHead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;

        .title {
            font-size: 20px;
            line-height: 30px;
            font-weight: 500;
            color: #000;
        }

        .closeCta {
            font-size: 35px;
            cursor: pointer;
        }
    }
}

.helpdeskSubmit {
    padding: 24px;
    @media (max-width: 640px) {
        padding: 10px;
    }
    img {
        max-width: 100%;
        display: block;
        margin: 0 auto 5px;
    }

    .title {
        margin-bottom: 12px;
        font-size: 24px;
        line-height: 36px;
        font-weight: 600;
        text-align: center;
    }

    .msg {
        text-align: center;
        font-size: 20px;
        line-height: 30px;
        margin: 0 auto 25px;
    }
    .modalButtons{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        .modalButton{
            flex: 0 0 230px;
            max-width: 50%;
            padding: 0 10px;
            @media (max-width: 640px) {
                max-width: 100%;
            }
            button{
                line-height: 20px;
            }
            &.returnHome{
                @media (max-width: 640px) {
                    margin-bottom: 15px;
                }
                button{
                    background-color: transparent;
                    color: var(--primaryBlue);
                }
            }
        }
    }
}