.surveyPage {
  padding-bottom: 40px;
  min-height: 600px;
}
.surveyTop {
  background-color: var(--primaryBlue);
  padding-bottom: 24px;
  @media (max-width: 640px) {
    padding-bottom: 20px;
  }
  .head {
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    margin-bottom: 16px;
    padding-top: 25px;
    @media (max-width: 640px) {
      font-size: 18px;
      line-height: 27px;
    }
  }
}
.surveyRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
  padding-top: 30px;
  @media (max-width: 640px) {
    padding-top: 20px;
  }
  .surveyCol8 {
    flex: 0 0 74%;
    max-width: 74%;
    padding: 0 8px;
    @media (max-width: 640px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
    :global {
      .infinite-scroll-component {
        overflow: visible !important;
      }
    }
  }
  .surveyCol4 {
    flex: 0 0 26%;
    max-width: 26%;
    padding: 0 8px;
    @media (max-width: 640px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
.surveyList {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0 -8px;
  @media (max-width: 640px) {
    margin-bottom: 20px;
  }
  .surveyCol {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    margin-bottom: 16px;
    padding: 0 8px;
    width: 100% !important;
    @media (max-width: 640px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
.surveyQuickLinks {
  position: sticky;
  top: 16px;
  z-index: 2;
}

/* survey card start */
.cardContainer {
}
.cardWrapper {
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0px 4px 8px 0px #00000026;
  background-color: #fff;
  position: relative;
  .contentType {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-bottom: 18px;
    span {
      margin-left: 6px;
    }
    svg path {
      fill: var(--primaryBlue);
    }
    img {
      width: auto;
      height: 14px;
    }
  }
  .titleImg {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    min-height: 96px;
    .cardTitle {
      flex: 1 0;
      max-width: calc(100% - 72px);
      padding-right: 14px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      cursor: pointer;
    }
    img {
      height: 72px;
      width: 72px;
      object-fit: cover;
      object-position: center;
      border-radius: 12px;
    }
  }
  .date {
    color: var(--neutral2);
    margin-top: 26px;
    font-size: 12px;
    align-items: center;
    display: flex;
    svg {
      margin-right: 6px;
    }
    span {
      color: var(--textColor);
      font-weight: 500;
      margin-right: 4px;
    }
  }
  .postBy {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: var(--neutral2);
    border-top: 1px solid #e0e0e0;
    padding-top: 16px;
    margin-top: 16px;
    svg {
      margin-right: 8px;
      max-width: 22px;
      max-height: 22px;
    }
    span {
      color: var(--primaryBlue);
      font-weight: 500;
      margin-right: 4px;
    }
  }
}
/* survey card end */

.contentNotFoundList {
  img {
    margin: 0 auto 20px;
    display: block;
    max-width: 100%;
  }
  .notFoundText {
    text-align: center;
    h3 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 12px;
    }
  }
}
.contentNotFound {
  width: 510px;
  max-width: 100%;
  margin: 20px auto;
  text-align: center;
  .notFoundImg {
    margin-bottom: 35px;
    img {
      max-width: 100%;
    }
  }
  h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: var(--primaryBlue);
    margin-bottom: 10px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    color: var(--neutral2);
  }
}
