.custTabsWrapper {
    .custTabs {
        background-color: #E6F0FA;
        padding: 16px 24px 0;

        @media (max-width: 640px) {
            margin: 0 -15px;
            padding: 16px 15px 0;
        }

        :global {
            .MuiTab-root {
                padding: 0;
                text-transform: capitalize;
                font-family: inherit;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                margin-right: 60px;
                color: var(--primaryBlue);
                min-width: auto;

                &.Mui-selected {
                    font-weight: 600;
                }

                @media (max-width: 640px) {
                    margin-right: 70px;
                }
            }

            .MuiTabs-flexContainer {
                overflow-x: auto;
            }

            .MuiTabs-indicator {
                height: 3px;
                background-color: var(--secondaryOrange);
            }
        }
    }

    .custTabContent {
        position: relative;

        :global {
            .MuiBox-root {
                padding: 24px 0 0;
            }

            .MuiTypography-root {
                font-family: var(--primaryFont);
            }
        }
    }
}