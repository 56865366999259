.custTabs{
    padding: 24px 24px 0;
    box-shadow: 0px 4px 15px 0px #00000026;
    border-radius: 16px 16px 0 0;
    background-color: #fff;
    @media (max-width: 640px) {
        padding: 20px 15px 0;
        border-radius: 0;
    }
    :global{
        
        .MuiTab-root{
            padding: 0;
            text-transform: capitalize;
            font-family: inherit;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-right: 60px;
            color: var(--primaryBlue);
            &.Mui-selected{
              font-weight: 600;
              color: var(--primaryBlue);
            }
            &:last-child{
                margin-right: 0;
            }
            @media (max-width: 640px) {
                margin-right: 24px;
            }
        }
        .MuiTabs-indicator{
            height: 3px;
            background-color: var(--secondaryOrange);
        }
        .MuiTabs-flexContainer{
            overflow-x: auto;
        }
    }
}
.custTabContent{
    :global{
        .MuiTypography-root{
            font-family: var(--primaryFont);
        }
        .MuiBox-root{
            padding: 12px 24px 24px;
            @media (max-width: 640px) {
                padding: 20px 15px;
            }
        }
    }
}