.requestTabs {
  .custTabs {
    background-color: #e6f0fa;
    padding: 16px 24px 0;
    margin: 0 -24px;

    @media (max-width: 640px) {
      padding: 0px 15px 0;
      margin: 0 -15px;
    }

    :global {
      .MuiTab-root {
        padding: 0;
        text-transform: capitalize;
        font-family: inherit;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-right: 60px;
        color: var(--primaryBlue);

        &.Mui-selected {
          font-weight: 600;
        }

        &:last-child {
          margin-right: 0;
        }

        @media (max-width: 640px) {
          margin-right: 24px;
        }
      }

      .MuiTabs-indicator {
        height: 3px;
        background-color: var(--secondaryOrange);
      }
    }
  }

  .custTabContent {
    padding-top: 30px;

    @media (max-width: 640px) {
      padding-top: 20px;
    }

    :global {
      .MuiBox-root {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

/* request css start */
.statusCtas {
  display: flex;
  align-items: center;
  margin: 0 -4px 30px;

  button {
    min-width: 67px;
    height: 30px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 100px;
    margin: 0 4px;
    background-color: transparent;
    color: var(--primaryBlue);
    border: 1px solid var(--primaryBlue);

    &.avtive {
      background-color: var(--lightBlue);
    }
  }
}

.requestList {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;

  .requestCol {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    padding: 0 8px;
    margin-bottom: 16px;
  }
}

.requestCard {
  padding: 12px;
  box-shadow: 0px 3px 9px 0px #00000026;
  border-radius: 12px;

  .reqStatus {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    font-size: 12px;

    span {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      margin-right: 8px;
      background-color: var(--green);
    }
  }

  .cardTitle {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    cursor: pointer;
    display: -webkit-box;
    overflow: hidden;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    transition: 0.2s ease;
    min-height: 66px;

    &:hover {
      color: var(--primaryBlue);
    }
  }

  .shotText {
    padding-top: 12px;
    margin-top: 12px;
    border-top: 1px solid #e0e0e0;
    font-size: 14px;
    line-height: 21px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    min-height: 42px;
  }

  .dateTime {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: var(--neutral2);
    margin-bottom: 25px;
    margin-top: 12px;
    min-height: 18px;

    svg {
      margin-right: 6px;
    }

    .date,
    .time {
      display: flex;
      align-items: center;

      span {
        color: var(--textColor);
        font-weight: 500;
        margin-right: 4px;
      }
    }

    .date {
      margin-right: 18px;
    }
  }

  .actionCtas {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0 -4px;

    button {
      border: 1px solid var(--primaryBlue);
      color: var(--primaryBlue);
      font-size: 14px;
      font-weight: 600;
      min-width: 86px;
      height: 32px;
      margin: 0 4px;
      border-radius: 100px;
      background-color: transparent;

      &.modify {
        background-color: var(--primaryBlue);
        color: #fff;
      }

      &:disabled {
        opacity: 0.4;
        cursor: default;
      }
    }
  }
}

.modifyModal {
  :global {
    .MuiPaper-root {
      width: 510px;
      max-width: 100%;
      border-radius: 12px;
    }

    .MuiDialogContent-root {
      padding: 24px;
      color: var(--textColor);

      @media (max-width: 640px) {
        padding: 16px;
      }
    }
  }

  .helpdeskHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    .title {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      color: #000;
    }

    .closeCta {
      font-size: 35px;
      cursor: pointer;
    }
  }
}
.helpdeskHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  .title {
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
  }

  .closeCta {
    font-size: 35px;
    cursor: pointer;
  }
}
.shalinaTextArea {
  p {
    margin-top: 6px;
    color: var(--errorColor);
  }
  .textAreaLabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    .astrick {
      margin-left: 4px;
      color: var(--errorColor);
    }
    .count {
      font-size: 12px;
      font-weight: 400;
      color: #909090;
    }
    .dialogCount {
      font-size: 12px;
      font-weight: 400;
      color: #909090;
    }
  }
}

.shalinaUploadCta {
  .uploadLabel {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
  }

  .uploadWrapper {
    position: relative;
    border: 1px solid var(--primaryBlue);
    border-radius: 100px;
    height: 44px;
    display: flex;
    text-align: center;
    padding: 10px 10px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    color: var(--primaryBlue);
    font-size: 14px;
    font-weight: 500;

    input {
      opacity: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      cursor: pointer;
    }

    svg {
      margin-right: 10px;
    }
  }
}
.helpdeskForm {
  .helpdeskFormCol {
    margin-bottom: 24px;
  }
}
.helpdeskSubmit {
  padding: 24px;
  img {
    max-width: 100%;
    display: block;
    margin: 0 auto 5px;
  }

  .title {
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    text-align: center;
  }

  .msg {
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    margin: 0 auto 25px;
  }
}
.revokeWrapper {
  .revokeTitle {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    margin-bottom: 12px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  .revokeCtas {
    display: flex;
    align-items: center;
    margin: 0 -8px;
    button {
      border: 1px solid var(--primaryBlue);
      color: var(--primaryBlue);
      font-size: 16px;
      font-weight: 600;
      width: auto;
      flex: 1 0 0;
      height: 45px;
      margin: 0 8px;
      border-radius: 100px;
      background-color: transparent;
      &.sureCta {
        background-color: var(--primaryBlue);
        color: #fff;
      }
    }
  }
}
/* request css end */

/* request detail css start */
.reqDetail {
  .backToDetail {
    margin-bottom: 15px;

    span {
      display: inline-flex;
      align-items: center;
      color: var(--primaryBlue);
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;

      svg {
        transform: rotate(90deg);
        margin-right: 5px;
      }
    }
  }

  .title {
    font-size: 22px;
    line-height: 33px;
    font-weight: 500;
    color: var(--primaryBlue);
    margin-bottom: 20px;
  }

  .subTitle {
    font-size: 18px;
    list-style: 22px;
    font-weight: 500;
    margin-bottom: 40px;
  }
}

.detailList {
  .detailItem {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e0e0e0;

    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
    }

    .detailListtitle {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .detailListsubText {
      font-size: 14px;
      line-height: 21px;
    }
  }

  .reqStatus {
    .reqStep {
      position: relative;
      margin-top: 20px;
      padding-left: 30px;

      &::before {
        content: '';
        position: absolute;
        width: 2px;
        height: calc(100% + 10px);
        background-color: var(--green);
        left: 7px;
        top: -56px;
      }

      &:first-child {
        &::before {
          display: none;
        }
      }

      svg {
        position: absolute;
        top: 3px;
        left: 0;
      }

      .text {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;

        span {
          display: block;
          font-size: 14px;
          line-height: 21px;
          font-weight: 400;
          margin-top: 10px;
        }
      }
    }
  }
}

.detailCtas {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #e0e0e0;
  padding-top: 40px;
  margin: 0 -8px;

  button {
    min-width: 165px;
    margin: 0 8px;
    font-size: 16px;
    font-weight: 600;
    height: 45px;
    border-radius: 100px;
    background-color: transparent;
    border: 1px solid var(--primaryBlue);
    color: var(--primaryBlue);

    &.modify {
      background-color: var(--primaryBlue);
      color: #fff;
    }
  }
}

.downloadLoader {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fileNote {
  color: var(--neutral2);
  font-size: 13px;
  text-align: right;
  display: block;
  margin-top: 5px;
  margin-right: 5px;
}

/* request detail css end */
