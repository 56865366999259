/* Home banner css start */
.homeBanner{
    margin-bottom: 24px;
    position: relative;
    @media (max-width: 640px) {
        margin: 0 -15px 0px;
    }
    .title{
        font-size: 24px;
        line-height: 26px;
        font-weight: 500;
        color: #050505;
        margin-bottom: 24px;
    }
    .bannerBg{
        position: absolute;
        bottom: -20px;
        left: 0;
        z-index: 1;
    }
}
.homeSlider{
    background-color: var(--primaryBlue);
    padding: 25px 20px 25px;
    display: inline-block;
    width: 100%;
    border-radius: 16px;
    position: relative;
    @media (max-width: 640px) {
        border-radius: 0;
        padding: 20px 15px 30px;
    }
}
.homeBanner .homeSlider{
    :global{
        .slick-dots{
            bottom: -30px;
            li{
                button{
                    background-color: #ABABAB5E;
                    transition: 0.3s ease;
                }
                &.slick-active{
                    button{
                        background-color: var(--secondaryOrange);
                        width: 30px;
                        border-radius: 6px;
                    }
                }
            }
        }
    }
}
.cardContainer{
    padding: 20px 20px 15px;
    background-color: #fff;
    border-radius: 16px;
    position: relative;
    margin: 0 8px;
    @media (max-width: 640px) {
        padding: 12px;
    }
    .mostViewed{
        position: absolute;
        background-color: var(--lightOrange);
        top: 16px;
        right: 0;
        padding: 3px 14px;
        border-radius: 16px 0 0 16px;
        font-size: 12px;
        color: var(--primaryBlue);
        @media (max-width: 640px) {
            top: 14px;
        }
    }
}
.cardWrapper{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    .cardContent{
        flex: 1 0 0;
        // padding-right: 16px;
        @media (max-width: 640px) {
            padding-right: 0;
        }
    }
    .cardImg{
        position: absolute;
        top: 33px;
        right: 0;
        width: 88px;
        height: 88px;
        @media (max-width: 640px) {
            // flex: 0 0 60px;
            width: 60px;
            height: 60px;
            margin-top: 0px;
            position: absolute;
            right: 0px;
            top: 30px;
        }
        img{
            border-radius: 10px;
            width: 100%;
            height: 88px;
            object-fit: cover;
            @media (max-width: 640px) {
                height: 60px;
            }
        }
    }
}
.contentType{
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-bottom: 15px;
    min-height: 18px;
    @media (max-width: 640px) {
        margin-bottom: 12px;
    }
    svg{
        margin-right: 8px;
        path{fill: var(--primaryBlue);}
    }
    img {
        margin-right: 8px;
        max-height: 16px;
        width: auto;
        @media (max-width: 640px) {
            max-height: 14px;
        }
    }
    span{
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        @media (max-width: 640px) {
            width: calc(100% - 110px);
        }
    }
}
.cardContent{
    .cardTitle{
        font-size: 22px;
        font-weight: 500;
        line-height: 26px;
        margin-bottom: 25px;
        min-height: 80px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        cursor: pointer;
        transition: 0.2s ease;
        padding-right: 100px;
        word-break: break-word;
        &.noThumbnail{
            padding-right: 0px;
        }
        &:hover{
            color: var(--primaryBlue);
        }
        @media (max-width: 640px) {
            font-size: 15px;
            line-height: 20px;
            min-height: 60px;
            padding-right: 75px;
            margin-bottom: 8px;
        }
    }
    .speciality{
        min-height: 46px;
    }
    .publishedName{
        display: flex;
        align-items: center;
        // margin-bottom: 16px;
        // min-height: 22px;
        color: var(--neutral2);
        @media (max-width: 640px) {
            font-size: 12px;
            // margin-bottom: 12px;
        }
        svg{
            margin-right: 6px;
        }
        span{
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
            max-width: calc(100% - 30px);
            strong{
                color: var(--textColor);
                font-weight: 500;
                margin-right: 4px;
            }
        }
    }
    .datePublisher{
        min-height: 22px;
    }
    .dateTime{
        display: flex;
        align-items: center;
        // margin-bottom: 16px;
        // min-height: 18px;
        @media (max-width: 640px) {
            // margin-bottom: 12px;
        }
        svg{
            margin-right: 6px;
        }
        .date, .time{
            display: flex;
            align-items: center;
            font-size: 14px;
            color: var(--neutral2);
            @media (max-width: 640px) {
                font-size: 12px;
            }
            span{
                font-weight: 500;
                margin-right: 3px;
                color: var(--textColor);
            }
            .utcTime{
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                max-width: unset;
                overflow: hidden;
                font-weight: 400;
                color: var(--neutral2);
                margin: 0;
                @media (max-width: 640px) {
                    max-width: 86px;
                }
            }
        }
        .date{
            margin-right: 18px;
        }
    }
    
}
.viewsDurationBookmark{
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #E0E0E0;
    padding-top: 16px;
    margin-top: 16px;
    @media (max-width: 640px) {
        padding-top: 9px;
        margin-top: 8px;
    }
    .viewsDuration{
        display: flex;
        align-items: center;
        margin: 0 -10px;
        min-height: 22px;
    }
    .views, .duration{
        padding: 0 10px;
        display: flex;
        align-items: center;
        color: var(--neutral2);
        @media (max-width: 640px) {
            font-size: 12px;
        }
        svg{
            margin-right: 6px;
        }
        span{
            font-weight: 500;
            margin-right: 3px;
            color: var(--textColor);
        }
    }
    .bookmark {
        cursor: pointer;
        &.activeBookmark {
          svg path{fill: var(--secondaryOrange)}
        }
      }
      .publishedName{
        display: flex;
        align-items: center;
        // min-height: 22px;
        color: var(--neutral2);
        flex: 0 0 100%;
        svg{
            margin-right: 6px;
        }
        span{
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
            max-width: calc(100% - 30px);
            strong{
                color: var(--textColor);
                font-weight: 500;
                margin-right: 4px;
            }
        }
      }
}
/* Home banner css end */

.marketingCard{
    margin: 0 8px;
    position: relative;
    &::before{
        content: "";
        background: linear-gradient(62.99deg, rgb(0 0 0 / 68%) 13.41%, rgba(0, 0, 0, 0.392985) 27.19%, rgba(0, 0, 0, 0) 47.67%);
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        border-radius: 16px;
    }
    .marketingImg{
        height: 300px;
        width: 100%;
        object-fit: cover;
        border-radius: 16px;
        @media (max-width: 640px) {
            height: 230px;
        }
    }
    .marketingText{
        position: absolute;
        width: calc(100% - 25px);
        bottom: 30px;
        left: 25px;
        color: #fff;
        @media (max-width: 640px) {
            bottom: 20px;
            left: 16px;
        }
        h2{
            width: 420px;
            max-width: 100%;
            font-size: 24px;
            line-height: 34px;
            font-weight: 600;
            margin-bottom: 10px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            @media (max-width: 640px) {
                font-size: 18px;
                line-height: 27px;
                margin-bottom: 5px;
            }
        }
        p{
            width: 400px;
            max-width: 100%;
            font-size: 16px;
            line-height: 24px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            @media (max-width: 640px) {
                font-size: 14px;
                line-height: 21px;
            }
        }
    }
    .btnWrapper{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        button{
            margin-top: 12px;
            margin-right: 12px;
            height: 32px;
            min-width: 110px;
            border-radius: 100px;
            padding: 0 13px;
            max-width: calc(50% - 12px);
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            background-color: var(--primaryBlue);
            color: #fff;
            transition: 0.3s ease;
            &:hover{
                background-color: var(--btnHover);
            }
            &:last-child{
                margin-right: 0;
            }
            span{
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }
    }
}

.featureTwoCard{
    display: flex !important;
    .cardContainer{
        flex: 0 0 calc(50% - 16px);
        max-width: calc(50% - 16px);
    }
}