.cardContainer {
  // padding: 0 8px;
  // flex: 0 0 33.3333%;
  margin: 7px 0 10px;
}
.cardWrapper {
  padding: 12px;
  border-radius: 12px;
  // box-shadow: 0px 4px 8px 0px #00000026;
  box-shadow: 0px 3px 9px 0px #00000026;
  background-color: #fff;
  position: relative;
  .mostViewed{
    position: absolute;
    background-color: var(--lightOrange);
    top: 8px;
    right: 0;
    padding: 3px 12px;
    border-radius: 16px 0 0 16px;
    font-size: 12px;
    color: var(--primaryBlue);
  }
  .contentType {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-bottom: 8px;
    min-height: 18px;
    span {
      margin-left: 6px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
      &.ismostView{
        width: calc(100% - 110px);
      }
    }
    svg path{fill: var(--primaryBlue);}
    img {
      width: auto;
      height: 14px;
    }
  }
  .titleImg {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 18px;
    min-height: 66px;
    @media (max-width: 640px) {
      min-height: 60px;
      margin-bottom: 16px;
    }
    .cardTitle {
      flex: 1 0;
      max-width: calc(100% - 72px);
      padding-right: 14px;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      cursor: pointer;
      transition: 0.2s ease;
      &.noImg{
        max-width: 100%;
        padding-right: 0;
      }
      @media (max-width: 640px) {
        font-size: 15px;
        line-height: 20px;
        max-width: calc(100% - 60px);
      }
      &:hover{
        color: var(--primaryBlue);
      }
    }
    img {
      height: 66px;
      width: 66px;
      object-fit: cover;
      object-position: center;
      border-radius: 12px;
      @media (max-width: 640px) {
        height: 60px;
        width: 60px;
      }
    }
  }
  .heightWrapper{
    // min-height: 106px;
    // display: inline-block;
    // width: 100%;
    min-height: 64px;
  }
  .speciality{
    min-height: 40px;
    margin-bottom: 2px;
    display: inline-block;
    width: 100%;
  }
  .publishedName {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 18px;
    color: var(--neutral2);
    // min-height: 22px;
    // margin-bottom: 12px;
    svg {
      margin-right: 6px;
    }
    span{
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
      max-width: calc(100% - 30px);
      strong{
        color: var(--textColor);
        font-weight: 500;
        margin-right: 4px;
      }
    }
  }
}

.dateTime {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--neutral2);
  // margin-bottom: 12px;
  // min-height: 18px;
  svg {
    margin-right: 6px;
  }
  .date,
  .time {
    display: flex;
    align-items: center;
    span{
      color: var(--textColor);
      font-weight: 500;
      margin-right: 4px;
      &.utcTime{
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        max-width: 60px;
        overflow: hidden;
        font-weight: 400;
        color: var(--neutral2);
        margin: 0;
      }
    }
  }
  .date {
    margin-right: 18px;
  }
}

.viewBookmark {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  padding-top: 10px;
  border-top: 1px solid #E0E0E0;
  min-height: 33px;
  .viewsDuration {
    display: flex;
    align-items: center;
    color: var(--neutral2);
    font-size: 12px;
    svg {
      margin-right: 8px;
    }
    .duration{
      display: flex;
      align-items: center;
      span{
        font-weight: 500;
        margin-right: 3px;
        color: var(--textColor);
      }
    }
    .views {
      display: flex;
      align-items: center;
      margin-right: 16px;
      span{
        font-weight: 500;
        margin-right: 3px;
        color: var(--textColor);
      }
    }
  }
  .bookmark {
    cursor: pointer;
    display: inline-flex;
    &.activeBookmark {
      svg path{fill: var(--secondaryOrange)}
    }
  }
  .bottomAuthor{
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 18px;
    color: var(--neutral2);
    flex: 0 0 100%;
    max-width: 100%;
    svg{
      margin-right: 6px;
    }
    span{
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
      max-width: calc(100% - 30px);
      strong{
        color: var(--textColor);
        font-weight: 500;
        margin-right: 4px;
      }
    }
  }
}


.cardContainer.fullWidth{
  .cardWrapper{
    .titleImg{
      min-height: 110px;
      @media (max-width: 640px) {
        min-height: 60px;
      }
      img{
        height: 110px;
        width: 110px;
        @media (max-width: 640px) {
          height: 60px;
          width: 60px;
        }
      }
    }
    .heightWrapper{
      margin-top: -44px;
      padding-right: 120px;
      @media (max-width: 640px) {
        margin-top: 0;
        padding-right: 0;
      }
    }
    .utcTime{
      max-width: unset;
    }
  }
}