.rssPage{
  min-height: 70vh;
  @media (max-width: 640px) {
    min-height: unset;
  }
}
.pageWrapper {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.149);
  // margin-top: 30px;
  margin-bottom: 40px;
  max-width: 100%;
  width: 1180px;
  padding: 20px;
  @media (max-width: 640px) {
    margin: 0 -15px;
    max-width: calc(100% + 30px);
    border-radius: 0;
    box-shadow: none;
    padding: 20px 15px;
  }
  .header {
    color: var(--neutral2);
    margin-bottom: 12px;
    display: block;
  }
}
.title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 20px;
  @media (max-width: 640px) {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 16px;
  }
}
.shortDesc {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
}
.description {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
  position: relative;
  &.guestDesc {
    min-height: 80px;
  }

  .guestDesc {
    // background: linear-gradient(
    //   rgba(255, 255, 255, 0.6),
    //   rgba(255, 255, 255, 0.9),
    //   #fff
    // );
    background: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.9), #fff);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 120px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 0px;
    border-radius: 16px;

    button {
      width: 343px;
      max-width: 100%;
      height: 45px;
      border-radius: 100px;
      background-color: var(--primaryBlue);
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      font-family: var(--primaryFont);
    }
  }
  h1 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  h3 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
  }
  figcaption {
    margin: 0 0 20px;
    font-size: 14px;
    color: var(--neutral2);
    text-align: center;
  }
  img {
    transform: scale(1) !important;
    max-width: 100%;
    border-radius: 12px;
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
    max-height: 300px;
    width: auto !important;
  }
  p {
    margin-bottom: 12px;
  }
  a {
    color: var(--secondaryOrange);
    font-weight: 500;
    text-decoration: none;
  }
  strong {
    font-weight: 500;
  }
  ul {
    margin-bottom: 20px;
    li {
      margin-bottom: 10px;
    }
  }
  svg {
    // max-width: 50px;
    display: inline-block;
    height: 20px;
    width: auto;
  }
  :global {
    .social-icons {
      list-style: none;
      padding: 0;
      li {
        display: inline-block;
        padding: 20px 10px 0;
      }
    }
  }
}

.ebescoModal {
  :global {
    .MuiPaper-root {
      //   width: 510px;
      max-width: 100%;
      //   border-radius: 12px;
    }
    .MuiDialogContent-root {
      padding: 0px;
    }
  }
  .closeDiv {
    // text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    span {
      cursor: pointer;
      font-size: 30px;
    }
    .backBtn {
      margin-right: 18px;
      font-size: 14px;
      font-weight: 600;
      padding: 6px 10px;
      min-width: 150px;
      height: 32px;
      cursor: pointer;
      background-color: var(--primaryBlue);
      border-radius: 100px;
      color: #fff;
      transition: 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-right: 5px;
        transform: rotate(90deg);
        path {
          fill: #fff;
        }
      }
    }
  }
}

.iframe {
  width: 100%;
  height: 90vh;
}