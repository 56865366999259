.breadWrapper {
  @media (max-width: 640px) {
    max-width: 100%;
    overflow-x: scroll;
  }
}
.breadcrumb {
  padding-top: 30px;
  padding-bottom: 12px;
  padding-left: 0;
  margin: 0;
  list-style: none;
  display: flex;
  font-size: 12px;
  color: var(--primaryBlue);
  @media (max-width: 640px) {
    padding-top: 20px;
  }
  a {
    color: var(--primaryBlue);
    text-decoration: none;
    white-space: nowrap;
    max-width: 800px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    & + {
      span {
        padding: 0 8px;
        svg {
          position: relative;
          top: 1.5px;
          overflow: visible;
        }
      }
    }
  }
  .breadcrumbCurrent {
    font-weight: 600;
    color: var(--textColor);
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    white-space: break-spaces;
  }
  .breadcrumb-current {
    font-weight: 600;
  }
  li {
    margin-right: 5px;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
  &.hasDarkBg {
    a {
      color: #fff;
      & + {
        span {
          color: #fff;
          svg path {
            stroke: #fff;
          }
        }
      }
    }
    .breadcrumbCurrent {
      color: #fff;
    }
  }
}
