.detailPage {
  min-height: 600px;
}

.detailWrapper {
  background-color: #fff;
  padding: 24px 24px 60px;
  border-radius: 16px;
  margin-bottom: 45px;
  width: 990px;
  max-width: 100%;
  box-shadow: 0px 4px 15px 0px #00000026;

  @media (max-width: 640px) {
    margin: 0 -15px;
    max-width: calc(100% + 30px);
    padding: 20px 15px;
    border-radius: 0;
    box-shadow: none;
  }
}

.detailTop {
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;

  @media (max-width: 640px) {
    flex-wrap: wrap;
  }

  .detailInfo {
    flex: 0 0 64%;
    max-width: 64%;
    padding-left: 30px;

    @media (max-width: 640px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: 0px;
      order: 1;
      margin-top: 16px;
    }
  }

  .detailImg {
    flex: 0 0 36%;
    max-width: 36%;

    @media (max-width: 640px) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    img {
      width: 100%;
      height: 194px;
      object-fit: cover;
      border-radius: 12px;
      display: block;

      @media (max-width: 640px) {
        height: 145px;
      }
    }
  }

  .tag {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-bottom: 10px;

    svg,
    img {
      margin-right: 6px;
      width: auto;
      height: 14px;
    }
  }

  .title {
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    min-height: 60px;

    @media (max-width: 640px) {
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 27px;
      min-height: unset;
    }
  }
  .author {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    svg {
      margin-right: 6px;
    }

    span {
      color: var(--neutral2);
      max-width: calc(100% - 30px);
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;

      strong {
        color: var(--primaryBlue);
        font-weight: 500;
        margin-right: 4px;
      }

      @media (max-width: 640px) {
        font-size: 12px;
      }
    }
  }

  .beginCta {
    width: 343px;
    max-width: 100%;
  }
}
.datesWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;
  @media (max-width: 640px) {
    display: none;
    margin-bottom: 16px;
  }
  &.mobile {
    display: none;
    @media (max-width: 640px) {
      display: flex;
    }
  }

  .date {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--neutral2);

    svg {
      margin-right: 6px;
    }

    span {
      font-size: 13px;
      font-weight: 500;
      color: var(--textColor);
      margin-right: 4px;
    }

    &:first-child {
      margin-right: 12px;
    }
  }
}
.detailDescription {
  margin-top: 30px;
  font-size: 16px;
  line-height: 24px;
  position: relative;

  h2 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    margin-bottom: 16px;

    @media (max-width: 640px) {
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 20px;
    }
  }

  p {
    margin-bottom: 12px;
  }
}
.desText {
  position: relative;
  &.guestDescWrapper {
    min-height: 100px;
  }
}
.guestDesc {
  // background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.9), #fff);
  background: linear-gradient(
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.9),
    #fff
  );
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 97px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 0px;

  button {
    width: 343px;
    max-width: 100%;
    height: 45px;
    border-radius: 100px;
    background-color: var(--primaryBlue);
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    font-family: var(--primaryFont);
  }
}

.shareList {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  bottom: -40px;
  right: 0;
  width: 100%;
  @media (max-width: 640px) {
    width: auto;
    margin: 0 -4px;
  }
  .shareListIcon {
    transition: 0.3s ease;
    padding: 0 6px;
    @media (max-width: 640px) {
      padding: 0 10px;
    }
    .copyIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      background-color: var(--primaryBlue);
      cursor: pointer;
      svg {
        height: 22px;
        width: auto;
        path {
          fill: white;
        }
      }
    }
    &:hover {
      transform: scale(1.1);
    }
  }
}

.shareWrapper {
  position: relative;
}

.shareAndCta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.detailActions {
  button {
    height: 40px;
    width: 40px;
    border-radius: 6px;
    background-color: #fff;
    margin: 0 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &.disabled {
      opacity: 0.4;
      cursor: default;
    }

    &.likeDisabled {
      cursor: default;
    }
    &.likeActive {
      cursor: default;
    }

    &.active {
      svg path {
        fill: var(--secondaryOrange);
      }
    }
  }
}

.thumbnailImageGuest {
  cursor: pointer;
}
