.detailPage {
  min-height: 70vh;
  padding-bottom: 70px;
  @media (max-width: 640px) {
    padding-bottom: 30px;
  }
}
.detailRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
  padding-top: 20px;
  @media (max-width: 640px) {
    margin: 0 -15px;
    padding-top: 8px;
  }
  .detailCol8 {
    flex: 0 0 74%;
    max-width: 74%;
    padding: 0 8px;
    @media (max-width: 640px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0;
    }
  }
  .detailCol4 {
    flex: 0 0 26%;
    max-width: 26%;
    padding: 0 8px;
    @media (max-width: 640px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 15px;
    }
  }
}
.detailWrapper {
  background-color: #fff;
  padding: 24px 24px 37px;
  border-radius: 12px;
  box-shadow: 0px 3px 8px 0px #00000040;
  position: relative;
  @media (max-width: 640px) {
    padding: 15px 15px 37px;
    border-radius: 0px;
    box-shadow: none;
    margin-bottom: 20px;
  }
}
.detailTop {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.detailImg {
  flex: 0 0 20%;
  max-width: 20%;
  img {
    width: 100%;
    border-radius: 8px;
  }
}
.detailInfo {
  flex: 0 0 80%;
  max-width: 80%;
  padding-left: 30px;
  .tag {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    svg {
      margin-right: 12px;
    }
  }
  .detailTitle {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    color: #242121;
    margin-bottom: 24px;
  }
  .publisher {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 24px;
    color: #645c5c;
    svg {
      margin-right: 12px;
    }
  }
  .views {
    display: flex;
    align-items: center;
    color: #6b6b6b;
    svg {
      margin-right: 12px;
    }
  }
  .detailCta {
    margin-top: 24px;
    width: 230px;
  }
}
.detailActions {
  margin-top: 32px;
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style: none;
    margin: 0 -8px;
    li {
      padding: 0 8px;
      button {
        border: 1px solid #efefef;
        background-color: transparent;
        height: 40px;
        width: 40px;
        border-radius: 6px;
        padding: 0;
        cursor: pointer;
      }
    }
  }
}
.relatedWeb {
  margin-bottom: 0px;
  .title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;
    font-weight: 500;
    @media (max-width: 640px) {
      font-size: 18px;
      line-height: 27px;
    }
  }
  .relatedCard {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

/* tabs css start */
.shalinaTabs {
  .custTabs {
    border-bottom: 1px solid #d3d3d3;
    :global {
      .MuiTab-root {
        padding: 0;
        text-transform: capitalize;
        font-family: inherit;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin-right: 60px;
        color: #444444;
      }
      .MuiTabs-indicator {
        height: 3px;
        background-color: #343434;
      }
    }
  }
  .custTabContent {
    :global {
      .MuiBox-root {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
/* tabs css start */

.guestDesc {
  position: absolute;
  width: 100%;
  height: 577px;
  max-height: 100%;
  bottom: 0;
  left: 0;
  display: none;
  .signUpSec {
    // background: linear-gradient(rgba(255, 255, 255, 0.9098039216) 10%, #ffffff 90%);
    background: linear-gradient(
      0.03deg,
      #fcfcfc 68.45%,
      rgba(252, 252, 252, 0) 85.76%
    );
    height: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 130px 24px 24px;
    button {
      width: 343px;
      max-width: 100%;
      height: 45px;
      border-radius: 100px;
      background-color: var(--primaryBlue);
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      font-family: var(--primaryFont);
      cursor: pointer;
    }
  }
}
.isLoggOut {
  .detailWrapper {
    height: 600px;
    overflow: hidden;
  }
  .guestDesc {
    display: block;
  }
  &.isVideo {
    .detailWrapper {
      height: 980px;
    }
  }
}

.contentNotFoundList {
  img {
    margin: 0 auto 20px;
    display: block;
    max-width: 100%;
  }
  .notFoundText {
    text-align: center;
    h3 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 12px;
    }
  }
}
.contentNotFound {
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 510px;
  max-width: 100%;
  margin: 20px auto;
  text-align: center;
  .notFoundImg {
    margin-bottom: 35px;
    img {
      max-width: 100%;
    }
  }
  h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: var(--primaryBlue);
    margin-bottom: 10px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    color: var(--neutral2);
  }
}

.ebescoModal {
  :global {
    .MuiPaper-root {
      //   width: 510px;
      max-width: 100%;
      //   border-radius: 12px;
    }
    .MuiDialogContent-root {
      padding: 0px;
    }
  }
  .closeDiv {
    // text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    span {
      cursor: pointer;
      font-size: 30px;
    }
    .backBtn {
      margin-right: 18px;
      font-size: 14px;
      font-weight: 600;
      padding: 6px 10px;
      min-width: 150px;
      height: 32px;
      cursor: pointer;
      background-color: var(--primaryBlue);
      border-radius: 100px;
      color: #fff;
      transition: 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-right: 5px;
        transform: rotate(90deg);
        path {
          fill: #fff;
        }
      }
    }
  }
}

.iframe {
  width: 100%;
  height: 90vh;
}

.loadingHeight{
  height: 500px;
}