.shalina-radioGroup{
    .mainLabel{
        font-size: 14px;
        margin-bottom: 8px;
        span{
            color: #F64E4E;
            margin-left: 4px;
        }
    }
    :global{
        .MuiRadio-root.Mui-checked{
            color: #E84E1B;
        }
        .MuiFormHelperText-root.Mui-error{
            color: var(--inputError);
        }
    }
}