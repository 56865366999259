.wrapper{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}

.langList {
  .langLabel {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    margin-bottom: 5px;
    :global {
      .MuiFormControlLabel-root{
        min-width: 150px;
      }
      .MuiRadio-root.Mui-checked {
        color: #e84e1b;
      }
      .MuiFormHelperText-root.Mui-error {
        color: var(--inputError);
      }
    }
    .langFlag {
      height: 25px;
      width: 45px;
      /* border-radius: 50%; */
      object-fit: cover;
      &.isDisabled {
        opacity: 0.3;
      }
    }
  }
}

.saveCta {
  text-align: right;
  margin-top: 60px;
  button {
    height: 45px;
    width: 165px;
    border-radius: 100px;
    background-color: var(--primaryBlue);
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    &:disabled {
        cursor: not-allowed;
        background-color: var(--neutral2);
    }
  }
}
