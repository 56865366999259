/* Explore more start */
.exploreMoreSec {
  margin-bottom: 36px;
}

// .exploreMoreContainer{
//     display: flex;
//     flex-wrap: wrap;
//     margin: 0 -8px;
.exploreMoreWrap {
  padding: 8px 8px;
  height: 100%;

  .exploreMoreCard {
    background-color: #f9f9f9;
    border: 1px solid #f9f9f9;
    text-align: center;
    padding: 16px;
    border-radius: 16px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1490196078);
    cursor: pointer;
    margin-bottom: 16px;

    .imgWrap {
      height: 140px;
      background-color: rgba(0, 46, 91, 0.0784313725);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img,
    svg {
      max-height: 50px;
      max-width: 80%;
      margin: 0 auto;
      transition: 0.3s ease;
    }
  }

  &:hover {
    .exploreMoreCard {
      img,
      svg {
        transform: scale(1.2);
      }
    }
  }
}

.exploreCardTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
}

// }
/* Explore more end */