.signUpWrapper {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 640px) {
    justify-content: flex-start;
  }
  .authBg {
    position: absolute;
    right: 0;
    z-index: -1;
    top: 0;
  }
}
.signUplogo {
  padding-top: 20px;
  cursor: pointer;
  .logo {
    height: 50px;
    width: auto;
    @media (max-width: 640px) {
      height: 40px;
    }
  }
}
.verificationContainer {
  width: 430px;
  max-width: calc(100% - 30px);
  margin: 30px auto;
  padding: 40px 40px;
  box-shadow: 0px 4px 15px 0px #00000026;
  border-radius: 12px;
  background-color: #fff;
  @media (max-width: 640px) {
    padding: 20px 15px;
  }
  .verificationWrapper {
  }
  .title {
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    @media (max-width: 640px) {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 27px;
    }
  }
  .infoText {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 8px;
    span {
      display: block;
      font-weight: 500;
    }
  }
  .verificationInput {
    margin-bottom: 25px;
    :global{
      .MuiFormHelperText-root.Mui-error{
        position: absolute;
        bottom: -23px;
      }
    }
  }
  .timeLink {
    display: flex;
    align-items: end;
    justify-content: end;
    color: var(--neutral2);
    flex-direction: column-reverse;
    div {
      font-weight: 500;
      margin-top: 5px;
      span {
        color: var(--errorColor);
        font-weight: 600;
      }
    }
    a {
      font-weight: 600;
    }
  }
  .verificationCta {
    margin-top: 25px;
  }
}

.toggleLogin{
  margin-top: 20px;
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
  span{
      margin-right: 4px;
  }
}