
.supportTop{
    background-color: var(--primaryBlue);
    padding: 24px 0;
    // margin-bottom: 36px;
    @media (max-width: 640px) {
        // margin-bottom: 0;
        padding: 20px 0;
    }
    .mainHead{
        color: #fff;
        font-size: 22px;
        font-weight: 500;
        line-height: 33px;
        margin: 0;
        padding: 0;
        @media (max-width: 640px) {
            font-size: 18px;
            line-height: 27px;
        }
    }
}

.helpContainer{
    padding-bottom: 30px;
    min-height: calc(100vh - 180px);
    margin-top: 36px;
    // display: flex;
    // justify-content: center;
    @media (max-width: 640px) {
        margin-top: 0;
        padding-bottom: 0;
        min-height: unset;
    }
}
.helpWrapper{
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1490196078);
    width: 990px;
    max-width: 100%;
    background-color: #fff;
    border-radius: 16px;
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    /* text-align: center; */
    @media (max-width: 640px) {
        margin: 20px 0;
        max-width: unset;
        width: auto;
        padding: 24px 15px;
    }
    
    .head{
        font-size: 22px;
        line-height: 33px;
        font-weight: 500;
        margin-bottom: 10px;
        color: var(--primaryBlue);
    }
    .subHead{
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 30px;
    }
    .contactWrapper{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 -10px;
        .contactItem{
            padding: 0 10px;
            cursor: pointer;
        }
        a{
            display: flex;
            align-items: center;
            text-decoration: none;
            font-size: 16px;
            font-weight: 500;
            color: #fff;
            background-color: var(--primaryBlue);
            border: 1px solid var(--primaryBlue);
            width: 170px;
            justify-content: center;
            padding: 7px 6px;
            border-radius: 100px;
            transition: 0.3s ease;
            @media (max-width: 640px) {
                width: 150px;
            }
            &:hover{
                background-color: var(--btnHover);
            }
            svg{
                height: 22px;
                width: auto;
                margin-right: 8px;
                fill: #fff;
            }
        }
    }
    .st0{fill-rule:evenodd;clip-rule:evenodd;}
}


.reqForm{
    max-width: 100%;
    .orDivider{
        margin: 30px 0;
        text-align: center;
        position: relative;
        &::before{
            content: "";
            position: absolute;
            height: 1px;
            width: 100%;
            background-color: #9E9E9E;
            left: 0;
            top: 50%;
        }
        span{
            position: relative;
            z-index: 1;
            background-color: #fff;
            padding: 0 8px;
            font-size: 16px;
            font-weight: 500;
            color: var(--neutral2);
        }
    }
}