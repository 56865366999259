.profilePoints {
  flex: 0 0 55%;
  max-width: 55%;
  padding-left: 60px;
  margin-bottom: 30px;
  @media (max-width: 640px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    margin-bottom: 16px;
  }
}

.pointWrapper {
  padding: 20px 20px 16px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 4px 15px 0px #00000026;
  @media (max-width: 640px) {
    padding: 14px 16px;
  }
  .pointProcessWrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .pointImg {
      height: 72px;
      width: 72px;
      border-radius: 50%;
      border: 6px solid var(--lightOrange);
      @media (max-width: 640px) {
        height: 40px;
        width: 40px;
        border: 3px solid var(--lightOrange);
      }
      &.active {
        border-color: var(--secondaryOrange);
      }
    }
    .pointProgressBar {
      position: absolute;
      height: 8px;
      width: calc(100% - 144px);
      left: 72px;
      top: calc(50% - 6px);
      background-color: var(--lightOrange);
      @media (max-width: 640px) {
        width: calc(100% - 80px);
        left: 40px;
        height: 4px;
        top: calc(50% - 3px);
      }
      .pointDots {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        span {
          height: 8px;
          width: 8px;
          background-color: var(--secondaryOrange);
          border-radius: 50%;
        }
      }
      .pointProgress {
        position: absolute;
        height: 100%;
        background-color: var(--secondaryOrange);
        border-radius: 0 10px 10px 0px;
        top: 0;
        left: 0;
      }
    }
  }
  .totalLabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    @media (max-width: 640px) {
      margin-top: 18px;
      font-size: 12px;
    }
    div {
      line-height: 21px;
      color: var(--neutral2);
      @media (max-width: 640px) {
        line-height: 16px;
      }
      span {
        color: var(--primaryBlue);
        font-weight: 600;
      }
      .redemptionLabel {
        height: 32px;
        margin-left: 11px;
        padding: 0 11px;
        display: inline-flex;
        align-items: center;
        background-color: transparent;
        border: 1px solid var(--secondaryOrange);
        border-radius: 100px;
        font-size: 12px;
        color: var(--textColor);
        svg {
          margin-right: 5px;
          path {
            fill: var(--secondaryOrange);
          }
        }
      }
      button {
        height: 32px;
        margin-left: 11px;
        padding: 0 11px;
        border: 1px solid var(--primaryBlue);
        background-color: var(--primaryBlue);
        border-radius: 100px;
        font-size: 12px;
        color: #fff;
        font-weight: 600;
        &:disabled {
          color: #969696;
          background-color: #e2e2e2;
          border-color: #e2e2e2;
          cursor: not-allowed;
        }
      }
    }
  }
  .discoverText {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    line-height: 24px;
    color: var(--primaryBlue);
    font-weight: 600;
    margin-top: 16px;
    padding-top: 14px;
    border-top: 1px solid #8080804d;
    cursor: pointer;
    @media (max-width: 640px) {
      font-size: 14px;
      line-height: 21px;
      margin-top: 14px;
      padding-top: 12px;
    }
    span {
      display: inline-flex;
      height: 28px;
      width: 28px;
      background-color: var(--primaryBlue);
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      @media (max-width: 640px) {
        height: 24px;
        width: 24px;
      }
    }
  }
}

.redemptionModal {
  :global {
    .MuiPaper-root {
      width: 420px;
      max-width: 100%;
      border-radius: 12px;
      margin: 16px;
    }

    .MuiDialogContent-root {
      padding: 24px;
      color: var(--textColor);
      @media (max-width: 640px) {
        padding: 16px;
      }
    }
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    h4 {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
    }
    span {
      cursor: pointer;
      font-size: 28px;
    }
  }
  .points {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 20px;
    strong {
      font-weight: 700;
    }
  }
  .signInFormCol {
    margin-bottom: 20px;
  }
  .rangeWrapper {
    margin-bottom: 20px;
    .rangeWrap {
      margin-bottom: 20px;
      margin-left: 12px;
      flex: 0 0 100%;
      width: 100%;
    }
    :global {
      .MuiFormControl-root {
        width: calc(100% - 30px);
      }
      .MuiBox-root {
        width: 100%;
      }
      .css-1aznpnh-MuiSlider-root,
      .css-9cct2k {
        color: var(--secondaryOrange);
      }
    }
    .redeemCta {
      flex: 0 0 100%;
      width: 100%;
      &:disabled {
        background-color: #aaa;
      }
    }
  }
  .enterEmail {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 20px;
    span {
      font-weight: 500;
      display: block;
    }
  }
  .verificationInput {
    max-width: 100%;
  }
  .timeLink {
    display: flex;
    align-items: end;
    justify-content: end;
    color: var(--neutral2);
    flex-direction: column-reverse;
    margin-top: 25px;
    div {
      font-weight: 500;
      margin-top: 5px;
      span {
        color: var(--errorColor);
        font-weight: 600;
      }
    }
    a {
      font-weight: 600;
    }
  }
  .verificationCta {
    margin: 25px auto 0;
  }
}
