.members {
    align-items: center;
    color: var(--neutral2);
    display: flex;
    font-size: 12px;
    margin-bottom: 5px;

    .memberImages {
      display: flex;
      padding-right: 13px;

      .imageWrapper {
        border: 3px solid #fff;
        border-radius: 50%;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin-right: -7px;
        overflow: hidden;

        img {
          border-radius: inherit;
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
    }
  }