.exploreCard {
  box-shadow: 0px 4px 15px 0px #00000026;
  padding: 14px 12px;
  margin-bottom: 16px;
  border-radius: 16px;
  display: flex;
  background-color: #fff;
  .exploreIconWrapper {
    flex: 0 0 43px;
    max-width: 43px;
    height: 43px;
    border-radius: 50%;
    border: 4px solid var(--lightBlue);
    display: flex;
    align-items: center;
    justify-content: center;
    .exploreIcon {
      max-width: 34px;
      height: 34px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .details {
    flex: 1 0 0;
    max-width: calc(100% - 43px);
    padding-left: 10px;
  }
  .title {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 8px;
    cursor: pointer;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .speciality {
    :global {
      .globalspecialityWrapper {
        margin-bottom: 6px;
      }
    }
  }
  .members {
    align-items: center;
    color: var(--neutral2);
    display: flex;
    font-size: 12px;
    margin-bottom: 15px;
    .memberImages {
      display: flex;
      padding-right: 13px;
      .imageWrapper {
        border: 3px solid #fff;
        border-radius: 50%;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin-right: -7px;
        overflow: hidden;
        img {
          border-radius: inherit;
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
    }
  }
  .ctaWrapper {
    display: flex;
    margin: 0 -6px;
    .crownIcon {
      max-width: 16px;
      max-height: 16px;
      margin-right: 12px;
    }
  }
  .joinCta {
    min-width: 85px;
    height: 24px;
    font-size: 11px;
    font-weight: 600;
    border-radius: 100px;
    transition: 0.3s ease;
    display: block;
    margin: 0 6px;
    border: 1px solid var(--primaryBlue);
    background-color: transparent;
    color: var(--primaryBlue);
    position: relative;
    // &:hover{
    //     background-color: var(--btnHover);
    // }
  }
  .reqCta {
    min-width: 85px;
    height: 32px;
    background-color: transparent;
    border: 1px solid var(--primaryBlue);
    color: var(--primaryBlue);
    font-size: 14px;
    font-weight: 600;
    border-radius: 100px;
    transition: 0.3s ease;
    display: block;
    margin: 0 6px;
    position: relative;
    &:hover {
      border-color: var(--btnHover);
      color: var(--btnHover);
    }
    &.accept {
      background-color: var(--primaryBlue);
      color: #fff;
      &:hover {
        background-color: var(--btnHover);
      }
    }
  }
}

.exploreCard.fullWidth {
  .members {
    margin-bottom: 8px;
  }
  .details {
    &.isAskToJoin {
      display: flex;
      align-items: center;
      @media (max-width: 640px) {
        align-items: flex-start;
        flex-direction: column;
      }
      .insideDatail {
        flex: 1;
        padding-right: 10px;
      }
    }
    &.crownWrap {
      align-items: flex-start;
    }
  }
  .joinCta {
    min-width: 130px;
    height: 32px;
    font-size: 14px;
    position: relative;
    &:hover {
      border-color: var(--btnHover);
      color: var(--btnHover);
    }
  }
}

.joinModal {
  .closeCta {
    background-color: transparent;
    font-size: 30px;
    height: 30px;
    line-height: 0px;
    padding: 0;
    position: absolute;
    right: 9px;
    top: 13px;
    width: 30px;
    color: #fff;
  }
  :global {
    .MuiPaper-root {
      width: 700px;
      max-width: 100%;
      border-radius: 12px;
      margin: 0 16px;
      max-height: calc(100% - 30px);
    }

    .MuiDialogContent-root {
      padding: 0px;
      position: relative;
      overflow: hidden;
    }
  }
}
