.myProfilePage {
    background-color: transparent;
    padding-bottom: 40px;

    @media (max-width: 640px) {
        background-color: transparent;
        padding-bottom: 0;
    }
}

.profileTop {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-top: 47px;

    @media (max-width: 640px) {
        padding-top: 0;
    }

    .userDetails {
        flex: 0 0 45%;
        max-width: 45%;
        margin-bottom: 30px;

        @media (max-width: 640px) {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 16px;
        }
    }

    .profilePoints {
        flex: 0 0 55%;
        max-width: 55%;
        padding-left: 60px;
        margin-bottom: 30px;
    }
}

.userDetailWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    @media (max-width: 640px) {
        background-color: #fff;
        margin: 0 -15px;
        padding: 20px 15px;
    }

    .userImg {
        flex: 0 0 190px;
        max-width: 190px;
        position: relative;

        @media (max-width: 640px) {
            flex: 0 0 73px;
            max-width: 73px;
        }

        img {
            height: 190px;
            width: 190px;
            border-radius: 50%;
            border: 8px solid #E6F0FA;

            @media (max-width: 640px) {
                height: 73px;
                width: 73px;
                border: 4px solid #E6F0FA;
            }
        }

        .editProfileImg {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 46px;
            width: 46px;
            border: 4px solid #E6F0FA;
            border-radius: 50%;
            bottom: 10px;
            right: 10px;
            background-color: var(--primaryBlue);
            cursor: pointer;

            @media (max-width: 640px) {
                height: 24px;
                width: 24px;
                border: 2px solid #e6f0fa;
                bottom: 8px;
                right: 0px;

                svg {
                    width: 10px;
                    height: auto;
                }
            }
        }
    }

    .userInfo {
        flex: 0 0 calc(100% - 190px);
        max-width: calc(100% - 190px);
        padding-left: 40px;

        .userName {
            font-size: 24px;
            line-height: 36px;
            font-weight: 600;
            margin-bottom: 4px;
        }

        .userDesignation {
            font-size: 20px;
            font-weight: 500;
            line-height: 27px;
            color: var(--neutral2);
            margin-bottom: 36px;

            span {
                font-weight: 400;
                line-height: 18px;
                border-left: 1px solid;
                // display: inline-block;
                padding-left: 5px;
            }
        }
    }

    .profileProgress {
        margin-bottom: 20px;

        .progressLabel {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;
            font-size: 14px;

            span {
                font-size: 16px;
                color: var(--primaryBlue);
                font-weight: 600;
            }
        }

        .profileProgressBar {
            position: relative;
            height: 8px;
            border-radius: 18px;
            background-color: var(--lightBlue);

            .progressCompleted {
                position: absolute;
                border-radius: 18px;
                height: 100%;
                background-color: var(--secondaryOrange);
                left: 0;
                top: 0;
            }
        }
    }

    .updateCta {
        height: 32px;
        font-size: 14px;
        background-color: var(--primaryBlue);
        color: #fff;
        border-radius: 100px;
        font-weight: 600;
        width: 100%;
        cursor: pointer;

        
    }
}

.pointWrapper {
    padding: 20px 20px 16px;
    background-color: #FFFFFF;
    border-radius: 16px;
    box-shadow: 0px 4px 15px 0px #00000026;

    .pointProcessWrap {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .pointImg {
            height: 72px;
            width: 72px;
            border-radius: 50%;
            border: 6px solid var(--lightOrange);

            &.active {
                border-color: var(--secondaryOrange);
            }
        }

    }

    .totalLabel {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;

        div {
            line-height: 21px;
            color: var(--neutral2);

            span {
                color: var(--primaryBlue);
                font-weight: 600;
            }
        }
    }

    .discoverText {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        line-height: 24px;
        color: var(--primaryBlue);
        font-weight: 600;
        margin-top: 16px;
        padding-top: 14px;
        border-top: 1px solid #8080804D;

        span {
            display: inline-flex;
            height: 28px;
            width: 28px;
            background-color: var(--primaryBlue);
            border-radius: 50%;
            justify-content: center;
            align-items: center;

            svg path {
                fill: #fff;
            }
        }
    }
}

/* profile tab css start */
.profileTabContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .tabCol {
        flex: 0 0 286px;
        max-width: 286px;

        @media (max-width: 640px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
        :global {
            .MuiTabs-root {
                overflow: visible;
            }
            .MuiTabs-indicator {
                display: none;
            }
            .MuiTabs-scroller {
                overflow: visible !important;
            }

            .MuiTabs-flexContainer {
                flex-wrap: wrap;

                @media (max-width: 640px) {
                    flex-wrap: nowrap;
                    overflow-x: scroll;
                    margin: 0 -15px;
                    background-color: #fff;
                    padding-top: 15px;
                    padding-left: 4px;
                    box-shadow: 0 2px 4px #ddd;
                }
            }

            .MuiTab-labelIcon {
                display: flex;
                align-items: center;
                min-height: auto;
                height: 47px;
                margin-bottom: 5px;
                font-family: inherit;
                width: 100%;
                text-align: left;
                padding: 10px 22px;
                background-color: transparent;
                font-size: 14px;
                font-weight: 400;
                transition: 0.3s ease;
                flex-direction: row;
                justify-content: flex-start;
                opacity: 1;
                text-transform: capitalize;
                color: var(--textColor);

                &:hover {
                    background-color: #E6F0FA99;

                    @media (max-width: 640px) {
                        background-color: transparent;
                    }
                }

                @media (max-width: 640px) {
                    width: auto;
                    white-space: nowrap;
                    padding: 15px 12px;
                    margin: 0;
                    font-size: 16px;
                    position: relative;
                    transition: none;
                }

            }
        }

        button {
            @media (max-width: 640px) {
                &::after {
                    content: "";
                    position: absolute;
                    width: 48px;
                    height: 3px;
                    background-color: transparent;
                    border-radius: 3px;
                    bottom: 0;
                    left: calc(50% - 24px);
                }
            }

            &.active {
                background-color: #E6F0FA99;
                color: var(--primaryBlue);
                font-weight: 600;

                @media (max-width: 640px) {
                    background-color: transparent;

                    &::after {
                        background-color: var(--secondaryOrange);
                    }
                }
            }

        }

        .tabicon {
            width: 32px;
            display: flex;
            align-items: center;
            margin-bottom: 0 !important;
            justify-content: center;
            padding-right: 10px;

            // svg path{fill: var(--secondaryOrange);}
            @media (max-width: 640px) {
                display: none;
            }
        }

    }

    .tabContent {
        flex: 0 0 calc(100% - 286px);
        max-width: calc(100% - 286px);
        padding-left: 16px;

        @media (max-width: 640px) {
            flex: 0 0 100%;
            max-width: 100%;
            padding-left: 0px;
        }

        :global {
            .MuiBox-root {
                padding: 0px;
                @media (max-width: 640px) {
                    margin: 0 -15px;
                }
            }
        }
    }

    .tabWrapper {
        background-color: #fff;
        width: 100%;
        padding: 14px 0px 5px;
        border-radius: 16px;
        margin-bottom: 14px;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 4px 15px 0px #00000026;

        @media (max-width: 640px) {
            width: auto;
            flex-direction: row;
            margin-bottom: 0px;
            background-color: transparent;
            box-shadow: none;
            padding: 0;
        }
    }
}

.tabContent {
    :global {
        .MuiTypography-root {
            font-family: var(--primaryFont);
        }
    }
}

.tabContentBox {
    padding: 24px;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0px 4px 15px 0px #00000026;
    min-height: 554px;
    display: flex;
    flex-direction: column;

    @media (max-width: 640px) {
        padding: 20px 15px;
        border-radius: 0;
        min-height: 400px;
    }

    .head {
        font-size: 22px;
        line-height: 33px;
        font-weight: 500;
        color: var(--primaryBlue);
        margin-bottom: 18px;
        @media (max-width: 640px) {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 10px;
        }
    }
}

/* profile tab css end */