/* auto complete css start */
.shalinaAutoComplete {
  .autocompleteLabel {
    margin-bottom: 8px;
    font-size: 14px;
    display: block;
    span {
      color: var(--errorColor);
      margin-left: 4px;
    }
  }
  :global {
    .MuiAutocomplete-root {
      .MuiInputBase-root {
        min-height: 48px;
        .MuiChip-root {
          height: 28px;
          color: var(--primaryBlue);
          background-color: #002e5b0f;
          font-size: 14px;
          font-family: var(--primaryFont);
          padding-right: 8px;
          .MuiChip-label {
            padding-left: 10px;
            padding-right: 14px;
            flex: 1;
          }
        }
      }
      .MuiOutlinedInput-root {
        padding-top: 3px;
        padding-bottom: 3px;
      }
    }
    .MuiAutocomplete-endAdornment {
      display: none;
    }
  }
}
:global {
  .MuiAutocomplete-popper {
    .MuiCheckbox-root {
      padding-top: 0;
      padding-bottom: 0;
      &.Mui-checked {
        color: #e84e1b !important;
      }
    }
  }
}
.autoCompleteLi {
  padding: 8px 20px;
  display: flex;
  align-items: center;
  .acCheck {
    padding: 0;
    padding-top: 1px;
    padding-right: 5px;
  }
}

/* auto complete css end */
