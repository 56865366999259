.termPage{
    .head{
        margin-top: 30px;
        margin-bottom: 32px;
        font-size: 12px;
        font-weight: 600;
        color: var(--primaryBlue);
    }
    .termPageWrapper{
        width: 990px;
        max-width: 100%;
        background-color: #fff;
        border-radius: 16px;
        box-shadow: 0px 4px 15px 0px #00000026;
        margin-bottom: 32px;
        padding: 32px 24px;
        .title{
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 16px;
            color: var(--primaryBlue);
            line-height: 33px;
        }
    }
}
// .termContent{
//     font-size: 16px;
//     line-height: 24px;
//     .contentList{
//         padding-bottom: 14px;
//     }
//     h4{
//         font-size: 16px;
//         font-weight: 600;
//         margin-bottom: 5px;
//     }
//     p{
//         margin-bottom: 10px;
//     }
// }
.termsContent{
    font-size: 14px;
    color: var(--textColor);
    line-height: 24px;
    .contentSec{
        margin-bottom: 20px;
    }
    .subHead{
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
        line-height: 20px;
    }
    p{
        margin-bottom: 10px;
    }
    ol, ul{
        padding-left: 25px;
        margin-bottom: 20px;
    }
}