.Wrapper{
    .head{
        display: flex;
        align-items: center;
        justify-content: space-between;
        h4{
            font-size: 18px;
            line-height: 27px;
            font-weight: 500;
        }
        button{
            min-width: 86px;
            height: 32px;
            background-color: var(--primaryBlue);
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            border-radius: 100px;
            transition: 0.3s ease;
            &:hover{
                background-color: var(--btnHover);
            }
        }
    }
}
.list{
    .item{
        box-shadow: 0px 4px 15px 0px #00000026;
        border-radius: 16px;
        background-color: #fff;
        padding: 14px 12px;
        display: flex;
        align-items: center;
        margin-top: 16px;
        .iconTitleWrap {
          display: flex;
          align-items: center;
          flex: 1;
        }
        .crownIcon {
          flex: 0 0 16px;
          max-width: 16px;
          max-height: 16px;
          margin-left: 5px;
          svg{
            height: 20px;
            width: 20px;
          }
        }
        .iconWrapper{
            flex: 0 0 43px;
            max-width: 43px;
            height: 43px;
            border-radius: 50%;
            .icon{
              max-width: 43px;
              width: 43px;
              height: 43px;
              object-fit: cover;
              border-radius: 50%;
              border: 4px solid var(--lightBlue);
            }
        }
        
        .title{
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin: 0;
            padding-left: 10px;
            cursor: pointer;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
}

.welcomePage {
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1490196078);
    padding: 20px;
    text-align: center;
    margin-top: 16px;
    .welcomeWrapper {
      width: 384px;
      max-width: 100%;
      margin: 0 auto;
      img {
        max-width: 100%;
        height: auto;
        margin-bottom: 18px;
      }
      h2 {
        font-size: 24px;
        line-height: 36px;
        font-weight: 600;
        color: var(--primaryBlue);
        margin-bottom: 8px;
      }
      p {
        font-size: 14px;
        color: var(--neutral2);
        line-height: 21px;
        margin-bottom: 18px;
      }
      .cta {
        width: 343px;
        max-width: 100%;
        margin: 0 auto;
        button{
          height: 35px;
        }
      }
    }
  }