.videoPlayer {
  margin-bottom: 25px;
  position: relative;
  @media (max-width: 640px) {
    margin: -15px -15px 16px;
  }
  video {
    width: 100% !important;
    height: auto !important;
    // object-fit: cover;
    border-radius: 12px;
    @media (max-width: 640px) {
      // height: 200px !important;
      border-radius: 0;
    }
  }
}
.guest {
  opacity: 0.6;
  cursor: pointer;
}
.isVideoLoading {
  min-height: 330px;
  background-color: var(--neutral2);
  border-radius: 12px;
  margin-bottom: 25px;
  @media (max-width: 640px) {
    min-height: 200px;
  }
}
.detailTop {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
}

.detailImg {
  flex: 0 0 37%;
  max-width: 37%;
  @media (max-width: 640px) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  img {
    width: 100%;
    border-radius: 8px;
    max-height: 194px;
    object-fit: cover;
    display: block;
    @media (max-width: 640px) {
      max-height: 200px;
      margin-bottom: 16px;
    }
  }
}
.dateViews {
  margin: 20px -15px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 640px) {
    display: none;
    margin: 16px -15px 0;
  }

  span {
    display: inline-flex;
    align-items: center;
    color: var(--neutral2);
    flex: 0 0 44%;
    padding: 0 15px;
    font-size: 12px;
    border-left: 1.5px solid #e0e0e0;
    margin-bottom: 18px;
    flex-wrap: wrap;
    @media (max-width: 640px) {
      margin-bottom: 12px;
      padding: 0 15px;
    }

    &:nth-child(1),
    &:nth-child(3),
    &:nth-child(6) {
      border: 0;
    }

    strong {
      color: var(--textColor);
      font-weight: 500;
      margin-right: 4px;
    }

    svg {
      margin-right: 8px;
    }
  }

  .lineBreak {
    flex: 0 0 100%;
  }
  &.MobileDateViews {
    display: none;
    @media (max-width: 640px) {
      display: flex;
    }
  }
}

.detailInfo {
  flex: 0 0 63%;
  max-width: 63%;
  padding-left: 30px;
  @media (max-width: 640px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
  }

  .tag {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 12px;

    svg,
    img {
      margin-right: 8px;
      height: 14px;
      width: auto;
    }
  }

  .detailTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 20px;
    // min-height: 120px;
    @media (max-width: 640px) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
      min-height: unset;
    }
  }

  .specialityWrap {
    margin: 0 0 4px;

    .specialityItem {
      margin: 0 6px 5px;
      background-color: var(--lightBlue);
      font-size: 12px;
      padding: 3px 10px;
      border-radius: 12px;
      color: var(--primaryBlue);
    }
  }

  .publisher {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 24px;
    color: #645c5c;
    @media (max-width: 640px) {
      margin-bottom: 0;
    }

    svg {
      margin-right: 12px;
    }
    span {
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      max-width: calc(100% - 34px);
      overflow: hidden;
      @media (max-width: 640px) {
        display: block;
        overflow: visible;
      }
    }
  }

  .ctaShare {
    width: 300px;
    max-width: 100%;
    margin-top: 10px;
    margin-bottom: 14px;
    @media (max-width: 640px) {
      margin-top: 18px;
    }
  }
}
.ctaShareWrapper{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.shareAndViews {
  flex: 1 0;
  margin-top: 10px;
  margin-bottom: 14px;
  @media (max-width: 640px) {
    margin-top: 18px;
  }

  .shareWrap {
    display: flex;
    align-items: center;
    margin: 0 -8px;

    button {
      height: 40px;
      width: 40px;
      border-radius: 6px;
      background-color: #fff;
      margin: 0 8px;
    }
  }
}

.articalContent {
  font-size: 16px;
  line-height: 24px;
  position: relative;

  &.guestDescWrapper {
    min-height: 140px;
  }

  p {
    margin-bottom: 10px;
  }
}
.guestDesc {
  // background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.9), #fff);
  background: linear-gradient(
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.9),
    #fff
  );
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 97px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 0px;

  button {
    width: 343px;
    max-width: 100%;
    height: 45px;
    border-radius: 100px;
    background-color: var(--primaryBlue);
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    font-family: var(--primaryFont);
  }
}

.speakerList {
  position: relative;
  &.guestDescWrapper {
    min-height: 140px;
    max-height: 140px;
    overflow: hidden;
  }
  .speaker {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 20px;
    margin-bottom: 20px;
    @media (max-width: 640px) {
      padding-bottom: 16px;
      margin-bottom: 16px;
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border: 0;
    }

    .speakerImg {
      flex: 0 0 66px;
      max-width: 66px;
      @media (max-width: 640px) {
        flex: 0 0 44px;
        max-width: 44px;
      }

      img {
        width: 100%;
        height: 66px;
        border: 4px solid var(--lightBlue);
        border-radius: 50%;
        display: block;
        @media (max-width: 640px) {
          height: 44px;
        }
      }
    }

    .speakerinfo {
      flex: 1;
      padding-left: 15px;
      @media (max-width: 640px) {
        padding-left: 12px;
      }

      .name {
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        margin-bottom: 5px;
        @media (max-width: 640px) {
          font-size: 15px;
          line-height: 22px;
          margin-bottom: 0;
        }
      }

      .role {
        font-size: 14px;
        color: var(--neutral2);
        font-weight: 500;
        @media (max-width: 640px) {
          font-size: 12px;
        }
      }
    }
  }
}

/* tabs css start */
.shalinaTabs {
  .custTabs {
    background-color: #e6f0fa;
    padding: 16px 24px 0;
    margin: 0 -24px;
    @media (max-width: 640px) {
      margin: 0 -15px;
      padding: 16px 15px 0;
    }

    :global {
      .MuiTab-root {
        padding: 0;
        text-transform: capitalize;
        font-family: inherit;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-right: 60px;
        color: var(--primaryBlue);

        &.Mui-selected {
          font-weight: 600;
        }
        @media (max-width: 640px) {
          margin-right: 70px;
        }
      }

      .MuiTabs-indicator {
        height: 3px;
        background-color: var(--secondaryOrange);
      }
    }
  }

  .custTabContent {
    position: relative;

    :global {
      .MuiBox-root {
        padding: 24px 0 0;
      }

      .MuiTypography-root {
        font-family: var(--primaryFont);
      }
    }
  }
}

.authorLabel {
  color: var(--textColor);
  font-weight: 500;
  margin-right: 6px;
}
.authorText {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  max-width: calc(100% - 130px);
  overflow: hidden;
  @media (max-width: 640px) {
    display: block;
  }
}

/* tabs css start */

/* play video start */
.detailTop.isPlayVideo {
  flex-direction: column-reverse;
  align-items: normal;
  .detailImg {
    flex: 0 0 100%;
    max-width: 100%;
    img {
      display: none;
    }
  }
  .dateViews {
    width: 700px;
    max-width: 100%;
    margin-top: 10px;
    @media (max-width: 640px) {
      width: auto;
      max-width: unset;
    }
  }
  .detailInfo {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
  }
}
.thumbnailImageGuest {
  cursor: pointer;
}
/* play video end */
