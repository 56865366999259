.detailPage {
    background-color: #fff;
    // padding-bottom: 84px;
}

.detailRow {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    padding-top: 36px;

    .detailCol8 {
        flex: 0 0 74%;
        max-width: 74%;
        padding: 0 8px;
    }

    .detailCol4 {
        flex: 0 0 26%;
        max-width: 26%;
        padding: 0 8px;
    }
}

.detailWrapper {
    background-color: #fff;
    padding: 24px 24px 80px;
    border-radius: 12px;
    box-shadow: 0px 3px 8px 0px #00000040;
}

.detailTop {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    margin-bottom: 30px;
    @media (max-width: 640px) {
        margin-bottom: 20px;
        flex-wrap: wrap;
        position: relative;
    }
}

.detailImg {
    flex: 0 0 21%;
    max-width: 21%;
    @media (max-width: 640px) {
        flex: 0 0 90px;
        width: 90px;
        max-width: 90px;
        position: absolute;
        right: 0;
        top: 0;
    }

    img {
        width: 100%;
        border-radius: 8px;
        height: 250px;
        object-fit: cover;
        display: block;
        @media (max-width: 640px) {
            height: 100px;
        }
    }
}
.dateView{
    display: flex;
    flex-direction: column;
    @media (max-width: 640px) {
        font-size: 12px;
        display: none;
    }
    &.mobileViews{
        display: none;
        @media (max-width: 640px) {
            display: flex;
            flex-direction: row;
            margin: 0 -12px;
        }
    }
}
.views {
    display: flex;
    align-items: center;
    color: var(--neutral2);
    // margin-top: 12px;
    font-size: 12px;
    @media (max-width: 640px) {
        margin: 0px 12px 0;
    }

    .text {
        font-weight: 500;
        margin-right: 4px;
        color: var(--textColor);
    }
    .icon{
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 640px) {
            width: auto;
        }
    }

    svg {
        margin-right: 8px;
        @media (max-width: 640px) {
            margin-right: 6px;
        }
    }
    
}

.detailInfo {
    flex: 0 0 79%;
    max-width: 79%;
    padding-left: 30px;
    @media (max-width: 640px) {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .tag {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 12px;
        @media (max-width: 640px) {
            padding-right: 102px;
        }

        svg,
        img {
            margin-right: 8px;
            height: 14px;
            width: auto;
        }
    }

    .detailTitle {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        margin-bottom: 24px;
        // min-height: 120px;
        @media (max-width: 640px) {
            padding-right: 102px;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 16px;
            // min-height: 70px;
        }
    }

    .publisher {
        display: flex;
        align-items: flex-start;
        font-size: 14px;
        margin-bottom: 16px;
        color: var(--neutral2);
        @media (max-width: 640px) {
            margin-bottom: 12px;
        }

        svg {
            margin-right: 8px;
            // @media (max-width: 640px) {
            //     margin-right: 8px;
            // }
        }
        .authorLabel{
            color: var(--textColor);
            font-weight: 500;
            margin-right: 6px;
        }
        .authorText{
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            max-width: calc(100% - 130px);
            overflow: hidden;
            @media (max-width: 640px) {
                display: block;
                overflow: visible;
            }
        }
    }

    .detailCta {
        margin-top: 24px;
        width: 343px;
        max-width: 100%;
        position: relative;
        @media (max-width: 640px) {
            margin-top: 30px;
        }

        .disableSpan {
            display: none;
        }
    }
}
.shareAndCta{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.detailActions {
    flex: 1 0 0;
    margin-top: 24px;
    @media (max-width: 640px) {
        margin-top: 30px;
    }
    ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        list-style: none;
        margin: 0 -8px;

        li {
            padding: 0 8px;

            button {
                background-color: transparent;
                height: 40px;
                width: 40px;
                border-radius: 6px;
                padding: 0;
                cursor: pointer;
            }
        }
    }
}

.relatedWeb {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 24px;

    .title {
        font-size: 18px;
        margin-bottom: 24px;
        font-weight: 500;
    }
}

.relatedCard {
    margin-bottom: 16px;
}


/* tabs css start */
.shalinaTabs {
    .custTabs {
        background-color: #E6F0FA;
        padding: 16px 24px 0;
        margin: 0 -24px;
        @media (max-width: 640px) {
            margin: 0 -15px;
            padding: 16px 15px 0;
         }

        :global {
            .MuiTab-root {
                padding: 0;
                text-transform: capitalize;
                font-family: inherit;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                margin-right: 60px;
                color: var(--primaryBlue);
                
                &.Mui-selected {
                    font-weight: 600;
                }
                @media (max-width: 640px) {
                    margin-right: 70px;
                }
            }

            .MuiTabs-indicator {
                height: 3px;
                background-color: var(--secondaryOrange);
            }
        }
    }

    .custTabContent {
        position: relative;

        :global {
            .MuiBox-root {
                padding: 24px 0 0;
            }

            .MuiTypography-root {
                font-family: var(--primaryFont);
            }
        }
    }
}

/* tabs css start */

.detailPage.isLoggOut {
    .detailInfo {
        .detailCta {
            .disableSpan {
                display: block;
                position: absolute;
                background-color: #fff;
                width: 100%;
                height: 100%;
                z-index: 1;
                opacity: 0.5;
            }
        }
    }

    .detailActions {
        position: relative;

        .disableSpan {
            display: block;
            position: absolute;
            background-color: #fff;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0.5;
        }
    }

    .ebookContent {
        max-height: 100px;
        overflow: hidden;
    }

    .guestDesc {
        display: block;
    }
}

.ebookContent {
    position: relative;

    &.guestDescWrapper {
        min-height: 140px;
    }
}
.guestDesc {
    // background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.9), #fff);
    background: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.9), #fff);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 97px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 0px;

    button {
        width: 343px;
        max-width: 100%;
        height: 45px;
        border-radius: 100px;
        background-color: var(--primaryBlue);
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        font-family: var(--primaryFont);
    }
}
.bookDetail {
    position: relative;
    &.guestDescWrapper {
        min-height: 140px;
        max-height: 140px;
        overflow: hidden;
    }
    p {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }

        span {
            color: var(--neutral2);
            flex: 1 0 0;
        }

        label {
            width: 180px;
            padding-right: 10px;
            font-weight: 600;
            font-size: 16px;
        }
    }
}

.thumbnailImageGuest {
    cursor: pointer;
}