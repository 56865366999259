.footerTitle {
  margin-bottom: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

.footerNavList {
  padding: 0;
  margin: 0 0 30px;
  list-style: none;

  @media (max-width: 640px) {
    margin-bottom: 0;
    display: none;
    &.open {
      display: block;
    }
  }

  li {
    margin-bottom: 18px;

    a {
      font-size: 14px;
      color: #fff;
      text-decoration: none;
      transition: 0.2s ease;
      opacity: 0.9;
      font-weight: 400;

      &:hover {
        color: #ffffff;
        opacity: 1;
      }
    }
  }
}

.footerItem {
  flex: 0 0 22%;
  max-width: 22%;
  padding: 0 15px;

  @media (max-width: 640px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .footerTitle {
    @media (max-width: 640px) {
      display: flex;
      align-items: center;
    }

    .icon {
      display: none;

      @media (max-width: 640px) {
        display: block;
        margin-left: 20px;
      }

      svg {
        display: block;

        path {
          fill: #fff;
        }
      }
    }
  }
}

.aboutShalina {
  flex: 0 0 30%;
  max-width: 30%;
  padding: 0 15px;

  @media (max-width: 640px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.aboutShalinaText {
  color: #fff;
  margin-bottom: 26px;
  padding-bottom: 28px;
  line-height: 21px;
  position: relative;
  font-size: 14px;
  width: 293px;
  max-width: 100%;
  font-weight: 500;

  &::after {
    content: "";
    position: absolute;
    height: 1px;
    width: 93%;
    background-color: #999;
    left: 0;
    bottom: 0;
  }
}

.footerSocial {
  margin-bottom: 30px;

  @media (max-width: 640px) {
    margin-bottom: 40px;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;

    li {
      margin-right: 32px;
    }
  }
}

.download {
  flex: 0 0 26%;
  max-width: 26%;
  padding: 0 15px;

  @media (max-width: 640px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 25px;
    padding-top: 25px;
    position: relative;

    &::before {
      content: "";
      width: 293px;
      max-width: 100%;
      position: absolute;
      height: 1px;
      background-color: #999;
      left: 15px;
      top: 0;
    }
  }

  .downloadText {
    color: #fff;
    margin-bottom: 20px;
    line-height: 21px;
  }

  .appWrapper {
    display: flex;
    margin: 0 -5px;

    img {
      margin: 0 5px;
      cursor: pointer;
    }
  }
}
