.signUpWrapper {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 640px) {
        justify-content: flex-start;
    }
    .authBg{
        position: absolute;
        right: 0;
        z-index: -1;
        top: 0;
    }
}
.signUplogo{
    padding-top: 20px;
    .logo{
        height: 50px;
        width: auto;
        @media (max-width: 640px) {
            height: 40px;
        }
    }
}
.profileSetupContainer{
    width: 720px;
    max-width: calc(100% - 30px);
    margin: 30px auto;
    padding: 30px 40px;
    box-shadow: 0px 4px 15px 0px #00000026;
    border-radius: 12px;
    background-color: #fff;
    @media (max-width: 640px) {
        padding: 20px 15px;
    }
    .title{
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 640px) {
            font-size: 18px;
            line-height: 27px;
        }
        .skipCta{
            font-size: 16px;
            font-weight: 600;
            color: var(--primaryBlue);
            cursor: pointer;
        }
    }
    .profileFormCol{
        margin-bottom: 20px;
        @media (max-width: 640px) {
            margin-bottom: 16px;
        }
    }
    .firstStepCtaWrapper{
        margin-top: 34px;
        .firstStepCta{
            width: 313px;
            max-width: 100%;
            margin: 0 auto;
        }
    }
    .secoundStepCtaWrapper{
        text-align: center;
        .secoundStepCta{
            width: 313px;
            max-width: 100%;
            margin: 0 auto;
        }
    }
    .hideStep{
        display: none;
    }
}
.stepwrapper{
    display: flex;
    margin: 0 -6px 26px;
    @media (max-width: 640px) {
        margin-bottom: 20px;
    }
    .step{
        flex: 0 0 calc(50% - 12px);
        max-width: calc(50% - 12px);
        height: 4px;
        background-color: #FCEAE4;
        margin: 0 6px;
        border-radius: 2px;
        &.active{
            background-color: var(--secondaryOrange);
        }
    }
}

.selectIntrest{
    .siLabel{
        margin-bottom: 8px;
        font-size: 14px;
        display: block;
    }
    .interestOptions{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 -5px;
        .interestCheckbox{
            margin: 0 5px 10px;
            cursor: pointer;
            input[type="checkbox"]{
                display: none;
            }
            span{
                border: 1px solid var(--primaryBlue);
                height: 40px;
                padding: 0 26px;
                color: var(--primaryBlue);
                display: inline-block;
                line-height: 40px;
                border-radius: 100px;
            }
            input[type="checkbox"]:checked + span{
                background-color: var(--secondaryOrange);
                color: #fff;
                border-color: var(--secondaryOrange);
            }
        }
    }
}
