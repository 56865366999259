.achievementTabs{
    .loaderContainer{
        min-height: 3px;
    }
    .loaderWrapper {
        margin: 0 -24px;
        @media (max-width: 640px) {
            margin: 0 -15px;
        }
    }
    .custTabs{
        background-color: #E6F0FA;
        padding: 16px 24px 0;
        margin: 0 -24px;
        @media (max-width: 640px) {
            padding: 16px 16px 0;
            margin: 0 -15px;
        }
        :global{
            .MuiTab-root{
                padding: 0;
                text-transform: capitalize;
                font-family: inherit;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                margin-right: 60px;
                color: var(--primaryBlue);
                min-width: unset;
                &.Mui-selected{
                  font-weight: 600;
                }
            }
            .MuiTabs-indicator{
                height: 3px;
                background-color: var(--secondaryOrange);
            }
        }
    }
}
.custTabContent{
    margin-top: 25px;
    @media (max-width: 640px) {
        margin: 20px 0 0;
        padding: 0 15px;
    }
}

.achivementList{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    .cardCol{
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
        padding: 0 8px;
        margin-bottom: 16px;
    }
}


/* achivement card start */
.achivementCard{
    padding: 12px;
    box-shadow: 0px 4px 15px 0px #00000026;
    border-radius: 16px;
    .tag{
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 14px;
    }
    .cardTitle{
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 34px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    .dateShare{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 11px;
        border-top: 1px solid #E0E0E0;
        .date{
            display: flex;
            align-items: center;
            font-size: 12px;
            svg{
                margin-right: 6px;
            }
            span{
                color: var(--textColor);
                font-weight: 500;
                margin-right: 4px;
            }
        }
        .shareIcons{
            display: flex;
            align-items: center;
            margin: 0 -15px;
            button{
                margin: 0 15px;
                background-color: transparent;
                border: none;
                padding: 0;
                cursor: pointer;
            }
        }
    }
}
/* achivement card end */


/* achivement Points start */
.pointCta{
    margin: 0 -6px 25px;
    @media (max-width: 640px) {
        margin: 0 -6px 20px;
    }
    button{
        padding: 0 14px;
        background-color: transparent;
        border: 1px solid var(--primaryBlue);
        color: var(--primaryBlue);
        font-size: 14px;
        font-weight: 600;
        height: 30px;
        margin: 0 6px;
        border-radius: 100px;
        &.active{
            background-color: var(--secondaryOrange);
            border: var(--secondaryOrange);
            color: #fff;
        }
    }
}
.pointTable{
    margin-top: 30px;
    @media (max-width: 640px) {
        margin-top: 20px;
    }
    .pointTableHead{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #8080804D;
        padding-bottom: 16px;
        margin-bottom: 16px;
        font-size: 16px;
        @media (max-width: 640px) {
            padding-bottom: 10px;
            font-size: 14px;
        }
        .accumulated{
            font-weight: 500;
        }
        .Totalpoint{
            font-weight: 600;
            span{
                color: var(--secondaryOrange);
            }
        }
    }
}
.pointList{
    .pointItem{
        margin-bottom: 24px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &.disabled {
            opacity: 0.4;
            cursor: default;
        }
        @media (max-width: 640px) {
            align-items: flex-start;
            margin-bottom: 15px;
        }
        .pointText{
            padding-right: 10px;
            &.pointText2{
                padding-right: 0;
                min-width: 120px;
            }
            @media (max-width: 640px) {
                max-width: calc(100% - 150px);
            }
            span{
                color: var(--secondaryOrange);
                font-weight: 600;
            }
            strong{
                font-weight: 400;
                color: var(--neutral2);
                margin-left: 4px;
            }
        }
        .pointDate{
            display: flex;
            align-items: flex-start;
            color: var(--neutral2);
            min-width: 215px;
            @media (max-width: 640px) {
                min-width: unset;
                width: 145px;
            }
            svg{
                margin-right: 6px;
                margin-top: 4px;
                flex: 0 0 12px;
            }
            span{
                color: var(--textColor);
                font-weight: 500;
                margin-right: 4px;
            }
        }
    }
}
/* achivement Points end */


/* Membership & Benefits start */
.totalPoint{
    font-size: 18px;
    font-weight: 500;
    color: var(--neutral2);
    margin-bottom: 16px;
    span{
        font-size: 20px;
        font-weight: 600;
        color: var(--primaryBlue);
    }
    @media (max-width: 640px) {
        font-size: 16px;
        span{
            font-size: 18px;
        }
    }
}
.pointProcessWrap{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .pointbox{
        position: relative;
        span{
            position: absolute;
            white-space: nowrap;
            bottom: -22px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 14px;
            line-height: 21px;
            color: var(--neutral2);
        }
    }
    .pointImg{
        height: 72px;
        width: 72px;
        border-radius: 50%;
        border: 6px solid var(--lightOrange);
        position: relative;
        z-index: 1;
        @media (max-width: 640px) {
            height: 40px;
            width: 40px;
            border: 3px solid var(--lightOrange);
        }
        &.active{
            border-color: var(--secondaryOrange);
        }
    }
    .pointProgressBar{
        position: absolute;
        height: 8px;
        width: calc(100% - 144px);
        left: 72px;
        top: calc(50% - 6px);
        background-color: var(--lightOrange);
        @media (max-width: 640px) {
            height: 4px;
            width: calc(100% - 80px);
            left: 40px;
            top: calc(50% - 3px);
        }
        
        .pointProgress{
            position: absolute;
            height: 100%;
            background-color: var(--secondaryOrange);
            border-radius: 0 10px 10px 0px;
            top: 0;
            left: 0;
        }
    }
}
.pointLabels{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    margin: 10px 0px 0;
    color: var(--neutral2);
    span{
        min-width: 100px;
    }
    span.active{
        color: var(--textColor);
        font-weight: 500;
        min-width: 72px;
        text-align: center;
    }
}

.benefitList{
    margin: 50px -15px 0;
    @media (max-width: 640px) {
        margin: 30px -15px 0;
    }
    :global{
        .slick-list{
            padding-top: 20px;
            .slick-slide{
                padding: 0 15px;
                margin-bottom: 18px;
            }
        }
    }
    .benefitCol{
        // flex: 0 0 30%;
        // transform: scale(0.9);
        transition: 0.3s ease;
        @media (max-width: 640px) {
            flex: 0 0 100%;
            transform: scale(0.95);
            margin-bottom: 16px;
        }
        &:hover{
            transform: scale(1.05);
        }
        // &.active{
        //     transform: scale(1);
        // }
    }
}
.titleArrow{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 15px;
    h4{
        font-size: 22px;
        line-height: 20px;
        font-weight: 500;
    }
}
.sliderArrow{
    button{
        height: 32px;
        width: 32px;
        //border: 1px solid var(--primaryBlue);
        background-color: transparent;
        border-radius: 50%;
        cursor: pointer;
        padding: 0px;
        svg{
            overflow: visible;
        }
        &.prev{
            margin-right: 7px;
            svg{
                transform: rotate(180deg);
            }
        }
        &:hover{
        svg{
            rect, path{stroke: var(--btnHover);}
        }
        }
        &:disabled {
            opacity: 0.2;
            cursor: not-allowed;
        }
    }
    @media (max-width: 640px) {
        display: none;
    }
}
.benefitBox{
    box-shadow: 0px 4px 15px 0px #00000026;
    border-radius: 16px;
    .head{
        padding: 14px 11px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        color: #45484B;
        border-radius: 16px 16px 0 0;
        background-color: var(--lightBlue);
        .pointImg{
            margin-right: 8px;
            height: 29px;
            border-radius: 50%;
        }
        p{
            font-size: 14px;
        }
        &.bronze{
            background: radial-gradient(74.11% 342.54% at 53.05% 42.86%, #F4F4F4 0%, #D9A37F 52.46%, #BC7C4C 79.99%, #805329 100%);
        }
        &.silver{
            background: radial-gradient(74.11% 342.54% at 53.05% 42.86%, #F3F3F3 0%, #D6D9DB 52.46%, #ADB0B3 79.99%, #888B8E 100%);
        }
        &.gold{
            background: radial-gradient(74.11% 342.54% at 53.05% 42.86%, #F3F3F3 0%, #E6DE8E 52.46%, #DFC952 79.99%, #BC9647 100%);
        }
    }
    .benefitBoxBody{
        padding: 20px 11px;
        font-size: 14px;
        ul{
            list-style: none;
            padding: 0;
            margin: 0;
            li{
                position: relative;
                margin-bottom: 12px;
                font-size: 14px;
                padding-left: 21px;
            }
            svg{
                position: absolute;
                top: 4px;
                left: 0;
            }
            span{
                display: block;
                font-weight: 500;
            }
        }
    }
    /* request css start */
    .statusCtas {
    display: flex;
    align-items: center;
    margin: 0 -4px 30px;

    button {
        min-width: 67px;
        height: 30px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 100px;
        margin: 0 4px;
        background-color: transparent;
        color: var(--primaryBlue);
        border: 1px solid var(--primaryBlue);

        &.active {
            background-color: var(--secondaryOrange);
            color: #fff;
            border-color: var(--secondaryOrange);
        }
    }
}
.rssListing {
    padding-bottom: 30px;
  
    .pageTitle {
      font-size: 22px;
      font-weight: 500;
      line-height: 33px;
      color: #fff;
      margin-bottom: 0px;
      @media (max-width: 640px) {
        font-size: 18px;
        line-height: 27px;
      }
    }

  
    :global {
      .infinite-scroll-component {
        overflow: unset !important;
      }
    }
  }
  
}

.rssFilterWrapper {
    display: flex;
    flex-wrap: wrap;
    background-color: var(--primaryBlue);
    padding: 0 16px 12px;
    margin: 0 -24px;
    @media (max-width: 640px) {
      flex-wrap: wrap;
      padding: 0 15px 14px;
      margin: 0 -15px;
    }
    .searchTitle{
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      color: #fff;
      flex: 0 0 100%;
      margin-top: 15px;
      @media (max-width: 640px) {
        font-size: 16px;
        line-height: 20px;
        padding: 0 4px;
      }
    }
    :global {
      .globalLabel {
        color: #fff;
      }
      .MuiAutocomplete-root {
        background-color: #fff;
        border-radius: 4px;
      }
    }
    .qualification {
      margin-top: 12px;
      margin-right: 16px;
      flex: 1 0 0;
      @media (max-width: 640px) {
        flex: 0 0 100%;
        margin-right: 0;
        padding: 0 4px;
      }
    }
    .startEndDate {
      margin-top: 12px;
      margin-right: 16px;
      @media (max-width: 640px) {
        flex: 0 0 50%;
        max-width: 50%;
        margin: 12px 0 0;
        padding: 0 4px;
      }
      label {
        margin-bottom: 8px;
        font-size: 14px;
        display: block;
        color: #fff;
      }
      input {
        height: 48px;
        padding: 0 10px;
        font-family: var(--primaryFont);
        font-size: 16px;
        border-radius: 4px;
        outline: none;
        width: 100%;
        max-width: 170px;
        border: 1px solid #8080804D;
        &:focus{
          border-color: #1976d2;
        }
        @media (max-width: 640px) {
            height: 40px;
        }
      }
    }
    .submit {
      background-color: var(--secondaryOrange);
      border: 1px solid var(--secondaryOrange);
      border-radius: 100px;
      font-size: 14px;
      font-weight: 600;
      height: 48px;
      width: 133px;
      text-transform: capitalize;
      color: #fff;
      transition: 0.3s ease;
      margin-top: 38px;
      @media (max-width: 640px) {
        width: 100%;
        margin-top: 15px;
        height: 40px;
      }
      &:hover {
        background-color: var(--secondaryBtnHover);
      }
      &:disabled {
        opacity: 0.4;
        cursor: default;
        &:hover {
          background-color: var(--secondaryOrange);
        }
      }
    }
  }
/* Membership & Benefits end */

.listingWrapper {
    :global{
      .infinite-scroll-component {
        overflow: unset !important;
      }
    }
  }