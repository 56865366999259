

/* tab css start */
.profileTabs{
    .custTabs{
        background-color: #E6F0FA;
        padding: 16px 24px 0;
        margin: 0 -24px;
        @media (max-width: 640px) {
            padding: 0px 15px 0;
            margin: 0 -15px;
        }
        :global{
            .MuiTab-root{
                padding: 0;
                text-transform: capitalize;
                font-family: inherit;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                margin-right: 60px;
                color: var(--primaryBlue);
                &.Mui-selected{
                  font-weight: 600;
                }
                &:last-child{
                    margin-right: 0;
                }
                @media (max-width: 640px) {
                    margin-right: 24px;
                }
            }
            
            .MuiTabs-indicator{
                height: 3px;
                background-color: var(--secondaryOrange);
            }
        }
    }
    .custTabContent{
        padding-top: 30px;
        @media (max-width: 640px) {
            padding-top: 20px;
        }
        :global{
            .MuiBox-root{
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}
/* tab css end */

// .profileUpdateForm{
//     display: flex;
//     flex-wrap: wrap;
//     margin: 0 -8px;
//     .profileUpdateFormCol6{
//         flex: 0 0 50%;
//         max-width: 50%;
//         padding: 0 8px;
//         margin-bottom: 24px;
//         position: relative;
//     }
//     .profileUpdateFormCol12{
//         flex: 0 0 100%;
//         max-width: 100%;
//         padding: 0 8px;
//     }
//     .referralCode{
//         label{
//             margin-bottom: 8px;
//             font-size: 16px;
//             font-weight: 600;
//             display: block;
//         }
//     }
//     .profileUpdateFormCtas{
//         display: flex;
//         flex-wrap: wrap;
//         align-items: center;
//         justify-content: flex-end;
//         margin: 20px -6px 0;
//         button{
//             height: 45px;
//             width: 165px;
//             text-align: center;
//             border: 1px solid var(--primaryBlue);
//             border-radius: 100px;
//             margin: 0 6px;
//             background-color: transparent;
//             font-weight: 600;
//             color: var(--primaryBlue);
//             cursor: pointer;
//             font-size: 16px;
//             &.save{
//                 background-color: var(--primaryBlue);
//                 color: #fff;
//             }
//             &:disabled{
//                 background-color: var(--neutral2);
//                 border-color: var(--neutral2);
//             }
//         }
//     }
//     .verifiedLabel{
//         position: absolute;
//         top: 0;
//         right: 8px;
//         color: #188423;
//         display: flex;
//         align-items: center;
//         font-weight: 700;
//         svg{
//             margin-left: 5px;
//         }
//     }
//     .verifiedCta{
//         text-align: right;
//         margin-top: 8px;
//         button{
//             background-color: var(--primaryBlue);
//             font-size: 12px;
//             font-weight: 600;
//             color: #fff;
//             height: 28px;
//             width: 64px;
//             border-radius: 100px;
//             cursor: pointer;
//         }
//     }
// }