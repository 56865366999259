.userSecWrapper {
  margin-left: 31px;
  height: 70px;
  display: flex;
  align-items: center;
  position: relative;
  &.active::after {
    content: '';
    height: 3px;
    position: absolute;
    background: var(--secondaryOrange);
    width: 100%;
    bottom: 0;
    left: 0;
    border-radius: 10px;
  }
}
.userSec {
  position: relative;

  .arrow {
    height: 17px;
    width: 17px;
    position: absolute;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    bottom: 3px;
    right: -3px;
    cursor: pointer;
    border: 2px solid #fff;
    svg path {
      fill: var(--primaryBlue);
    }
  }
  img {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    object-fit: cover;
  }
}
.userDropdown {
  position: absolute;
  width: 250px;
  right: 0;
  background-color: #fff;
  z-index: 1;
  padding: 22px 16px 7px;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 12px;
  display: none;
  z-index: 3;
  &.open {
    display: block;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: 12px;
      .icon {
        margin-right: 8px;
        width: 21px;
      }
      a,
      .userDropdownItem {
        background-color: #fdfdfd;
        text-decoration: none;
        display: flex;
        align-items: center;
        padding: 7px 12px;
        border-radius: 4px;
        border: 1px solid transparent;
        font-weight: 500;
        color: #444;
        transition: 0.3s ease;
        cursor: pointer;
        &:hover {
          border-color: #e5e5e5;
          color: #000;
        }
      }
    }
  }
}

/* refre doctor modal start */
.referModal {
  :global {
    .MuiPaper-root {
      width: 429px;
      max-width: 100%;
      border-radius: 12px;
    }
    .MuiDialogContent-root {
      padding: 24px;
      color: var(--textColor);
    }
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 19px;
    font-weight: 600;
    margin-bottom: 15px;
    span {
      font-size: 28px;
      font-weight: 400;
      cursor: pointer;
    }
  }
  .referText {
    margin-bottom: 32px;
    font-size: 14px;
    line-height: 21px;
    strong {
      font-weight: 600;
      margin-left: 4px;
    }
  }
  button {
    background-color: var(--primaryBlue);
    height: 40px;
    width: 100%;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    border-radius: 100px;
  }
}
/* refre doctor modal end */

.guestUserIcon {
  cursor: pointer;
}

.isActive {
}

.shareWrap {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  .shareListIcon {
    padding: 0 10px;
    transition: 0.3s ease;
    .copyIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      background-color: var(--primaryBlue);
      cursor: pointer;
      svg {
        height: 22px;
        width: auto;
        path {
          fill: white;
        }
      }
    }
    &:hover {
      transform: scale(1.1);
    }
  }
}
