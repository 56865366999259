.listingPage {
  padding-bottom: 0px;

  .pageTitle {
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    color: #fff;
    margin-bottom: 16px;
    padding-top: 25px;
    @media (max-width: 640px) {
      font-size: 18px;
      line-height: 27px;
      padding-top: 20px;
    }
  }

  .listingTop {
    padding-bottom: 24px;
    background-color: var(--primaryBlue);
    @media (max-width: 640px) {
      padding-bottom: 20px;
    }
    .landingFilter{
      @media (max-width: 640px) {
          margin-right: -15px;
      }
    }
  }
}

.listingRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
  padding-top: 36px;
  @media (max-width: 640px) {
    padding-top: 20px;
    margin: 0 -15px;
  }

  .listingCol8 {
    flex: 0 0 74%;
    max-width: 74%;
    padding: 0 8px;
    @media (max-width: 640px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 15px;
    }
  }

  .listingCol4 {
    flex: 0 0 26%;
    max-width: 26%;
    padding: 0 8px;
    @media (max-width: 640px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 15px;
    }
  }
}
.shalinaSec{
  margin-bottom: 16px;
  border-bottom: 1px solid #BAC8D5;
  padding-bottom: 10px;
  // @media (max-width: 640px) {
  //     margin-bottom: 20px;
  // }
  &:last-child{
    border-bottom: 0;
  }
}

.listingCardRow {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -8px;

  .listingCard {
    padding: 0 8px;
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    margin-bottom: 8px;
  }
}
.readingSec{
  margin-bottom: 16px;
  padding: 18px 18px 2px;
  border: 1px solid #f3f3f3;
  border-radius: 16px;
  box-shadow: 0px 4px 15px 0px #00000026;
  background-color: #F5F5F7;
  .title{
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: 16px;
  }
}
.readingCard {
  background-color: #fff;
  padding: 16px 13px;
  margin-bottom: 16px;
  border-radius: 12px;
  box-shadow: 0px 4px 15px 0px #00000026;

  .readingCardTitle {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .msg {
    margin-bottom: 16px;
    font-weight: 400;
    color: var(--neutral2);
    line-height: 21px;
    min-height: 42px;
    font-size: 14px;
  }
  button{
    // height: 32px;
    height: auto;
    width: 100%;
    border-radius: 100px;
    background-color: var(--primaryBlue);
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    transition: 0.3s ease;
    &:hover{
        background-color: var(--btnHover);
    }
  }
}

.contentNotFound {
  width: 510px;
  max-width: 100%;
  margin: 20px auto;
  text-align: center;

  .notFoundImg {
    margin-bottom: 35px;

    img {
      max-width: 100%;
    }
  }

  h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: var(--primaryBlue);
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: var(--neutral2);
  }
}