.shareWrapper {
  position: relative;
}
.shareWrap {
  display: flex;
  align-items: center;
  margin: 0 -8px;
  justify-content: flex-end;

  button {
    height: 40px;
    width: 40px;
    border-radius: 6px;
    background-color: #fff;
    margin: 0 8px;
    position: relative;

    &.disabled {
      opacity: 0.4;
      cursor: default;
    }

    &.likeDisabled {
      cursor: default;
    }
    &.likeActive {
      cursor: default;
    }

    &.active {
      svg path {
        fill: var(--secondaryOrange);
      }
    }
  }

  .downloadLoader {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffffb8;
  }
}

.shareList {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  bottom: -40px;
  right: 0;
  width: 100%;
  @media (max-width: 640px) {
    width: auto;
    margin: 0 -4px;
  }
  .shareListIcon {
    transition: 0.3s ease;
    padding: 0 6px;
    @media (max-width: 640px) {
      padding: 0 10px;
    }
    .copyIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      background-color: var(--primaryBlue);
      cursor: pointer;
      svg {
        height: 22px;
        width: auto;
        path {
          fill: white;
        }
      }
    }
    &:hover {
      transform: scale(1.1);
    }
  }
}
