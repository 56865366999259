.shalina-checkbox{
    :global{
        .MuiFormControlLabel-root{
            align-items: flex-start;
        }
        .MuiCheckbox-root{
            padding-top: 0;
            padding-bottom: 0;
            padding-right: 14px;
            &.Mui-checked{
                color: var(--secondaryOrange);
            }
        }
        .MuiFormHelperText-root.Mui-error{
            color: var(--inputError);
        }
    }
}