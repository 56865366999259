.quickGoList {
  display: flex;
  margin: 20px -5px 30px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: auto;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--neutral2);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--textColor);
  }
  @media (max-width: 640px) {
    margin: 20px -15px 20px;
  }
  .quickGoItem {
    cursor: pointer;
    flex: 0 0 12.5%;
    padding: 0 5px;
    @media (max-width: 640px) {
      padding: 0 10px;
    }
    .icon {
      height: 70px;
      width: 70px;
      border-radius: 50%;
      background-color: var(--primaryBlue);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 12px;
      @media (max-width: 640px) {
        height: 50px;
        width: 50px;
      }
      img {
        //width: 37px; 
        max-width: 51%;
        //height: auto;
        max-height: 50%;
      }
    }
    .name {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      text-align: center;
      @media (max-width: 640px) {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
}
