.referModal {
  :global {
    .MuiPaper-root {
      width: 360px;
      max-width: 100%;
      border-radius: 12px;
    }
    .MuiDialogContent-root {
      padding: 24px;
      color: var(--textColor);
    }
  }
}
/* sign up css start */
.signUpSec {
    position: relative;
    padding-top: 20px;
    text-align: center;
  .closeCta{
    background-color: transparent;
    font-size: 30px;
    position: absolute;
    top: -5px;
    right: 0;
    height: 30px;
    width: 30px;
    line-height: 30px;
    padding: 0;
  }
  img {
    margin: 0 auto 30px;
    display: block;
    width: 80px;
  }
  .title {
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  .desc {
    font-size: 14px;
    color: var(--neutral2);
    line-height: 21px;
    margin-bottom: 30px;
  }
  .cta {
    text-align: center;
    text-decoration: none;
    color: #fff;
    height: 45px;
    line-height: 45px;
    padding: 0px 10px;
    background-color: var(--primaryBlue);
    display: block;
    font-size: 14px;
    font-weight: 600;
    border-radius: 100px;
  }
}
/* sign up css end */

.signIn{
  margin-top: 24px;
  .text{
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }
  .cta {
    background-color: transparent;
    border: 1px solid var(--primaryBlue);
    color: var(--primaryBlue);
    transition: 0.3s ease;
    &:hover{
      border-color: var(--btnHover);
      color: var(--btnHover);
    }
  }
}
