.detailTop {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  margin-bottom: 30px;
  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
}
.guestDesc {
  // background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.9), #fff);
  background: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.9), #fff);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 97px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 0px;

  button {
      width: 343px;
      max-width: 100%;
      height: 45px;
      border-radius: 100px;
      background-color: var(--primaryBlue);
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      font-family: var(--primaryFont);
  }
}
.views {
  display: flex;
  align-items: center;
  color: var(--neutral2);
  // margin-top: 12px;
  font-size: 12px;

  .text {
    font-weight: 500;
    margin-right: 4px;
    color: var(--textColor);
  }
  .icon {
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 640px) {
      width: auto;
    }
  }

  svg {
    margin-right: 8px;
    @media (max-width: 640px) {
      margin-right: 6px;
    }
  }
}

.detailImg {
  flex: 0 0 37.7%;
  max-width: 37.7%;
  @media (max-width: 640px) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  img {
    width: 100%;
    border-radius: 8px;
    height: 194px;
    object-fit: cover;
    display: block;
    &.mobileImg {
      display: none;
    }
    @media (max-width: 640px) {
      // height: 145px;
      display: none;
      &.mobileImg {
        display: block;
      }
    }
  }
}
.tag {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 12px;
  svg,
  img {
    margin-right: 8px;
    height: 14px;
    width: auto;
  }
}
.detailInfo {
  flex: 0 0 62.3%;
  max-width: 62.3%;
  padding-left: 25px;
  @media (max-width: 640px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
  }

  

  .detailTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 24px;
    @media (max-width: 640px) {
      font-size: 16px;
      line-height: 24px;
      min-height: unset;
      margin-bottom: 16px;
    }
  }

  .shareAndCta {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .downloadPPT {
    width: 300px;
    max-width: 100%;
    margin-top: 24px;
    @media (max-width: 640px) {
      margin-top: 16px;
      width: 100%;
    }
  }
}

.detailActions {
  flex: 1 0;
  margin-top: 24px;
}

.shareAndViews {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 30px;

  .dateViews {
    span {
      display: inline-flex;
      align-items: center;
      margin-right: 28px;
      color: #6b6b6b;
      font-size: 12px;

      svg {
        margin-right: 8px;
      }
    }
  }

  .shareWrap {
    display: flex;
    align-items: center;
    margin: 0 -8px;

    button {
      height: 40px;
      width: 40px;
      border-radius: 6px;
      border: 1px solid #efefef;
      background-color: #fff;
      margin: 0 8px;
    }
  }
}

.articalContent {
  font-size: 16px;
  line-height: 24px;
  // margin-top: 30px;
  position: relative;

  &.guestDescWrapper {
    min-height: 140px;
  }

  p {
    margin-bottom: 10px;
  }

  .guestDesc {
    // background: linear-gradient(
    //   rgba(255, 255, 255, 0.6),
    //   rgba(255, 255, 255, 0.9),
    //   #fff
    // );
    background: linear-gradient(
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.9),
      #fff
    );
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 97px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 0px;
    border-radius: 16px;

    button {
      width: 343px;
      max-width: 100%;
      height: 45px;
      border-radius: 100px;
      background-color: var(--primaryBlue);
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      font-family: var(--primaryFont);
    }
  }
}

.headerTitle {
  background-color: #e6f0fa;
  padding: 18px 24px 18px;
  margin: 0 -24px;
  span {
    padding: 0;
    text-transform: capitalize;
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-right: 116px;
    color: var(--primaryBlue);
  }
}
.datViewWrapper{
  display: flex;
  align-items: center;
  margin-top: 12px;
  @media (max-width: 640px) {
    margin-bottom: 12px;
  }
}
.dateViews {
  margin-right: 10px;
  padding-right: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-right: 1px solid #E0E0E0;

  span {
    display: inline-flex;
    align-items: center;
    color: var(--neutral2);
    font-size: 12px;

    strong {
      color: var(--textColor);
      font-weight: 500;
      margin-right: 4px;
    }

    svg {
      margin-right: 8px;
    }
  }
  &.mobileDate {
    display: none;
    @media (max-width: 640px) {
      display: flex;
    }
  }
}

.customDialog {
  :global {
      .MuiPaper-root {
          // width: 992px;
          // max-width: 100%;
          border-radius: 12px;
          margin: 0 16px;
          max-height: calc(100% - 32px);
      }

      .MuiDialogContent-root {
          padding: 24px;
          color: var(--textColor);

          @media (max-width: 640px) {
              padding: 16px;
          }
      }
  }
  .dialogContent{
      position: relative;
      overflow: hidden;
      .modalHeader {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .fileName {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        text-align: center;
        margin-top: 8px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        flex: 1;
      }
      .closeCta{
          background-color: transparent;
          font-size: 30px;
          height: 30px;
          line-height: 30px;
          padding: 0;
          right: -9px;
          top: -9px;
          width: 30px;
      }
      video {
        margin-top: 16px;
        width: 100% !important;
        max-width: 100% !important;
        max-height: 80vh;
        // object-fit: cover;
      }
  }
}

/* tabs css start */
.shalinaTabs {
  .custTabs {
      background-color: #E6F0FA;
      padding: 16px 24px 0;
      margin: 0 -24px;
      @media (max-width: 640px) {
          margin: 0 -15px;
          padding: 16px 15px 0;
       }

      :global {
          .MuiTab-root {
              padding: 0;
              text-transform: capitalize;
              font-family: inherit;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              margin-right: 60px;
              color: var(--primaryBlue);
              
              &.Mui-selected {
                  font-weight: 600;
              }
              @media (max-width: 640px) {
                  margin-right: 70px;
              }
          }

          .MuiTabs-indicator {
              height: 3px;
              background-color: var(--secondaryOrange);
          }
          .MuiTabs-flexContainer{
            overflow-x: auto;
          }
      }
  }

  .custTabContent {
      position: relative;

      :global {
          .MuiBox-root {
              padding: 24px 0 0;
          }

          .MuiTypography-root {
              font-family: var(--primaryFont);
          }
      }
  }
}

/* tabs css start */

.bookDetail {
  position: relative;
  &.guestDescWrapper {
      min-height: 140px;
      max-height: 140px;
      overflow: hidden;
  }
  p {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-bottom: 24px;

      &:last-child {
          margin-bottom: 0;
      }

      span {
          color: var(--neutral2);
          flex: 1 0 0;
      }

      label {
          width: 180px;
          padding-right: 10px;
          font-weight: 600;
          font-size: 16px;
      }
  }
}

.thumbnailImageGuest {
  cursor: pointer;
}