.whoWeAre{
    .title{
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 16px;
        @media (max-width: 640px) {
            font-size: 18px;
        }
    }
    .weAreInfo{
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: 40px;
        @media (max-width: 640px) {
            margin-bottom: 20px;
        }
        .infoText{
            flex: 0 0 100%;
            max-width: 100%;
            font-size: 16px;
            line-height: 24px;
            p{
                margin-bottom: 15px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        .weAreImg{
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 24px;
            @media (max-width: 640px) {
                margin-bottom: 10px;
            }
            img{
                display: block;
                width: 100%;
                max-height: 384px;
                object-fit: cover;
            }
        }
    }
}
.journeyNav{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    @media (max-width: 640px) {
        overflow-x: scroll;
        margin: 0 -15px 30px;
        padding: 0 15px;
    }
    .navItem{
        padding-right: 16px;
        position: relative;
        &::after{
            content: "";
            position: absolute;
            height: 1px;
            width: 16px;
            background-color: #E0E0E0;
            top: calc(50% - 0.5px);
            right: 0;
        }
        button{
            background-color: var(--lightBlue);
            width: 87px;
            height: 32px;
            border-radius: 100px;
            border: 0;
        }
        &:last-child{
            &::after{display: none;}
        }
        &.active{
            button{
                background-color: var(--primaryBlue);
                font-weight: 600;
                color: #fff;
            }
        }
    }
}

.journeyWrapper{
    border-left: 2px solid var(--primaryBlue);
    padding-left: 30px;
    .journeyItem{
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 3px solid var(--primaryBlue);
        position: relative;
        &::before{
            content: "";
            position: absolute;
            height: 13px;
            width: 13px;
            border-radius: 50%;
            background-color: var(--secondaryOrange);
            top: 3px;
            left: -37.5px;
            border: 2px solid var(--primaryBlue);
            @media (max-width: 640px) {
                left: -39.5px;
            }
        }
        &:last-child{
            border: 0;
            margin-bottom: 0;
        }
        .date{
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 12px;
            color: var(--primaryBlue);
            @media (max-width: 640px) {
                margin-bottom: 16px;
            }
        }
        .imgContent{
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            .contentImg {
                display: block;
                margin-bottom: 12px;
                max-width: 100%;
                max-height: 200px;
                @media (max-width: 640px) {
                    max-width: 150px;
                    height: auto;
                }
            }
            .journeyContent{
                flex: 0 0 100%;
                max-width: 100%;
                padding-right: 34px;
                font-size: 16px;
                line-height: 24px;
                @media (max-width: 640px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding-right: 0px;
                    order: 1;
                }
            }
            .journeyImg{
                flex: 0 0 28%;
                max-width: 28%;
                @media (max-width: 640px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin-bottom: 12px;
                }
                img{
                    width: 100%;
                    border-radius: 12px;
                    display: block;
                    @media (max-width: 640px) {
                        width: auto;
                        border-radius: 12px;
                        height: 190px;
                        display: block;
                    }
                }
            }
        }
        &:nth-child(even){
            .imgContent{
                .journeyContent{
                    order: 1;
                    // padding-left: 34px;
                    padding-right: 0;
                    @media (max-width: 640px) {
                        padding-left: 0px;
                    }
                }
            }
        }
        strong, b{
            font-weight: 600;
        }
    }
}

.journeyContent{
    :global{
        .number{
            display: none;
        }
        img{
            max-width: 100%;
            max-height: 190px;
            display: block;
            margin-bottom: 12px;
            object-fit: cover;
        }
    }
}