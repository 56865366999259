/* help desk css start */
.helpdesk {
  text-align: right;
  .helpdeskCta {
    background-color: #6e6e6e;
    border-radius: 18px;
    box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.2705882353);
    display: inline-flex;
    height: 64px;
    width: 64px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
.helpdeskModal {
  :global {
    .MuiPaper-root {
      width: 510px;
      max-width: 100%;
      border-radius: 12px;
    }
    .MuiDialogContent-root {
      padding: 24px;
      padding-right: 14px;
      margin-right: 10px;
      /* width */
      &::-webkit-scrollbar {
        width: 6px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: none;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: var(--neutral2);
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: var(--textColor);
      }
    }
  }
  .helpdeskHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    .title {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      color: #000;
    }
    .closeCta {
      font-size: 35px;
      cursor: pointer;
    }
  }
}
.shalinaTextArea {
  .textAreaLabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 600;
    span {
      font-size: 12px;
      font-weight: 400;
      color: #909090;
    }
  }
}
.shalinaUploadCta {
  .uploadLabel {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 600;
  }
  .uploadWrapper {
    position: relative;
    border: 1px solid #8080804d;
    border-radius: 8px;
    height: 44px;
    display: block;
    text-align: center;
    padding: 10px 10px;
    cursor: pointer;
    input {
      opacity: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  }
}
.helpdeskForm {
  .helpdeskFormCol {
    margin-bottom: 24px;
  }
}

.helpdeskSubmit {
  img {
    max-width: 100%;
    display: block;
    margin: 0 auto 20px;
  }
  .title {
    margin-bottom: 12px;
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    text-align: center;
  }
  .msg {
    width: 312px;
    max-width: 100%;
    text-align: center;
    line-height: 21px;
    margin: 0 auto 25px;
  }
}
/* help desk css end */

.helpdeskModal {
  &.termsModal {
    :global {
      .MuiPaper-root {
        width: 700px;
        max-width: 100%;
      }
      .MuiDialogContent-root {
        margin-top: 80px;
        padding-top: 0;
      }
    }
  }
}

.popupWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px 20px;

  .closeCTA {
    font-size: 35px;
    cursor: pointer;
  }
}

.recognitionModal{
  :global {
    .MuiPaper-root {
      width: 520px;
      max-width: 100%;
      border-radius: 12px;
    }
    .MuiDialogContent-root {
      padding: 24px;
      padding-right: 14px;
      margin-right: 10px;
      /* width */
      &::-webkit-scrollbar {
        width: 6px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: none;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: var(--neutral2);
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: var(--textColor);
      }
    }
  }
  .recognition{
    position: relative;
    display: inline-block;
    width: 100%;
    padding-bottom: 15px;
    .closeCTA{
      position: absolute;
      top: 0;
      right: 0;
      font-size: 28px;
      cursor: pointer;
    }
    .badgeImg{
      display: block;
      margin: 30px auto;
      width: 90px;
      height: auto;
    }
    .msg{
      width: 370px;
      max-width: 100%;
      margin: 0 auto;
      text-align: center;
      h4{
        margin-bottom: 12px;
        font-size: 18px;
        line-height: 27px;
        font-weight: 600;
        color: var(--primaryBlue);
      }
      p{
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}