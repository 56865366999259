.recommended {
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    h4 {
      font-size: 18px;
      line-height: 27px;
      font-weight: 500;
    }
    button {
      min-width: 86px;
      height: 32px;
      background-color: var(--primaryBlue);
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      border-radius: 100px;
      transition: 0.3s ease;
      &:hover {
        background-color: var(--btnHover);
      }
    }
  }
}
