.notificationWrapper{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .notificationOption{
        margin-bottom: 24px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .notificationBtn{
        input[type="checkbox"]{display: none;}
        div{
            width: 44px;
            height: 24px;
            border-radius: 100px;
            background-color: var(--neutral2);
            position: relative;
            transition: 0.3s ease;
        }
        span{
            height: 16px;
            width: 16px;
            position: absolute;
            background-color: #fff;
            border-radius: 50%;
            top: 4px;
            right: calc(100% - 20px);
            transition: 0.3s ease;
        }
        input[type="checkbox"]:checked{
            &+{
                div{
                    background-color: var(--secondaryOrange);
                    cursor: pointer;
                    span{
                        right: 4px;
                    }
                }
            }
        }
        &.disabled {
            cursor: not-allowed;
            div{
                background-color: var(--secondaryOrange);
            }
        }
    }
    .saveCta{
        text-align: right;
        margin-top: 60px;
        button{
            height: 45px;
            width: 165px;
            border-radius: 100px;
            background-color: var(--primaryBlue);
            color: #fff;
            font-size: 16px;
            font-weight: 600;
            &:disabled {
                cursor: not-allowed;
                background-color: var(--neutral2);
            }
        }
    }
}