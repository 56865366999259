.wrapper{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.title{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
}
.infoText{
    position: relative;
    font-size: 14px;
    margin-bottom: 20px;
    padding-left: 24px;
    svg{
        position: absolute;
        left: 0;
        top: 2px;
    }
}
.accountCtas{
    text-align: right;
    margin: 120px -4px 0;
    @media (max-width: 640px) {
        margin: 80px -4px 0;
        display: flex;
    }
    button{
        height: 45px;
        width: 280px;
        background-color: transparent;
        border: 1px solid var(--primaryBlue);
        color: var(--primaryBlue);
        font-size: 16px;
        font-weight: 600;
        margin: 0 4px;
        border-radius: 100px;
        @media (max-width: 640px) {
            width: 50%;
        }
        &.deactivate{
            background-color: var(--primaryBlue);
            color: #fff;
        }
    }
}

.cautionModal {
    :global {
        .MuiPaper-root {
            width: 510px;
            max-width: 100%;
            border-radius: 12px;
        }

        .MuiDialogContent-root {
            padding: 24px;
            color: var(--textColor);

            @media (max-width: 640px) {
                padding: 16px;
            }
        }
    }

    .modalWrapper{
        .closeCta{
            background-color: transparent;
            font-size: 30px;
            position: absolute;
            top: 20px;
            right: 10px;
            height: 30px;
            width: 30px;
            line-height: 30px;
            padding: 0;
          }
        .modalTitle{
            font-size: 20px;
            line-height: 30px;
            font-weight: 500;
            margin-bottom: 12px;
            color: var(--inputError);
        }
        p{
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 30px;
        }
        .modalCta{
            display: flex;
            align-items: center;
            margin: 0 -8px;
            button{
                border: 1px solid var(--primaryBlue);
                color: var(--primaryBlue);
                font-size: 16px;
                font-weight: 600;
                width: auto;
                flex: 1 0 0;
                height: 45px;
                margin: 0 8px;
                border-radius: 100px;
                background-color: transparent;
                &.sureCta{
                    background-color: var(--primaryBlue);
                    color: #fff;
                    position: relative;
                    &:disabled{
                        cursor: default;
                    }
                }
            }
        }
    }
}