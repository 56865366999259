.sliderContainer {
    width: 100%;
    // margin-bottom: 40px;
    position: relative;
    &.bannerContainer{
        margin-bottom: 0;
        position: relative;
        z-index: 2;
        .sliderWrapper{
            margin: 0 -8px;
        }
    }
    .title{
        font-size: 22px;
        line-height: 20px;
        font-weight: 500;
        color: #fff;
        margin-bottom: 24px;
        @media (max-width: 640px) {
            margin-bottom: 16px;
            font-size: 15px;
        }
    }
    :global{
        .slick-list{
            @media (max-width: 640px) {
                padding-left: 0 !important;
            }
        }
        .slick-track{
            margin-left: 0;
            display: flex;
            .slick-slide{
                height: inherit;
                &>div{height: 100%;}
            }
        }
    }
}
.sliderWrapper{
    
    // margin-bottom: 36px;
    position: relative;
    border-radius: 12px;
    :global{
        .slick-dots{
            bottom: 20px;
            li{
                height: auto;
                width: auto;
                button{
                    height: 10px;
                    width: 10px;
                    background-color: #E1E1E1;
                    border-radius: 50%;
                    &::before{display: none;}
                }
                &.slick-active{
                    button{
                        background-color: #000;
                    }
                }
            }
        }
    }
    
}
.headCta{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
    min-height: 37px;
    padding-right: 20px;
    @media (max-width: 640px) {
        margin-bottom: 12px;
        min-height: 27px;
        padding-right: 0px;
    }
    .title{
        font-size: 22px;
        line-height: 20px;
        font-weight: 500;
        color: var(--textColor);
        margin-bottom: 0;
        @media (max-width: 640px) {
            font-size: 15px;
        }
    }
}
.ctaWrapper{
    display: flex;
    align-items: center;
    .viewAll{
        margin-right: 18px;
        font-size: 14px;
        font-weight: 600;
        padding: 5px 10px;
        min-width: 86px;
        height: 32px;
        margin-bottom: 3px;
        cursor: pointer;
        background-color: var(--primaryBlue);
        border-radius: 100px;
        color: #fff;
        transition: 0.3s ease;
        &:hover{
            background-color: var(--btnHover);
        }
        @media (max-width: 640px) {
            margin-right: 0px;
            padding: 2px 10px;
            width: 79px;
            height: 24px;
            font-size: 12px;
        }
    }
    .sliderArrow{
        button{
            height: 32px;
            width: 32px;
            //border: 1px solid var(--primaryBlue);
            background-color: transparent;
            border-radius: 50%;
            cursor: pointer;
            padding: 0px;
            svg{
                overflow: visible;
            }
            &.prev{
                margin-right: 7px;
                svg{
                    transform: rotate(180deg);
                }
            }
            &:hover{
            svg{
                rect, path{stroke: var(--btnHover);}
            }
            }
            &:disabled {
                opacity: 0.2;
                cursor: not-allowed;
            }
        }
        @media (max-width: 640px) {
            display: none;
        }
    }
}

.cardContainer{
    margin: 0 -8px;
    @media (max-width: 640px) {
        margin-right: -15px;
    }
}
.sliderContainer.sliderDark{
    .headCta{
        padding-right: 0;
        .title{
            color: #fff;
        }
    }
    .ctaWrapper{
        .viewAll{
            border: 1px solid #fff;
        }
    }
    .sliderArrow{
        button{
            svg {
                rect{stroke: #fff;}
                path{stroke:#fff;}
            }
        }
    }
}

.contentNotFound{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 20px;
    .notFoundImg{
        width: 223px;
        max-width: 100%;
        img{
            max-width: 100%;
        }
    }
    .notFoundText{
        width: 538px;
        max-width: 100%;
        padding-left: 40px;
        h3{
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
            margin-bottom: 10px;
            color: var(--primaryBlue);
        }
        p{
            font-size: 16px;
            line-height: 24px;
            color: var(--neutral2);
        }
    }
    &.dark{
        .notFoundText{
            h3{
                color: #fff;
            }
        }
        .notFoundImg{
            background-color: #fff;
            border-radius: 50%;
        }
    }
}
.sliderContainer.sliderDark{
    .contentNotFound{
        .notFoundText{
            h3{
                color: #fff;
            }
        }
        .notFoundImg{
            background-color: #fff;
            border-radius: 50%;
        }
    }
}
.sliderContainer.eBookBg {
    position: relative;
    padding-top: 24px;
    padding-bottom: 24px;
    &::before{
        content: "";
        background-color: #FBE9D4;
        position: absolute;
        width: calc(100% + 24px);
        height: 100%;
        top: 0;
        left: -24px;
        z-index: -1;
        border-radius: 16px;
    }
}
// .sliderContainer.resources {
//     position: relative;
//     padding-top: 24px;
//     padding-bottom: 24px;
//     @media (max-width: 640px) {
//         padding-top: 20px;
//         padding-bottom: 20px;
//     }
//     &::before{
//         content: "";
//         background-color: #F2F2F2;
//         position: absolute;
//         width: calc(100% + 32px);
//         height: 100%;
//         top: 0;
//         left: -16px;
//         z-index: -1;
//         border-radius: 16px;
//         @media (max-width: 640px) {
//             width: calc(100% + 30px);
//             left: -15px;
//             border-radius: 0px;
//         }
//     }
// }

.emptyLoader {
    height: 300px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: white;
}
