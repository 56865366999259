.shalinaSelect{
    label{
        margin-bottom: 8px;
        font-size: 14px;
        display: block;
        span{
            color: var(--errorColor);
            margin-left: 4px;
        }
    }
    :global{
        .MuiSelect-select{
            height: 48px !important;
            padding: 0 10px !important;
            line-height: 44px;
            // width: calc(100% - 42px);
            width: 100%;
            color: var(--textColor);
            position: relative;
            z-index: 9;
        }
        .MuiInputBase-root{
            &.Mui-error{
                .MuiOutlinedInput-notchedOutline{
                    border-color: var(--inputError);
                }
            }
        }
        .MuiFormHelperText-root.Mui-error{
            color: var(--inputError);
        }
    }
}
:global{
    .countrySelect{
        width: 115px;
        flex:  0 0 115px;
        max-width: 115px;
        margin-right: 15px;
    }
}
.countyFlag{
    display: flex;
    padding: 4px 0;
    align-items: center;
    img{
        height: 20px;
        width: 20px;
        margin-right: 10px;
        object-fit: cover;
    }
    .countryName {
        color: var(--neutral2);
        margin-left: 5px;
    }
}
.selectLabel{
    opacity: 1 !important; 
}

.selectIcon {
    position: absolute;
    right: 10px;
}