
.kHub {
    background-color: green;
}
.aboutQuicklink{
    position: sticky;
    top: 16px;
    z-index: 2;
    margin-bottom: 32px;
}
/* search page start */
.searchResult{
    padding-top: 36px;
    padding-bottom: 100px;
    .pageHead{
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 16px;
    }
}
.searchResults{
    padding-bottom: 50px;
}
.resultTop{
    padding-top: 45px;
    padding-bottom: 25px;
    background-color: var(--primaryBlue);
    margin-bottom: 24px;
    .resultHead{
        color: #fff;
        font-size: 22px;
        line-height: 33px;
        font-weight: 500;
        margin-bottom: 16px;
    }
}
.searchWrapper{
    background-color: #fff;
    padding: 25px;
    width: 990px;
    max-width: 100%;
    border-radius: 16px;
}
.searchTag{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0 -4px;
    li{
        margin: 0 4px;
        span{
            display: inline-block;
            padding: 3px 14px;
            border: 1px solid #FFFFFF;
            border-radius: 100px;
            color: #fff;
            font-size: 14px;
            line-height: 21px;
            font-weight: 600;
            cursor: pointer;
        }
        input[type="checkbox"]{
            display: none;
            &:checked + span{
                background-color: #fff;
                color: var(--primaryBlue);
            }
        }
    }
}
.searchCard{
    border-radius: 16px;
    margin-bottom: 24px;
    padding: 24px;
    box-shadow: 0px 4px 15px 0px #00000026;
    background-color: #fff;
    width: 840px;
    max-width: 100%;
    .tag{
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        font-size: 12px;
        svg{
            margin-right: 10px;
            height: 15px;
            width: auto;
            path{
                fill: var(--primaryBlue);
            }
        }
    }
    .searchCardTitle{
        font-size: 16px;
        line-height: 24px;
        span{
            display: block;
        }
    }
}

.notFound{
    .notFoundImg{
        display: block;
        margin: 0 auto 60px;
    }
    h4{
        font-size: 24px;
        line-height: 36px;
        font-weight: 600;
        margin-bottom: 10px;
        color: var(--primaryBlue);
        text-align: center;
    }
    p{
        font-size: 16px;
        line-height: 24px;
        width: 440px;
        max-width: 100%;
        text-align: center;
        margin: 0 auto 100px;
        color: var(--neutral2);
    }
}
/* search page end */



/* about page start */
.aboutRow{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    @media (max-width: 640px) {
        margin: 0 -15px;
    }
    .aboutCol8{
        flex: 0 0 74%;
        max-width: 74%;
        padding: 0 8px;
        @media (max-width: 640px) {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0 ;
        }
    }
    .aboutCol4{
        flex: 0 0 26%;
        max-width: 26%;
        padding: 0 8px;
        @media (max-width: 640px) {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0 15px;
        }
    }
}
.aboutPage{
    .aboutTop{
        background-color: var(--primaryBlue);
        padding: 24px 0;
        margin-bottom: 36px;
        @media (max-width: 640px) {
            margin-bottom: 0;
            padding: 20px 0;
        }
    }
    .head{
        color: #fff;
        font-size: 22px;
        font-weight: 500;
        line-height: 33px;
        margin: 0;
        padding: 0;
        @media (max-width: 640px) {
            font-size: 18px;
            line-height: 27px;
        }
    }
    .aboutPageWrapper{
        background-color: #fff;
        border-radius: 16px;
        box-shadow: 0px 4px 15px 0px #00000026;
        margin-bottom: 32px;
        @media (max-width: 640px) {
            border-radius: 0;
            box-shadow: none;
        }
    }
}

/* who we are start */
.whoWeAre{
    .title{
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 16px;
    }
    .weAreInfo{
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: 24px;
        .infoText{
            flex: 0 0 60%;
            max-width: 60%;
            padding-right: 20px;
            font-size: 16px;
            line-height: 24px;
            p{
                margin-bottom: 15px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        .weAreImg{
            flex: 0 0 40%;
            max-width: 40%;
            img{
                width: 100%;
                border-radius: 12px;
            }
        }
    }
}
.ourMission{
    .infoText{
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
    }
}
/* who we are end */


.shalinaTabs{
    .custTabs{
        padding: 24px 24px 0;
        box-shadow: 0px 4px 15px 0px #00000026;
        border-radius: 16px 16px 0 0;
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 1;
        @media (max-width: 640px) {
            padding: 20px 15px 0;
            border-radius: 0;
        }
        :global{
            
            .MuiTab-root{
                padding: 0;
                text-transform: capitalize;
                font-family: inherit;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                margin-right: 60px;
                color: var(--primaryBlue);
                &.Mui-selected{
                  font-weight: 600;
                }
                &:last-child{
                    margin-right: 0;
                }
                @media (max-width: 640px) {
                    margin-right: 24px;
                }
            }
            .MuiTabs-indicator{
                height: 3px;
                background-color: var(--secondaryOrange);
            }
            .MuiTabs-flexContainer{
                overflow-x: auto;
            }
        }
    }
    .custTabContent{
        :global{
            .MuiTypography-root{
                font-family: var(--primaryFont);
            }
            .MuiBox-root{
                padding: 24px 24px;
                @media (max-width: 640px) {
                    padding: 20px 15px;
                }
            }
        }
    }
}