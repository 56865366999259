
/* survey card start */
.cardContainer {
  
}
.cardWrapper {
    padding: 12px;
    border-radius: 12px;
    box-shadow: 0px 4px 8px 0px #00000026;
    background-color: #fff;
    position: relative;
    .contentType {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-bottom: 18px;
        span {
          margin-left: 6px;
        }
        svg path{fill: var(--primaryBlue);}
        img {
          width: auto;
          height: 14px;
        }
    }
    .titleImg {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 18px;
        min-height: 72px;
        .cardTitle {
          flex: 1 0;
          max-width: calc(100% - 72px);
          padding-right: 14px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          cursor: pointer;
          transform: 0.3s ease;
          &:hover{
            color: var(--primaryBlue);
          }
        }
        img {
          height: 66px;
          width: 66px;
          object-fit: cover;
          object-position: center;
          border-radius: 12px;
        }
    }
    .date{
        color: var(--neutral2);
        font-size: 12px;
        align-items: center;
        display: flex;
        svg{
            margin-right: 6px;
        }
        span{
            color: var(--textColor);
            font-weight: 500;
            margin-right: 4px;
        }
    }
    .postBy{
        display: flex;
        align-items: center;
        font-size: 12px;
        color: var(--neutral2);
        border-top: 1px solid #E0E0E0;
        padding-top: 16px;
        margin-top: 16px;
        svg{
            margin-right: 8px;
            max-width: 22px;
            max-height: 22px;
        }
        span{
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
            max-width: calc(100% - 30px);
            strong{
                color: var(--textColor);
                font-weight: 500;
                margin-right: 4px;
            }
        }
    }
}

.speciality{
  min-height: 40px;
  margin-bottom: 2px;
  display: inline-block;
  width: 100%;
}
/* survey card end */