.interestOptions{
    margin: 0 -5px;
}
.interestCheckbox{
  margin: 0 5px 10px;
  display: inline-block;
  cursor: pointer;
  input[type="checkbox"]{
      display: none;
  }
  span{
      border: 1px solid var(--primaryBlue);
      height: 40px;
      padding: 0 26px;
      color: var(--primaryBlue);
      display: inline-flex;
      align-items: center;
      border-radius: 100px;
      font-weight: 500;
      @media (max-width: 640px) {
        height: 34px;
        padding: 0 12px;
        font-size: 14px;
      }
  }
  input[type="checkbox"]:checked + span{
      background-color: var(--secondaryOrange);
      color: #fff;
      border-color: var(--secondaryOrange);
  }
}