/* quick Link start */
.quickLink {
  margin-bottom: 16px;
  padding: 18px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 4px 15px 0px #00000026;

  .title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 24px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin-bottom: 27px;
      cursor: pointer;
      &:last-child{
        margin-bottom: 10px;
      }
    }
    a, .quickLinkItem {
      font-size: 14px;
      font-weight: 500;
      color: var(--textColor);
      text-decoration: none;
      display: flex;
      align-items: center;
      &:hover{
        color: var(--primaryBlue);
      }
    }
    .iconWrapper{
      width: 24px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img, svg {
      max-width: 20px;
    }
  }
}
/* quick Link end */
