
.navSearchWrapper{
  @media (max-width: 767px) {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #fff;
    z-index: 100;
    top: 0;
    left: 0;
    padding: 0 15px;
    overflow: auto;
    transform: translateX(100%);
    transition: 0.3s ease;
    &.open{
      transform: translateX(0%);
    }
  }
  .closeSearch{
    display: none;
    text-align: right;
    padding: 12px 0;
    @media (max-width: 767px) {
      display: block;
    }
    button{
      background-color: transparent;
      font-size: 25px;
      font-weight: 500;
      padding: 0;
      height: 20px;
      width: 20px;
      line-height: 20px;
    }    
  }
}
.navSearch {
  background-color: #f5f5f5;
  border-radius: 100px;
  position: relative;
  @media (max-width: 767px) {
    background-color: transparent;
  }
  svg {
    position: absolute;
    left: 11px;
    top: calc(50% - 7px);
    height: 14px;
    width: auto;
    @media (max-width: 767px) {
      top: 12px;
    }
    path{
      fill: #757575;
    }
  }
  input {
    height: 36px;
    width: 200px;
    background-color: transparent;
    outline: none;
    padding: 0 10px 0 38px;
    &:-internal-autofill-selected,
    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill:active{
      -webkit-box-shadow: 0 0 0 30px #F5F5F5 inset !important;
      border-radius: 100px;
    }
    @media (max-width: 767px) {
      background-color: #f5f5f5;
      border-radius: 100px;
      width: 100%;
    }
  }
  span {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .searchResult {
    position: absolute;
    padding: 20px 20px 7px;
    width: 400px;
    background-color: #fff;
    top: calc(100% + 6px);
    border-radius: 12px;
    right: 0;
    z-index: 3;
    box-shadow: 0px 4px 15px 0px #00000026;
    @media (max-width: 767px) {
      position: relative;
      padding: 20px 5px 7px;
      width: 100%;
      top: 0;
      box-shadow: none;
    }
    .searchTitle {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 18px;
    }
    .recentList {
      margin: 0;
      padding: 0;
      list-style: none;
      div {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 14px;
        p {
          font-size: 14px;
          color: var(--neutral2);
          text-decoration: none;
        }
        span {
          font-size: 14px;
          color: var(--neutral2);
          text-decoration: none;
          cursor: pointer;
          word-break: break-word;
        }
        button {
          background-color: transparent;
          color: #bfbfbf;
          font-size: 18px;
          line-height: 18px;
          padding: 0 3px;
          cursor: pointer;
          border: 0;
        }
      }
    }
  }
  input {
    &:focus ~ .searchResult {
      display: block;
    }
  }
}

.mobileIcon{
  display: none;
  background-color: transparent;
  svg{
    display: block;
  }
  @media (max-width: 767px) {
    display: block;
  }
}