.profileUpdateForm {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
  @media (max-width: 640px) {
    margin: 0;
  }
  .profileUpdateFormCol6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 8px;
    margin-bottom: 24px;
    position: relative;
    @media (max-width: 640px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 15px;
      margin-bottom: 16px;
    }
  }
  .profileUpdateFormCol12 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 8px;
    @media (max-width: 640px) {
      padding: 0 15px;
    }
  }
  .referralCode {
    label {
      display: block;
      margin-bottom: 8px;
      font-size: 14px;
    }
    .referralCodeText{
      font-size: 14px;
      font-weight: 600;
    }
  }
  .profileUpdateFormCtas {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    margin: 30px -6px 0;
    @media (max-width: 640px) {
      margin: 16px -5px 0;
    }
    button {
      height: 45px;
      width: 165px;
      text-align: center;
      border: 1px solid var(--primaryBlue);
      border-radius: 100px;
      margin: 0 6px;
      background-color: transparent;
      font-weight: 600;
      color: var(--primaryBlue);
      cursor: pointer;
      font-size: 16px;
      &:disabled {
        cursor: not-allowed;
        border-color: var(--neutral2);
        color: var(--neutral2);
      }
      &.save {
        background-color: var(--primaryBlue);
        color: #fff;
        position: relative;
        &:disabled {
          background-color: var(--neutral2);
          color: #fff;
        }
      }
      
    }
  }
  .verifiedLabel {
    position: absolute;
    top: 0;
    right: 8px;
    color: #188423;
    display: flex;
    align-items: center;
    font-weight: 700;
    svg {
      margin-left: 5px;
    }
  }
  .verifiedCta {
    text-align: right;
    button {
      background-color: var(--primaryBlue);
      font-size: 12px;
      font-weight: 600;
      color: #fff;
      height: 28px;
      width: 64px;
      border-radius: 100px;
      margin-top: 8px;
      cursor: pointer;
      &:disabled {
        display: none;
      }
    }
  }
}


.verificationModal{
  :global {
    .MuiPaper-root {
      width: 390px;
      max-width: 100%;
      border-radius: 12px;
    }
    .MuiDialogContent-root {
      padding: 24px;
      color: var(--textColor);
    }
  }
  .title{
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 24px;
  }
  .infoText{
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 10px;
    span{
      display: block;
      font-weight: 500;
    }
  }
  .timeLink{
    display: flex;
    align-items: end;
    justify-content: end;
    margin-top: 10px;
    margin-bottom: 25px;
    flex-direction: column-reverse;
    .time{
      font-weight: 500;
      color: var(--neutral2);
      margin-top: 5px;
      span{
        color: var(--errorColor);
        font-weight: 600;
      }
    }
    
  }
}