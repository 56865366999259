.specialityWrapper {
  display: flex;
  margin-bottom: 10px;
  flex-wrap: wrap;
  .specialityItem {
    background-color: #002E5B1A;
    padding: 4px 10px;
    margin-right: 8px;
    margin-bottom: 4px;
    color: var(--primaryBlue);
    border-radius: 30px;
    font-size: 12px;
    display: flex;
    align-items: center;
    max-width: 35%;
    span{
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.fullWidth {
      max-width: 100%;
      span {
        max-width: 100%;
      }
    }
    &.more{
      background-color: transparent;
      border: 1px solid var(--primaryBlue);
      font-weight: 600;
      position: relative;
      padding: 3px 10px;
      margin-right: 0;
      span{
        overflow: visible;
        text-overflow: unset;
        white-space: break-spaces;
      }
      &:hover{
        .tooltipWrapper{
          display: inline-block;
        }
      }
    }
  }
}
.tooltipWrapper{
  display: none;
  position: absolute;
  background-color: #F0F3F5;
  padding: 8px 8px 4px;
  border-radius: 8px;
  top: calc(100% + 10px);
  right: 0px;
  z-index: 1;
  &::after{
    content: "";
    position: absolute;
    top: -6px;
    right: 10px;
    margin-top: -5px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent #F0F3F5 transparent;
  }
}
.tooltip {
  font-size: 11px;
  line-height: 16px;
  min-width: 100px;
  max-height: 64px;
  overflow-x: hidden;
  overflow-y: auto;
  @media (max-width: 640px) {
    max-width: unset;
  }
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  &::-webkit-scrollbar-track {
    box-shadow: none;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--neutral2); 
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--textColor);
  }
  
  .tooltiptext{
    display: block;
    margin-bottom: 6px;
    font-weight: 400;
    white-space: nowrap;
  }
}

