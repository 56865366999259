.listLoader {
    .loaderWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        right: 0;
        z-index: 1400;
        padding: 20px;
    }
}