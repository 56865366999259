.cookiesModal{
  :global {
    .MuiPaper-root {
      width: 512px;
      max-width: 100%;
      border-radius: 12px;
    }
    .MuiDialogContent-root {
      padding: 24px;
      color: var(--textColor);
    }
  }
}
.cookiesBoday{
  text-align: center;
  .logo{
    width: 170px;
    height: auto;
    max-width: 100%;
    margin-bottom: 32px;
  }
  .title{
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  .content{
    p{
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;
    }
  }
  .ctas{
    display: flex;
    align-items: center;
    justify-content: center;
    button{
      width: calc(50% - 16px);
      max-width: calc(50% - 16px);
      height: 48px;
      margin: 0 8px;
      border-radius: 100px;
      background-color: var(--primaryBlue);
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      border: 1px solid var(--primaryBlue);
      &.cancel{
        background-color: transparent;
        color: var(--primaryBlue);
      }
    }
  }
}