/* sign up css start */
.signUpSec {
  position: relative;
  padding: 18px;
  margin-bottom: 16px;
  box-shadow: 0px 4px 15px 0px #00000026;
  background-color: #fff;
  border-radius: 16px;
  text-align: center;
  img {
    margin: 0 auto 24px;
    display: block;
  }
  .title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .desc {
    font-size: 14px;
    color: var(--neutral2);
    line-height: 21px;
    margin-bottom: 12px;
  }
  .cta {
    text-align: center;
    text-decoration: none;
    color: #fff;
    height: 35px;
    line-height: 35px;
    padding: 0px 10px;
    background-color: var(--primaryBlue);
    display: block;
    font-size: 14px;
    font-weight: 600;
    border-radius: 100px;
    transition: 0.3s ease;
    &:hover{
      background-color: var(--btnHover);
    }
  }
  .signIn{
    margin-top: 24px;
    .text{
      margin-bottom: 12px;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
    }
    .cta {
      background-color: transparent;
      border: 1px solid var(--primaryBlue);
      color: var(--primaryBlue);
      transition: 0.3s ease;
      &:hover{
        border-color: var(--btnHover);
        color: var(--btnHover);
      }
    }
  }
}
/* sign up css end */
