/* help desk css start */
.helpdesk {
    text-align: right;
    .helpdeskCta {
      background-color: var(--secondaryOrange);
      border-radius: 50%;
      box-shadow: 0px 2px 15px 0px #00000026;
      display: inline-flex;
      height: 64px;
      width: 64px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: fixed;
      bottom: 20px;
      right: 20px;
      z-index: 3;
    }
  }
  .helpdeskModal {
    :global {
      .MuiPaper-root {
        width: 510px;
        max-width: 100%;
        border-radius: 12px;
      }
      .MuiDialogContent-root {
        padding: 24px;
      }
    }
    .helpdeskHead {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      .title {
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        color: #000;
      }
      .closeCta {
        font-size: 35px;
        cursor: pointer;
      }
    }
  }
  .shalinaTextArea {
    .textAreaLabel {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 400;
      span {
        font-size: 12px;
        font-weight: 400;
        color: #909090;
      }
    }
  }
  .shalinaUploadCta {
    .uploadLabel {
      display: block;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 400;
    }
    .uploadWrapper {
      position: relative;
      border: 1px solid var(--primaryBlue);
      border-radius: 100px;
      height: 44px;
      display: flex;
      text-align: center;
      padding: 10px 10px;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      color: var(--primaryBlue);
      font-size: 14px;
      font-weight: 500;
      input {
        opacity: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
      }
      svg{
        margin-right: 10px;
      }
    }
  }
  .helpdeskForm {
    .helpdeskFormCol {
      margin-bottom: 24px;
    }
  }
  
  .helpdeskSubmit {
    img {
      max-width: 100%;
      display: block;
      margin: 0 auto 20px;
    }
    .title {
      margin-bottom: 12px;
      font-size: 18px;
      line-height: 27px;
      font-weight: 600;
      text-align: center;
    }
    .msg {
      width: 312px;
      max-width: 100%;
      text-align: center;
      line-height: 21px;
      margin: 0 auto 25px;
    }
  }
  /* help desk css end */