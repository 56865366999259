
.loaderWrapper {
    height: 100%;
    width: 100%;
    background-color: #ffffffb5;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1400;
}

.globalLoader{
    position: relative;
    height: 3px;
    overflow: hidden;
 
    .loaderLine{
        position: absolute;
        height: 100%;
        /* left: -100%; */
        top: 0;
        background-color: var(--secondaryOrange);
        animation: globalLoader 2s 1s infinite linear;
    }
}
@keyframes globalLoader{
    0%{
        width: 0%;
    }
    100%{
        width: 120%;
        opacity: 0;
    }
}