.bookmarkFilter {
  margin-bottom: 23px;
  position: relative;
  @media (max-width: 640px) {
    margin-right: -15px;
  }
  .loaderDiv {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -15px;
    // margin: 0 -24px;
  }
}
.bookmarkCards {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
  .bookmarkCard {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    padding: 0 8px;
    margin-bottom: 12px;
    @media (max-width: 640px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.emptyBookmark {
  text-align: center;
  img {
    margin: 0 auto 40px;
    width: 275px;
    max-width: 100%;
    @media (max-width: 640px) {
      margin: 20px auto 40px;
      width: 220px;
    }
  }
  .title {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    margin-bottom: 10px;
    color: var(--primaryBlue);
    @media (max-width: 640px) {
      font-size: 18px;
      line-height: 27px;
    }
  }
  .infoText {
    font-size: 16px;
    line-height: 24px;
    color: var(--neutral2);
    width: 488px;
    max-width: 100%;
    margin: 0 auto 32px;
    @media (max-width: 640px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .ctaExplore {
    width: 343px;
    max-width: 100%;
    margin: 0 auto;
  }
}

.contentNotFound{
  width: 510px;
  max-width: 100%;
  margin: 20px auto;
  text-align: center;
  .notFoundImg{
      margin-bottom: 35px;
      img {
        // margin: 0 auto 40px;
        width: 275px;
        max-width: 100%;
        @media (max-width: 640px) {
          margin: 20px auto 40px;
          width: 220px;
        }
      }
  }
  h3{
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      color: var(--primaryBlue);
      margin-bottom: 10px;
  }
  p{
      font-size: 16px;
      line-height: 24px;
      color: var(--neutral2);
  }
}