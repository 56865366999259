.videoWrapper {
    height: 330px;
    background-color: #232323;
    border-radius: 12px;
    @media (max-width: 640px) {
        margin: -15px -15px 0;
        height: 200px;
    }

    video {
        width: 100% !important;
        height: auto !important;
        background-color: #232323;
        border-radius: 12px;
        // object-fit: contain;
        @media (max-width: 640px) {
            height: 200px !important;
        }
    }
    .imgWrapper{
        position: relative;
        .playIcon{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
        }
        .playIconGuest {
            cursor: not-allowed;
        }
    }
    .thumbnailImage {
        width: 100%;
        height: 330px;
        object-fit: cover;
        border-radius: 12px;
        display: block;
        @media (max-width: 640px) {
            height: 200px;
            border-radius: 0px;
        }
    }
    .thumbnailImageGuest {
        cursor: pointer;
    }
}

.videoInfo {
    margin-top: 30px;
    @media (max-width: 640px) {
        margin-top: 16px;
    }

    .tag {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-bottom: 11px;

        svg,
        img {
            margin-right: 8px;
            height: 14px;
            width: auto;
        }
    }

    .title {
        margin-bottom: 22px;
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        @media (max-width: 640px) {
            margin-bottom: 16px;
            font-size: 16px;
            line-height: 24px;
        }
    }

    .specialityWrap {
        margin: 0 -6px 20px;
        @media (max-width: 640px) {
            margin-bottom: 0;
        }

        .specialityItem {
            margin: 0 6px 5px;
            background-color: var(--lightBlue);
            font-size: 12px;
            padding: 3px 10px;
            border-radius: 12px;
            color: var(--primaryBlue);
        }
    }

    .viewsDate {
        margin-bottom: 27px;
        @media (max-width: 640px) {
            font-size: 12px;
            margin-bottom: 30px;
        }

        .dateViews {
            span {
                display: inline-flex;
                align-items: center;
                border-right: 1px solid #E0E0E0;
                margin-right: 12px;
                padding-right: 12px;
                color: var(--neutral2);

                strong {
                    font-weight: 600;
                    color: var(--textColor);
                    margin-right: 5px;
                }

                svg {
                    margin-right: 8px;
                }

                &:last-child {
                    margin-right: 0;
                    padding-right: 0;
                    border: 0;
                }
            }
        }
    }

    .ctaShare {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 26px;
        @media (max-width: 640px) {
            flex-wrap: wrap;
            margin-bottom: 20px;
        }

        .fullVideoCta {
            flex: 0 0 343px;
            max-width: 100%;
        }

        .shareWrap{
            @media (max-width: 640px) {
                margin-top: 16px;
                flex: 0 0 100%;
            }
        }
    }
}

.videoDesc {
    position: relative;

    &.guestDescWrapper {
        min-height: 140px;
    }
}
.guestDesc {
    // background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.9), #fff);
    background: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.9), #fff);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 97px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 0px;

    button {
        width: 343px;
        max-width: 100%;
        height: 45px;
        border-radius: 100px;
        background-color: var(--primaryBlue);
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        font-family: var(--primaryFont);
    }
}

/* tabs css start */
.shalinaTabs {
    .custTabs {
        background-color: #E6F0FA;
        padding: 16px 24px 0;
        margin: 0 -24px;
        @media (max-width: 640px) {
            margin: 0 -15px;
            padding: 16px 15px 0;
        }

        :global {
            .MuiTab-root {
                padding: 0;
                text-transform: capitalize;
                font-family: inherit;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                margin-right: 60px;
                color: var(--primaryBlue);
                &:last-child{
                    margin-right: 0;
                }
                @media (max-width: 640px) {
                    margin-right: 70px;
                }

                &.Mui-selected {
                    font-weight: 600;
                }
            }

            .MuiTabs-indicator {
                height: 3px;
                background-color: var(--secondaryOrange);
            }
        }
    }

    .custTabContent {
        :global {
            .MuiBox-root {
                padding: 24px 0 0;
            }

            .MuiTypography-root {
                font-family: var(--primaryFont);
            }
        }
    }
}

/* tabs css start */


.speakerList {
    position: relative;
    &.guestDescWrapper {
        min-height: 140px;
        max-height: 140px;
        overflow: hidden;
    }
    .speaker {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #E0E0E0;
        padding-bottom: 20px;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border: 0;
        }

        .speakerImg {
            flex: 0 0 66px;
            max-width: 66px;

            img {
                width: 100%;
                height: 66px;
                border: 4px solid var(--lightBlue);
                border-radius: 50%;
                display: block;
            }
        }

        .speakerinfo {
            flex: 1;
            padding-left: 15px;

            .name {
                font-size: 18px;
                font-weight: 500;
                line-height: 27px;
                margin-bottom: 5px;
            }

            .role {
                font-size: 14px;
                color: var(--neutral2);
                font-weight: 500;
            }
        }
    }
}

.bookDetail {
    position: relative;
    &.guestDescWrapper {
        min-height: 140px;
        max-height: 140px;
        overflow: hidden;
    }
    p {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }

        span {
            color: var(--neutral2);
            flex: 1 0 0;
        }

        label {
            width: 180px;
            padding-right: 10px;
            font-weight: 600;
            font-size: 16px;
        }
    }
}

.publisher {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 16px;
    color: var(--neutral2);
    @media (max-width: 640px) {
        margin-bottom: 12px;
    }

    svg {
        margin-right: 8px;
        // @media (max-width: 640px) {
        //     margin-right: 8px;
        // }
    }
    .authorLabel{
        color: var(--textColor);
        font-weight: 500;
        margin-right: 6px;
    }
    .authorText{
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        max-width: calc(100% - 120px);
        overflow: hidden;
        @media (max-width: 640px) {
            display: block;
            overflow: visible;
        }
    }
}