.listingPage{
    padding-bottom: 30px;
    .pageTitle{
        font-size: 22px;
        font-weight: 500;
        line-height: 33px;
        color: #fff;
        margin-bottom: 16px;
        @media (max-width: 640px) {
            font-size: 18px;
            line-height: 27px;
        }
        span {
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
        }
        strong {
            font-weight: 700;
        }
    }
    .listingTop{
        padding-bottom: 24px;
        min-height: 18vh;
        background-color: var(--primaryBlue);
        @media (max-width: 640px) {
            padding-bottom: 20px;
        }
        .listingFilter{
            @media (max-width: 640px) {
                margin-right: -15px;
            }
        }
        .BreadCrumbHeight {
            height: 60px;
        }
    }
}
.listingRow{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    padding-top: 36px;
    @media (max-width: 640px) {
        padding-top: 20px;
    }
    .listingCol8{
        flex: 0 0 74%;
        max-width: 74%;
        padding: 0 8px;
        @media (max-width: 640px) {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 20px;
        }
    }
    .listingCol4{
        flex: 0 0 26%;
        max-width: 26%;
        padding: 0 8px;
        @media (max-width: 640px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    :global{
        .infinite-scroll-component {
            overflow: unset !important;
        }
    }
}
.listingCards{
    margin-top: -7px;
    .listingCard{
        margin-bottom: 16px;
    }
}
/* Reading section start */
.readingSec{
    margin-bottom: 16px;
    padding: 18px 18px 2px;
    border: 1px solid #f3f3f3;
    border-radius: 16px;
    box-shadow: 0px 4px 15px 0px #00000026;
    background-color: #F5F5F7;
    .title{
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 16px;
    }
}
.readingCard{
    background-color: #fff;
    padding: 16px 13px;
    margin-bottom: 16px;
    border-radius: 12px;
    box-shadow: 0px 4px 15px 0px #00000026;
    .readingCardTitle{
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 28px;
        .icon{
            margin-right: 8px;
            margin-top: 2px;
            img {
                width: auto;
                height: 14px;
            }
        }
        .msg{
            font-size: 16px;
            font-weight: 400;
            span{
                display: block;
                color: var(--neutral2);
                margin-top: 10px;
                font-size: 15px;
                font-weight: 400;
            }
        }
    }
    
    button{
        // height: 32px;
        height: auto;
        width: 100%;
        border-radius: 100px;
        background-color: var(--primaryBlue);
        color: #fff;
        font-size: 12px;
        line-height: 18px;
        font-weight: 600;
        transition: 0.3s ease;
        &:hover{
            background-color: var(--btnHover);
        }
    }
}
.listHeight {
    min-height: 500px;
  }
.contentNotFound{
    width: 510px;
    max-width: 100%;
    margin: 20px auto;
    text-align: center;
    .notFoundImg{
        margin-bottom: 35px;
        img{
            max-width: 100%;
        }
    }
    h3{
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        color: var(--primaryBlue);
        margin-bottom: 10px;
    }
    p{
        font-size: 16px;
        line-height: 24px;
        color: var(--neutral2);
    }
}
/* Readinf section end */

.discoverBtn {
    button {
        text-transform: none;
    }
}