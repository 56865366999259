.mainNav {
  display: flex;
  list-style: none;
  padding: 0;
  margin-right: 16px;
  @media (max-width: 1185px) {
    flex-wrap: wrap;
    margin-right: 0;
  }
  .mainNavitem {
    padding: 0 14px;
    @media (min-width: 1186px) and (max-width: 1310px) {
      padding: 0 10px;
    }
    @media (max-width: 1185px) {
      padding: 13px 15px;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .mainNavitemLink {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 70px;
    color: var(--textColor);
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    @media (min-width: 1186px) and (max-width: 1310px) {
      font-size: 14px;
    }
    @media (max-width: 1185px) {
      height: auto;
    }
    &:hover{
      color: var(--primaryBlue);
    }
    &.active{
      color: var(--primaryBlue);
      font-weight: 600;
      &::after{
        content: "";
        height: 3px;
        position: absolute;
        background: var(--secondaryOrange);
        width: 100%;
        bottom: 0;
        left: 0;
        border-radius: 10px;
        @media (max-width: 1185px) {
          background: transparent;
        }
      }
    }
  }
  .more {
    position: relative;
    padding-right: 22px;
    @media (max-width: 1185px) {
      padding-right: 0;
      display: flex;
      flex-wrap: wrap;
    }
    svg{
      position: absolute;
      top: calc(50% - 4px);
      right: 0;
      @media (max-width: 1185px) {
        position: relative;
        top: 0;
        display: block;
        margin-left: 12px;
      }
      path{
        fill: var(--primaryBlue);
      }
    }
    .moreContainer {
      position: absolute;
      top: calc(100% - 10px);
      transform: translateX(-70%);
      z-index: 9;
      padding: 0;
      line-height: 27px;
      display: none;
      box-shadow: 0px 4px 15px 0px #00000026;
      border-radius: 16px;
      @media (max-width: 1185px) {
        position: relative;
        transform: translateX(0%);
        top: 0;
        left: 0;
        box-shadow: none;
        flex: 0 0 100%;
        width: 100%;
        max-width: 100%;
      }
      .moreWrapper{
        // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
        min-width: 297px;
        background-color: #fff;
        border-radius: 12px;
        padding-top: 15px;
        padding-bottom: 15px;
      }
      .moreOptions {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
          margin-bottom: 0px;
          a, .moreItemNoRedirect {
            cursor: pointer;
            color: var(--textColor);
            text-decoration: none;
            font-size: 14px;
            display: block;
            padding: 10px 24px;
            font-weight: 400;
            transition: 0.3s ease;
            @media (max-width: 1185px) {
              padding: 8px 0px;
            }
            &:hover{
              background-color: var(--lightBlue);
              color: var(--primaryBlue);
              font-weight: 500;
              @media (max-width: 1185px) {
                background-color: transparent;
              }
            }
          }
        }
      }
    }
    &:hover {
      .moreContainer {
        display: block;
      }
    }
  }
}

.mainNav{
  &.french{
    .mainNavitem{
      padding: 0 12px;
      @media (min-width: 1186px) and (max-width: 1310px) {
        padding: 0 7px;
      }
      @media (max-width: 1185px) {
        padding: 13px 15px;
      }
    }
    .mainNavitemLink{
      font-size: 13px;
      @media (min-width: 1186px) and (max-width: 1310px) {
        font-size: 12px;
      }
      @media (max-width: 1185px) {
        font-size: 16px;
      }
    }
  }
  &.portugal{
    .mainNavitem{
      padding: 0 8px;
      @media (min-width: 1186px) and (max-width: 1310px) {
        padding: 0 7px;
      }
      @media (max-width: 1185px) {
        padding: 13px 15px;
      }
    }
    .mainNavitemLink{
      font-size: 13px;
      @media (min-width: 1186px) and (max-width: 1310px) {
        font-size: 12px;
      }
      @media (max-width: 1185px) {
        font-size: 16px;
      }
    }
  }
}