.shalina-primaryBtn{
    button{
        height: 48px;
        background-color: var(--primaryBlue);
        border-color: var(--primaryBlue);
        color: #fff;
        border-radius: 100px;
        font-weight: 600;
        text-transform: capitalize;
        font-size: 16px;
        @media (max-width: 640px) {
            height: 40px;
            font-size: 15px;
        }
        &:hover{
            background-color: var(--btnHover);
            border-color: var(--btnHover);
        }
        &:disabled{
            background-color: var(--neutral2);
            border-color: var(--neutral2);
            color: #fff;
        }
    }
}

.downloadLoader {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }