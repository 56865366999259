.homePage {
  margin-top: 24px;
  @media (max-width: 640px) {
    margin-top: 0;
  }
}
.homeRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
  .homeCol8 {
    flex: 0 0 75.3%;
    max-width: 75.3%;
    padding: 0 8px;
    @media (max-width: 640px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .homeCol4 {
    flex: 0 0 24.7%;
    max-width: 24.7%;
    padding: 0 8px;
    @media (max-width: 640px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.homeSortWrapper {
  margin-bottom: 40px;
  @media (max-width: 640px) {
    margin: 0 -15px 25px;
  }
}
.homeSort{
  background-color: #fff;
  padding: 20px;
  border-radius: 100px;
  box-shadow: 0px 4px 15px 0px #00000026;
  // margin-bottom: 40px;
  @media (max-width: 640px) {
    // margin: 0 -15px 25px;
    border-radius: 0;
    padding: 20px 0px 20px 15px;
  }
}
.homeQuicklink{
  position: sticky;
  top: 16px;
  z-index: 2;
  margin-bottom: 16px;
}
.homeSliderSec{
  margin-bottom: 20px;
  border-bottom: 1px solid #BAC8D5;
  padding-bottom: 10px;
  &.noBorder{
    margin-bottom: 0;
    padding-bottom: 0;
    border: 0;
  }
}

/* Ebesco added for testing  */
.ebescoModal {
  :global {
    .MuiPaper-root {
      //   width: 510px;
      max-width: 100%;
      //   border-radius: 12px;
    }
    .MuiDialogContent-root {
      padding: 0px;
    }
  }
  .closeDiv {
    text-align: right;
    padding: 16px;
    span {
      cursor: pointer;
      font-size: 30px;
    }
  }
}
.iframe {
  width: 100%;
  height: 90vh;
}
/* Ebesco added for testing  */

.loaderDiv {
  padding: 0 30px;
}
