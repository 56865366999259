.communityPage {
  padding-bottom: 30px;

  .pageTitle {
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    color: #fff;
    margin-bottom: 0;
    padding-top: 0;

    @media (max-width: 640px) {
      font-size: 18px;
      line-height: 27px;
      padding-top: 0;
    }
  }

  .listingTop {
    padding: 24px 0;
    background-color: var(--primaryBlue);

    @media (max-width: 640px) {
      padding: 20px 0;
    }

    .landingFilter {
      @media (max-width: 640px) {
        margin-right: -15px;
      }
    }
  }
}

.listingRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
  padding-top: 36px;

  @media (max-width: 640px) {
    padding-top: 0px;
    margin: 0 -15px;
  }

  .listingCol6 {
    flex: 1 0 0;
    padding: 0 8px;
    max-width: calc(100% - 550px);

    @media (max-width: 640px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 15px;
    }
  }

  .listingCol3 {
    flex: 0 0 275px;
    max-width: 275px;
    padding: 0 8px;

    @media (max-width: 640px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 15px;
    }
  }
}

.tabWrapper {
  background-color: #fff;
  width: 100%;
  padding: 14px 0px 5px;
  border-radius: 16px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 15px 0px #00000026;

  @media (max-width: 640px) {
    width: 100%;
    padding: 0;
  }
}

.tabCol {

  // flex: 0 0 286px;
  // max-width: 286px;
  :global {
    .MuiTabs-root {
      overflow: visible;
    }

    .MuiTabs-indicator {
      display: none;
    }

    .MuiTabs-scroller {
      overflow: visible !important;
    }

    .MuiTabs-flexContainer {
      flex-wrap: wrap;

      @media (max-width: 640px) {
        flex-wrap: nowrap;
        overflow-x: scroll;
        margin: 0 -15px;
        background-color: #fff;
        padding-top: 15px;
        padding-left: 4px;
        box-shadow: 0 2px 4px #ddd;
      }
    }

    .MuiTab-labelIcon {
      display: flex;
      align-items: center;
      min-height: auto;
      height: 47px;
      margin-bottom: 5px;
      font-family: inherit;
      width: 100%;
      text-align: left;
      padding: 10px 22px;
      background-color: transparent;
      font-size: 14px;
      font-weight: 400;
      transition: 0.3s ease;
      flex-direction: row;
      justify-content: flex-start;
      opacity: 1;
      text-transform: capitalize;
      color: var(--textColor);

      @media (max-width: 640px) {
        &::after {
          content: "";
          position: absolute;
          width: 48px;
          height: 3px;
          background-color: transparent;
          border-radius: 3px;
          bottom: 0;
          left: calc(50% - 24px);
        }
      }

      &.Mui-selected {
        background-color: #E6F0FA99;
        color: var(--primaryBlue);
        font-weight: 600;
        @media (max-width: 640px) {
          background-color: transparent;
  
          &::after {
            background-color: var(--secondaryOrange);
          }
        }
      }

      &:hover {
        background-color: #e6f0fa99;

        @media (max-width: 640px) {
          background-color: transparent;
        }
      }

      @media (max-width: 640px) {
        width: auto;
        white-space: nowrap;
        padding: 15px 12px;
        margin: 0;
        font-size: 16px;
        position: relative;
        transition: none;
      }
    }
  }




  .tabicon {
    width: 32px;
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
    justify-content: center;
    padding-right: 10px;

    // svg path{fill: var(--secondaryOrange);}
    @media (max-width: 640px) {
      display: none;
    }
  }
}

.buildNetworkBox {
  height: 156px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1490196078);
  padding: 14px 41px;
  margin-bottom: 16px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  text-align: center;
  font-size: 15px;
  line-height: 20px;
}

.communityBody {
  :global {
    .MuiBox-root {
      padding: 0;
    }

    .MuiTypography-root {
      font-family: var(--primaryFont);
    }
  }
}

.joinModal {
  :global {
    .MuiDialogContent-root {
      padding: 0;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }

    .MuiPaper-root {
      width: 706px;
      max-width: 100%;
      border-radius: 12px;
      max-height: calc(100% - 30px);
      margin: 0 16px;
    }
  }
}

.myCommunities{
  @media (max-width: 640px) {
    display: none;
    margin: 20px 0 16px;
  }
  &.mobile{
    display: none;
    @media (max-width: 640px) {
      display: block;
    }
  }
}