.signInPage {
  position: relative;
  .authBg {
    position: absolute;
    right: 0;
    z-index: -1;
    top: 0;
  }
}
.signUplogo {
  padding-top: 20px;
  cursor: pointer;
  .logo {
    height: 50px;
    width: auto;
    @media (max-width: 640px) {
      height: 40px;
    }
  }
}
.signInWrapper {
  width: 440px;
  max-width: calc(100% - 30px);
  margin: 30px auto;
  padding: 30px 40px;
  box-shadow: 0px 4px 15px 0px #00000026;
  border-radius: 12px;
  background-color: #fff;
  @media (max-width: 640px) {
    padding: 20px 15px;
  }
  .title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    margin-bottom: 24px;
  }
  .signInFormCol {
    margin-bottom: 20px;
  }
  .divider {
    padding-top: 16px;
    padding-bottom: 14px;
  }
  .toggleLogin {
    text-align: center;
    span {
      margin-right: 4px;
    }
  }
  .helpLink {
    margin-top: 20px;
  }
}

.loaderWrapper {
  height: 100%;
  width: 100%;
  background-color: #ffffffb5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1400;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  padding: 16px;
  text-align: center;
}

.modalWrap {
  position: relative;
  // padding-top: 20px;
  // text-align: center;
  .title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .closeCta {
    background-color: transparent;
    font-size: 30px;
    position: absolute;
    top: -5px;
    right: 0;
    height: 30px;
    width: 30px;
    line-height: 30px;
    padding: 0;
  }
  span {
    color: var(--inputError);
  }
  .modalCtaWrap {
    margin-top: 16px;
    button {
      background-color: var(--primaryBlue);
      height: 40px;
      width: 100%;
      color: #fff;
      font-weight: 600;
      font-size: 16px;
      border-radius: 100px;
    }
  }
}
