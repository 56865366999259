.cardWrapper {
  padding: 20px 24px;
  box-shadow: 0px 4px 15px 0px #00000026;
  margin-bottom: 16px;
  border-radius: 16px;
  background-color: #fff;
}

.cardTop {
  margin: 0 -24px 10px;
  padding-left: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .titleAndIntrest {
    display: flex;
    flex: 1 0 0;
    padding-right: 16px;
    .cardIconWrapper {
      flex: 0 0 43px;
      max-width: 43px;
      height: 43px;
      .cardIcon {
        max-width: 43px;
        height: 43px;
        width: 43px;
        object-fit: cover;
        border-radius: 50%;
        border: 4px solid var(--lightBlue);
      }
    }

    .detail {
      flex: 1 0;
      max-width: calc(100% - 43px);
      padding-left: 10px;

      .title {
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 10px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .dateAndLabel {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 18px;

    .postDate {
      color: var(--neutral2);
      margin-right: 16px;

      strong {
        margin-right: 4px;
        color: var(--primaryBlue);
        font-weight: 500;
      }
    }

    .cardLabel {
      background-color: var(--lightOrange);
      padding: 3px 16px;
      color: var(--primaryBlue);
      border-radius: 16px 0 0 16px;
    }
  }
}
.descTitle {
  font-weight: 600;
  margin-bottom: 6px;
  font-size: 15px;
}
.cardDesc {
  display: flex;
  justify-content: space-between;
  // margin-bottom: 30px;

  .descText {
    font-size: 15px;
    line-height: 22px;
    flex: 1 0 0;
    padding-right: 24px;

    .moreLessText {
      font-weight: 500;
      cursor: pointer;
      margin-left: 4px;
      color: var(--primaryBlue);
      white-space: nowrap;
    }
  }

  .cardImg {
    flex: 0 0 68px;
    max-width: 68px;

    img {
      width: 100%;
      height: 68px;
      object-fit: cover;
      border-radius: 10px;
      display: block;
    }
  }
}
.dateWrapper {
  margin-top: 16px;
  .date {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 12px;
    svg {
      margin-right: 6px;
    }
    strong {
      font-weight: 500;
      margin-right: 4px;
    }
    span {
      color: var(--neutral2);
      margin-right: 10px;
    }
  }
}

.enterPostWrapper {
  display: flex;
  align-items: flex-start;
  margin-top: 16px;

  .userImg {
    max-width: 40px;
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid var(--lightBlue);
  }

  .postInput {
    flex: 1 0;
    margin-left: 10px;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid rgba(128, 128, 128, 0.3019607843);
    border-radius: 6px;
    position: relative;

    .richTextEditor {
      border: 0 !important;
      flex: 1;
      font-family: var(--primaryFont);
      &>div {
        border-bottom: 0 !important;
      }
    }
    .tagList{
      position: absolute;
      box-shadow: 0px 4px 15px 0px #00000026;
      border-radius: 16px;
      padding: 20px;
      width: 362px;
      max-width: 100%;
      top: calc(100% + 1px);
      left: 6px;
      z-index: 99;
      background-color: #fff;
      .taglistWrapper{
        overflow-x: auto;
        height: 149px;
        &::-webkit-scrollbar {
          width: 6px;
        }
    
        &::-webkit-scrollbar-track {
          box-shadow: none;
        }
    
        &::-webkit-scrollbar-thumb {
          background: var(--neutral2);
          border-radius: 10px;
        }
    
        &::-webkit-scrollbar-thumb:hover {
          background: var(--textColor);
        }
        .tagItem{
          display: flex;
          align-items: center;
          margin-bottom: 14px;
          &:last-child{
            margin-bottom: 0;
          }
          img{
            max-width: 40px;
            height: 40px;
            width: 40px;
            object-fit: cover;
            border-radius: 50%;
            border: 3px solid var(--lightBlue);
            margin-right: 10px;
          }
          span{
            font-size: 15px;
            font-weight: 500;
            line-height: 22px;
          }
        }
      }
    }
    .textAreaWrap{
      flex: 1;
      font-family: var(--primaryFont);
      position: relative;
      font-size: 14px;
      padding: 8px 4px 0 14px;
      letter-spacing: 0.266px !important;
      .textBoxref{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding: 8px 4px 0 14px;
        font-size: 14px;
        .placeholder{
          color: var(--neutral2);
        }
        &.hide {
          display: none;
        }
      }
    }
    textarea {
      min-height: 40px;
      border: 0;
      /* border-radius: 6px; */
      background-color: transparent;
      /* width: 100%; */
      padding: 0;
      outline: none;
      font-size: 14px;
      font-family: var(--primaryFont);
      resize: none;
      width: 100%;
      position: relative;
      z-index: 99;
      // opacity: 0;
      letter-spacing: 0.4px !important;
      &.hide {
        display: none;
      }
    }


    .submitCta {
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      background-color: transparent;
      position: relative;
      &.disabled {
        opacity: 0.4;
        cursor: default;
      }
      .buttonLoader {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff91;
      }
    }
  }
}

.commentList {
  margin-top: 24px;
}

.viewAllComment {
  text-align: center;
  border-top: 1px solid #e0e0e0;
  padding-top: 10px;
  margin-top: 20px;

  button {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    padding: 4px 4px;
    background-color: transparent;
    color: var(--primaryBlue);
  }
}

.viewAllModal {
  :global {
    .MuiDialogContent-root {
      padding: 0;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }

    .MuiPaper-root {
      width: 706px;
      max-width: 100%;
      border-radius: 12px;
    }
  }

  .head {
    background-color: var(--primaryBlue);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;

    .title {
      display: flex;
      align-items: center;
      flex: 1;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      .exploreIconWrapper {
        flex: 0 0 43px;
        max-width: 43px;
        height: 43px;
        border-radius: 50%;
        border: 4px solid var(--lightBlue);
        background-color: #fff;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        .exploreIcon {
          max-width: 30px;
          height: 30px;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .popupTitle {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        padding-right: 8px;
      }
    }

    .closeCTA {
      font-size: 25px;
      cursor: pointer;
      font-weight: 400;
    }
  }

  .viewAllTop {
    padding: 20px 0 0;
    margin-left: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    // max-height: 300px;
    // overflow-x: auto;

    &::-webkit-scrollbar {
      width: 4px;
      height: auto;
    }

    &::-webkit-scrollbar-track {
      box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--neutral2);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--textColor);
    }

    .topDetails {
      padding-right: 15px;
    }
    .topLabel {
      background-color: var(--lightOrange);
      padding: 3px 16px;
      color: var(--primaryBlue);
      border-radius: 16px 0 0 16px;
      white-space: nowrap;
    }
  }
  .descriptionDiv {
    padding: 0 16px;
    margin-top: 8px;
    .title {
      font-size: 15px;
      line-height: 22px;
      margin-bottom: 15px;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;

      strong {
        font-weight: 500;
        cursor: pointer;
        margin-left: 4px;
        color: var(--primaryBlue);
      }
    }
    .date {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      svg {
        margin-right: 6px;
      }
      strong {
        font-weight: 500;
        margin-right: 4px;
      }
      span {
        color: var(--neutral2);
        margin-right: 8px;
      }
    }
  }
  .divider {
    border-top: 1px solid #e0e0e0;
    margin: 0 16px 0px;
  }
  .postInput {
    margin-bottom: 16px;
  }
  .viewAllBody {
    padding: 0px 10px 0px 16px;
    margin-right: 6px;
    overflow: auto;
    max-height: 400px;
    margin-bottom: 30px;
    margin-top: 20px;

    :global {
      .infinite-scroll-component {
        overflow: unset !important;
      }
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: auto;
    }

    &::-webkit-scrollbar-track {
      box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--neutral2);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--textColor);
    }

    .bodyTitle {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 16px;
    }
  }
}

.tagLoader {
  height: 149px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mention {
  color: var(--primaryBlue);
  font-weight: 600;
}

.mentionInput {
  width: '100%';
  height: '105px';
}

.customMention {
  position: relative;
  z-index: 1;
  color: #002E5B;
  font-weight: 600 !important;
}

.mentionWrap {
  font-family: var(--primaryFont) !important;
}

.textAreaWrap {
  :global {
    .mentionWrap {
      .mentionWrap__highlighter {
        display: none !important;
        .mentionWrap__highlighter__substring {
          visibility: visible !important;
          color: var(--textColor);
        }
      }
      &.previewOn {
        .mentionWrap__highlighter {
          display: block !important;
        }
        .mentionWrap__input {
          display: none !important;
        }
      }
    }
  }
}

.postInput {
  :global {
    .demo-wrapper {
      height: auto;
      width: 100%;
      padding: 0 16px;
      .rdw-mention-link{
        color: var(--primaryBlue);
        background-color: transparent;
        font-weight: 600;
      }
      .rdw-suggestion-dropdown {
        min-width: 250px;
        cursor: pointer;
        bottom: unset !important;
      }
      .rdw-editor-main {
        overflow: visible;
      }
      .public-DraftEditorPlaceholder-root {
        height: 100%;
        width: 100%;
        // padding: 16px 0;
      }
    }
  }
}