/* Take Survey start */
.takeSurvey {
  // margin-bottom: 40px;
  background-color: var(--primaryBlue);
  padding: 25px 20px 25px;
  border-radius: 16px;
  position: relative;
  @media (max-width: 640px) {
    padding: 20px 15px;
    margin: 0 -15px;
    border-radius: 0;
  }
  .surveyBg{
    position: absolute;
    top: 0;
    left: 0;
  }
  .homeSlider{
    padding: 0 8px;
  }
  .surveyContent {
    padding: 10px 20px 10px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    position: relative;
    z-index: 1;
    @media (max-width: 640px) {
      padding: 15px;
    }
    .card{
      flex: 1 1;
      max-width: 100%;
      padding-top: 14px;
      @media (max-width: 640px) {
        flex: 0 0 calc(100% - 150px);
        max-width: calc(100% - 150px);
        padding-top: 0px;
      }
    }
    .surveyImg{
      flex: 0 0 256px;
      max-width: 256px;
      height: 256px;
      @media (max-width: 640px) {
        flex: 0 0 150px;
        max-width: 150px;
        height: 150px;
      }
      img{
        max-width: 100%;
        height: 256px;
        object-fit: fill;
      }
    }
    .title {
      font-size: 22px;
      font-weight: 500;
      line-height: 32px;
      color: #242121;
      margin-bottom: 26px;
      width: 510px;
      min-height: 64px;
      max-width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      &:hover {
        color: var(--primaryBlue);
        cursor: pointer;
      }
      @media (max-width: 640px) {
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 16px;
        min-height: unset;
      }
    }
    .dateWrapper{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .date {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      color: var(--neutral2);
      font-size: 14px;
      margin-right: 18px;
      @media (max-width: 640px) {
        font-size: 12px;
        margin-bottom: 0px;
        margin-right: 0;
      }
      &.endDate{
        margin-right: 0;
        @media (max-width: 640px) {
          margin-bottom: 16px;
          margin-top: 5px;
        }
      }
      svg {
        margin-right: 6px;
      }
      span{
        color: var(--textColor);
        font-weight: 500;
        margin-right: 4px;
      }
      
    }
    .postedBy {
      display: flex;
      align-items: center;
      color: var(--neutral2);
      svg {
        margin-right: 6px;
        flex: 0 0 22px;
      }
      span {
        color: var(--neutral2);
        max-width: calc(100% - 30px);
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        strong{
          color: var(--primaryBlue);
          font-weight: 500;
          margin-right: 4px;
        }
        @media (max-width: 640px) {
          font-size: 12px;
        }
      }
    }
    .cta {
      background-color: var(--primaryBlue);
      color: #fff;
      height: 32px;
      padding: 0 14px;
      font-size: 14px;
      font-weight: 600;
      border-radius: 100px;
      display: inline-block;
      line-height: 32px;
      text-align: center;
      text-decoration: none;
      margin-top: 30px;
      transition: 0.3s ease;
      cursor: pointer;
      @media (max-width: 640px) {
        margin-right: 0px;
        padding: 0px 10px;
        height: 30px;
        font-size: 12px;
        line-height: 30px;
        margin-top: 20px;
      }
      &:hover{
        background-color: var(--btnHover);
      }
      &:disabled{
        background-color: var(--neutral2);
        cursor: default;
      }
    }
  }
}
/* Take Survey end */
