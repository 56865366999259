.notification {
  margin-left: 31px;
  position: relative;
  height: 70px;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (max-width: 1185px) {
    margin-left: 20px;
    height: auto;
  }
  &.active::after{
    content: "";
    height: 3px;
    position: absolute;
    background: var(--secondaryOrange);
    width: 100%;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    @media (max-width: 1185px) {
      background: transparent;
    }
  }
  img {
    display: block;
  }
  span {
    height: 14px;
    width: 14px;
    position: absolute;
    background-color: var(--secondaryOrange);
    font-size: 9px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    top: calc(50% - 14px);
    right: -7px;
  }
}

/* Notification start */
.notificationPage {
  min-height: 70vh;
  padding-top: 36px;
  padding-bottom: 30px;
  @media (max-width: 640px) {
    padding: 20px 0 0;
  }
  .pageHead {
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    margin-bottom: 16px;
    color: var(--primaryBlue);
    @media (max-width: 640px) {
      font-size: 18px;
      line-height: 27px;
    }
  }
}
.notificationWrapper {
  background-color: #fff;
  padding: 25px;
  width: 990px;
  max-width: 100%;
  border-radius: 16px;
  box-shadow: 0px 4px 15px 0px #00000026;
  &.listWrapper{
    background-color: #fff;
    padding: 40px 24px;
    box-shadow: 0px 4px 15px 0px #00000026;
    @media (max-width: 640px) {
      padding: 20px 16px;
      box-shadow: none;
      margin: 0 -15px;
      max-width: calc(100% + 30px);
      border-radius: 0;
    }
  }
  :global{
    .infinite-scroll-component {
        overflow: unset !important;
    }
}
}
.notifSec {
  // margin-bottom: 0px;
  // margin-top: 6px;
  // display: inline-block;
  // width: 100%;
  .notifsecTitle {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    margin-bottom: 16px;
  }
}
.notificationList {
  // display: inline-block;
  // width: 100%;
  .notificationCard {
    padding: 24px 24px;
    margin-bottom: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0px 4px 15px 0px #00000026;
    border-radius: 16px;
    &.read{
      box-shadow: none;
      background-color: #E6F0FA;
    }
    .notificationContent {
      max-width: calc(100% - 70px);
      width: 780px;
      cursor: pointer;
      h4 {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 8px;
        color: #000;
      }
    }
    p {
      line-height: 21px;
      margin-bottom: 10px;
    }
    .notificationTime {
      color: var(--neutral2);
      font-size: 14px;
    }
    &:last-child {
      margin-bottom: 24px;
    }
  }
}

.notfound{
  .notFoundImg{
    display: block;
    margin: 30px auto 24px;
  }
  .notFoundContent{
    width: 500px;
    max-width: 100%;
    margin: 0 auto 20px;
    text-align: center;
    h4{
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      color: var(--primaryBlue);
      margin-bottom: 8px;
    }
    p{
      font-size: 16px;
      line-height: 24px;
      color: var(--neutral2);
      margin: 0 auto 45px;
      width: 440px;
      max-width: 100%;
    }
    .backToHome{
      width: 280px;
      max-width: 100%;
      margin: 0 auto;
    }
  }
}
/* Notification end */

.initialHeight {
  min-height: 500px;
}
