.detailTop {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
}

.detailImg {
    flex: 0 0 36%;
    max-width: 36%;

    img {
        width: 100%;
        border-radius: 8px;
        height: 194px;
        object-fit: cover;
    }
}

.detailInfo {
    flex: 0 0 64%;
    max-width: 64%;
    padding-left: 30px;

    .tag {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 12px;

        svg,
        img {
            margin-right: 8px;
            height: 14px;
            width: auto;
        }
    }

    .detailTitle {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        margin-bottom: 24px;
        min-height: 120px;
    }
}

.dateViews {
    margin-top: 20px;

    span {
        display: inline-flex;
        align-items: center;
        margin-right: 28px;
        color: var(--neutral2);

        strong {
            color: var(--textColor);
            font-weight: 500;
            margin-right: 4px;
        }

        svg {
            margin-right: 8px;
        }
    }
}

.shareAndViews {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
    margin-top: 26px;

    .shareWrap {
        display: flex;
        align-items: center;
        margin: 0 -8px;

        button {
            height: 40px;
            width: 40px;
            border-radius: 6px;
            background-color: #fff;
            margin: 0 8px;
        }
    }
}

.articalContent {
    font-size: 16px;
    line-height: 24px;
    position: relative;

    &.guestDesc {
        min-height: 140px;
    }

    p {
        margin-bottom: 10px;
        color: var(--textColor);
    }

    .guestDesc {
        // background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.9), #fff);
        background: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.9), #fff);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 97px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 0px;
        border-radius: 16px;

        button {
            width: 343px;
            max-width: 100%;
            height: 45px;
            border-radius: 100px;
            background-color: var(--primaryBlue);
            color: #fff;
            font-size: 16px;
            font-weight: 600;
            font-family: var(--primaryFont);
        }
    }
}

// style={{ width: 400, height: 200, margin: 10, padding: 10 }}
/* rss css start */
.rssContainer {
    width: 100%;
    padding: 20px;
    display: flex;
    box-shadow: none;
    border-radius: 16px;
    cursor: pointer;
    border: 1px solid #E5E5E5;
    margin-top: 16px;

    .cardImg {
        flex: 0 0 130px;
        max-width: 130px;
        height: 130px;
        border-radius: 12px;
        object-fit: cover;
    }

    .cardDetails {
        flex: 1;
        padding: 10px 10px 10px 30px;

        h2 {
            font-size: 22px;
            line-height: 30px;
            font-weight: 600;
            margin-bottom: 12px;
        }
    }
}