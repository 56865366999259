/* home profile start */
.homeProfile {
  margin-bottom: 16px;
  padding: 18px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 15px 0px #00000026;
}
.profileInfo {
  position: relative;
  .profilePoint {
    position: absolute;
    display: flex;
    align-items: center;
    color: var(--primaryBlue);
    right: 0;
    top: 0;
    font-size: 11px;
    font-weight: 500;
    padding: 4px 8px 4px 4px;
    border: 1px solid var(--primaryBlue);
    border-radius: 18px;
    img {
      margin-right: 5px;
      border-radius: 50%;
      height: 16px;
      width: 16px;
    }
  }
  .profileImg {
    position: relative;
    margin-bottom: 16px;
    display: inline-block;
    img {
      height: 64px;
      width: 64px;
      object-fit: cover;
      border-radius: 50%;
    }
    span {
      position: absolute;
      height: 24px;
      width: 24px;
      border: 2px solid #fff;
      line-height: 21px;
      text-align: center;
      border-radius: 50%;
      background-color: var(--primaryBlue);
      bottom: 2px;
      right: -3px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        height: 10px;
        width: auto;
      }
    }
  }
  .userName {
    font-size: 20px;
    font-weight: 600;
  }
  .userRole {
    font-size: 14px;
    color: var(--neutral2);
    margin-top: 12px;
    margin-bottom: 21px;
  }
  .unlockProgess {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #8080804d;
    padding-top: 14px;
    cursor: pointer;
    p {
      max-width: 190px;
      color: var(--primaryBlue);
      line-height: 21px;
      font-size: 14px;
      font-weight: 600;
    }
    .arrow {
      display: inline-flex;
      height: 24px;
      width: 24px;
      background-color: var(--primaryBlue);
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
.profileProgress {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    height: 38px;
    width: 38px;
    position: relative;
    z-index: 1;
    border-radius: 50%;
    border: 4px solid #fceae4;
    &.active {
      border-color: var(--secondaryOrange);
    }
  }
  &::before {
    content: "";
    position: absolute;
    border-top: 1px dashed #cfcfcf;
    width: 100%;
    left: 0;
    top: calc(50% - 0.5px);
  }
  .progressBarWrap {
    position: absolute;
    height: 4px;
    left: 38px;
    max-width: calc(100% - 76px);
    width: 100%;
    background-color: #fceae4;
    .pointDots {
      position: absolute;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      span {
        height: 4px;
        width: 4px;
        background-color: var(--secondaryOrange);
        border-radius: 50%;
      }
    }
  }
  .progressBar {
    position: absolute;
    background-color: var(--secondaryOrange);
    left: 0px;
    top: 0px;
    height: 100%;
    display: block;
  }
}
.totalPoint {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 25px;
  div {
    color: var(--neutral2);
    font-size: 11px;
    strong {
      font-weight: 600;
      color: var(--primaryBlue);
      font-size: 12px;
    }
    span {
      font-weight: 500;
      color: var(--textColor);
      font-size: 12px;
    }
  }
}
/* home profile start */

/* profile completion start */
.profileCompletion {
  margin-bottom: 16px;
  padding: 18px;
  border-radius: 16px;
  box-shadow: 0px 4px 15px 0px #00000026;
  background-color: #fff;
  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    margin-bottom: 20px;
  }
  .profileProgressBar {
    background-color: #e6f0fa;
    border-radius: 6px;
    height: 4px;
    position: relative;
    margin-bottom: 14px;
    span {
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 6px;
      background-color: var(--secondaryOrange);
    }
  }
  .profileProgressNumber {
    text-align: right;
    color: var(--primaryBlue);
    margin-bottom: 6px;
    font-size: 12px;
    font-weight: 600;
  }
  .profileProgressText {
    color: #808080;
    margin-bottom: 24px;
    font-size: 14px;
  }
  .profileCompleteCta {
    text-align: center;
    text-decoration: none;
    color: #fff;
    height: 35px;
    line-height: 35px;
    padding: 0px 10px;
    background-color: var(--primaryBlue);
    display: block;
    font-size: 14px;
    font-weight: 600;
    border-radius: 100px;
    cursor: pointer;
    transition: 0.2s ease;
    &:hover {
      background-color: var(--btnHover);
    }
  }
}
/* profile completion end */

/* edit profile image modal start */
.editImgModal {
  :global {
    .MuiPaper-root {
      width: 612px;
      max-width: 100%;
      border-radius: 12px;
    }
    .MuiDialogContent-root {
      padding: 24px;
      color: var(--textColor);
    }
  }
  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    margin-bottom: 27px;
  }
  .imgCta {
    display: flex;
    align-items: center;
    justify-content: center;
    .profileImg {
      flex: 0 0 225px;
      max-width: 225px;
      img {
        height: 225px;
        width: 225px;
        object-fit: cover;
        border-radius: 50%;
        border: 8px solid #e6f0fa;
      }
    }
    .ctas {
      padding-left: 40px;
      flex: 0 0 254px;
      max-width: 254px;
      .uploadCta {
        display: block;
        height: 40px;
        width: 100%;
        background-color: transparent;
        border: 1px solid var(--primaryBlue);
        color: var(--primaryBlue);
        border-radius: 6px;
        margin-bottom: 18px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        transition: 0.3s ease;
        &:hover {
          border: 1px solid var(--btnHover);
        }
        input[type="file"] {
          display: none;
        }
      }
      button {
        height: 40px;
        width: 100%;
        background-color: transparent;
        border: 1px solid var(--primaryBlue);
        color: var(--primaryBlue);
        border-radius: 6px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        transition: 0.3s ease;
        &:hover {
          border: 1px solid var(--btnHover);
        }
      }
    }
  }
  .actionCtas {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    button {
      border: 1px solid var(--primaryBlue);
      background-color: transparent;
      width: 165px;
      height: 45px;
      border-radius: 100px;
      font-size: 16px;
      font-weight: 600;
      color: var(--primaryBlue);
      margin: 0 6px;
      &.save {
        background-color: var(--primaryBlue);
        color: #fff;
      }
    }
  }
}
/* edit profile image modal end */

