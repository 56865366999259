.shalinaLink{
    text-decoration: none;
    // font-size: 14px;
    color: var(--primaryBlue);
    cursor: pointer;
    font-weight: 600;
}

.shalinaLinkDisable {
    text-decoration: none;
    color: var(--neutral2);
    // cursor: pointer;
    // font-weight: 600;
    pointer-events: none;
}