/* Explore Communitites start */
.exploreSec {
  // margin-bottom: 36px;
  // background-color: #f5f5f7;
  // border-radius: 16px;
  // padding: 20px 18px 12px;
}
.exploreCardWrap {
  padding: 9px 8px 12px;
}
.exploreCard {
  padding: 16px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 4px 10px 0px #00000026;
  .exploreCardImgWrapper{
    flex: 0 0 43px;
    max-width: 43px;
    height: 43px;
    border-radius: 50%;
    border: 4px solid var(--lightBlue);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }
  .exploreCardImg {
    max-width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
  }
  .experCardTitle {
    min-height: 61px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    h4 {
      min-height: 40px;
      font-weight: 500;
      line-height: 20px;
      font-size: 16px;
      margin-bottom: 5px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      cursor: pointer;
    }
    p {
      margin-bottom: 0px;
      font-size: 12px;
      line-height: 15px;
    }
    .speciality {
      display: flex;
      align-items: center;
      min-height: 18px;
      position: relative;
      :global{
        .globalspecialityWrapper {
          width: 100%;
        }
      }
      .specialityItem{
        padding-left: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 70%;
      }
    }
  }
  .members {
    margin-bottom: 15px;
    margin-top: 10px;
    color: var(--neutral2);
    font-size: 12px;
    display: flex;
    align-items: center;
    .memberImages {
      display: flex;
      padding-right: 13px;
      img {
        border-radius: inherit;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  .experCardCta {
    display: inline-block;
    color: var(--primaryBlue);
    font-size: 14px;
    font-weight: 600;
    line-height: 29px;
    height: 30px;
    border-radius: 100px;
    text-decoration: none;
    text-align: center;
    padding: 0px 10px;
    min-width: 132px;
    max-width: 100%;
    border: 1px solid var(--primaryBlue);
    background-color: transparent;
    position: relative;
  }
  .moreSpeciality {
    // position: relative;
    display: inline-block;
    margin-left: 8px;
    .count {
      font-size: 12px;
      padding: 0 5px;
      font-weight: 600;
      color: var(--primaryBlue);
    }
    .specialityTooltipWrapper{
      display: none;
      position: absolute;
      font-size: 11px;
      line-height: 16px;
      padding: 0;
      border-radius: 8px;
      top: calc(100% + 10px);
      right: 0;
      width: 100%;
      /* transform: translateX(-50%); */
      z-index: 1;
      /* background-color: #f0f3f5;*/
      align-items: center;
      &::after {
        content: "";
        position: absolute;
        top: -8px;
        right: calc(50% - 7px);
        margin-top: -5px;
        border-width: 7px;
        border-style: solid;
        border-color: transparent transparent #F0F3F5 transparent;
      }
    }
    .specialityTooltip {
      max-height: 70px;
      overflow-x: hidden;
      overflow-y: auto;
      margin: 0 auto;
      background-color: #f0f3f5;
      padding: 8px 8px 4px;
      border-radius: 8px;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      &::-webkit-scrollbar-track {
        box-shadow: none;
      }
      &::-webkit-scrollbar-thumb {
        background: var(--neutral2); 
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: var(--textColor);
      }
      
      .tooltiptext {
        display: block;
        color: var(--primaryBlue);
        font-weight: 500;
        margin-bottom: 4px;
        // white-space: nowrap;
      }
    }
    &:hover {
      .specialityTooltipWrapper {
        display: flex;
      }
    }
  }
}

/* ImageWrapper.css */
.imageWrapper {
  display: inline-block;
  width: 30px;
  height: 30px;
  overflow: hidden;
  margin-right: -7px;
  border: 2px solid #fff;
  border-radius: 50%;
}

.joinModal{
  .closeCta{
      background-color: transparent;
      font-size: 30px;
      height: 30px;
      line-height: 30px;
      padding: 0;
      position: absolute;
      right: 9px;
      top: 13px;
      width: 30px;
      color: #fff;
  }
  :global {
    .MuiPaper-root {
      width: 700px;
      max-width: 100%;
      border-radius: 12px;
      margin: 16px;
    }

    .MuiDialogContent-root {
      padding: 0px;
      position: relative;
    }
  }
}
/* Explore Communitites end */
