.calcTop {
  padding-top: 0px;
  padding-bottom: 24px;
  background-color: var(--primaryBlue);
  // margin-bottom: 24px;

  @media (max-width: 640px) {
    padding-bottom: 20px;
    // margin-bottom: 20px;
  }
  &.calcDetail{
    background-color: transparent;
    padding: 0;
    @media (max-width: 640px) {
      padding: 0;
    }
  }

  .calcHead {
    color: #fff;
    font-size: 22px;
    line-height: 33px;
    font-weight: 500;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;

    @media (max-width: 640px) {
      font-size: 18px;
      line-height: 27px;
    }
  }
}

.calcTag {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0 -4px;
  max-width: 100%;
  overflow-x: auto;

  @media (max-width: 640px) {
    margin: 0 -15px;
    padding-left: 15px;
    max-width: unset;
  }

  li {
    margin: 0 4px;

    span {
      display: inline-flex;
      align-items: center;
      height: 30px;
      padding: 0px 14px;
      border: 1px solid #ffffff;
      border-radius: 100px;
      color: #fff;
      font-size: 14px;
      line-height: 21px;
      font-weight: 600;
      cursor: pointer;
      white-space: nowrap;

      &.allText {
        cursor: default;
      }
    }

    input[type="checkbox"] {
      display: none;

      &:checked + span {
        background-color: #fff;
        color: var(--primaryBlue);
      }
    }
  }

  &::-webkit-scrollbar {
    width: auto;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--neutral2);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--textColor);
  }
}

.calcRow {
  display: flex;
  flex-wrap: wrap;
  margin: 20px -8px 0;
  
  @media (max-width: 640px) {
    margin: 20px -15px 0;
  }

  .calcCol8 {
    flex: 0 0 74%;
    max-width: 74%;
    padding: 0 8px;
    @media (max-width: 640px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0;
    }
  }

  .calcCol4 {
    flex: 0 0 26%;
    max-width: 26%;
    padding: 0 8px;
    @media (max-width: 640px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 15px;
    }
  }
}

.calcQuicklink {
  position: sticky;
  top: 16px;
  z-index: 2;
  margin-bottom: 16px;
}

.shalinaTabs {
  .custTabs {
    padding: 24px 24px 0;
    box-shadow: 0px 4px 15px 0px #00000026;
    border-radius: 16px 16px 0 0;
    background-color: #fff;
    @media (max-width: 640px) {
      padding: 20px 15px 0;
      border-radius: 0;
    }
    :global {
      .MuiTab-root {
        padding: 0;
        text-transform: capitalize;
        font-family: inherit;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-right: 60px;
        color: var(--primaryBlue);
        &.Mui-selected {
          font-weight: 600;
          color: var(--primaryBlue);
        }
        &:last-child {
          margin-right: 0;
        }
        @media (max-width: 640px) {
          margin-right: 24px;
        }
      }
      .MuiTabs-indicator {
        height: 3px;
        background-color: var(--secondaryOrange);
      }
      .MuiTabs-flexContainer {
        overflow-x: auto;
      }
    }
  }
  .custTabContent {
    :global {
      .MuiTypography-root {
        font-family: var(--primaryFont);
      }
      .MuiBox-root {
        padding: 12px 24px 24px;
        @media (max-width: 640px) {
          padding: 20px 15px;
        }
      }
    }
  }
}

.calcPageWrapper {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 15px 0px #00000026;
  margin-bottom: 32px;
  min-height: 500px;
  @media (max-width: 640px) {
    border-radius: 0;
    box-shadow: none;
  }
}
.calcList {
  .calcListItem {
    cursor: pointer;
    padding: 20px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    &:last-child {
      border-bottom: 0;
    }
    .calctext {
      flex: 1;
      padding-right: 16px;
    }
    .calcicon {
      transform: rotate(-90deg);
      height: 24px;
      width: 24px;
      text-align: center;
      cursor: pointer;
    }
  }
}

.ebescoModal {
  :global {
    .MuiPaper-root {
      //   width: 510px;
      max-width: 100%;
      //   border-radius: 12px;
    }
    .MuiDialogContent-root {
      padding: 0px;
    }
  }
  .closeDiv {
    // text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    span {
      cursor: pointer;
      font-size: 30px;
    }
    .backBtn {
      margin-right: 18px;
      font-size: 14px;
      font-weight: 600;
      padding: 6px 10px;
      min-width: 150px;
      height: 32px;
      cursor: pointer;
      background-color: var(--primaryBlue);
      border-radius: 100px;
      color: #fff;
      transition: 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-right: 5px;
        transform: rotate(90deg);
        path {
          fill: #fff;
        }
      }
    }
  }
}

.iframe {
  width: 100%;
  height: calc(100vh - 70px);
  }

.loadingHeight {
  height: 500px;
}

.contentNotFound {
  width: 510px;
  max-width: 100%;
  margin: 20px auto 50px;
  text-align: center;

  .notFoundImg {
    margin-bottom: 35px;

    img {
      max-width: 100%;
    }
  }

  h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: var(--primaryBlue);
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: var(--neutral2);
  }
}


.breadWrapper{
  @media (max-width: 640px) {
      max-width: 100%;
      overflow-x: scroll;
  }
}
.breadcrumb{
  padding-top: 30px;
  padding-bottom: 12px;
  padding-left: 0;
  margin: 0;
  list-style: none;
  display: flex;
  font-size: 12px;
  color: var(--primaryBlue);
  @media (max-width: 640px) {
      padding-top: 20px;
  }
  a{
      color: var(--primaryBlue);
      text-decoration: none;
      &+{
          span{
              padding: 0 8px;
          }
      }
  }
  .bredArrow{
    padding: 0 8px;
    svg{
      position: relative;
      top: 1.5px;
      overflow: visible;
    }
  }
  .breadcrumbCurrent{
      font-weight: 600;
      color: var(--textColor);
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      white-space: break-spaces;
  }
  .breadcrumb-current{
      font-weight: 600;
  }
  li{
      margin-right: 5px;
      white-space: nowrap;
      display: flex;
      align-items: center;
  }
  &.hasDarkBg{
      a{
          color: #fff;
          &+{
              span{
                  color: #fff;
              }
          }
      }
      .breadcrumbCurrent{
          color: #fff;
      }
  }
}