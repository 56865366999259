.quizPage {
  min-height: 600px;
}
.quizWrapper {
  background-color: #fff;
  padding: 24px 24px 60px;
  border-radius: 16px;
  margin-bottom: 45px;
  width: 990px;
  max-width: 100%;
  box-shadow: 0px 4px 15px 0px #00000026;
  @media (max-width: 640px) {
    padding: 20px 15px;
    margin: 0 -15px;
    max-width: calc(100% + 30px);
    box-shadow: none;
    border-radius: 0;
  }
}
.quizNav {
  display: flex;
  margin: 0 -12px 30px;
  @media (max-width: 640px) {
    margin-bottom: 20px;
  }
  span {
    height: 6px;
    border-radius: 16px;
    background-color: var(--lightOrange);
    flex: auto;
    margin: 0 12px;
    @media (max-width: 640px) {
      height: 4px;
    }
    &.active {
      background-color: var(--secondaryOrange);
    }
  }
}
.quizTitle {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 30px;
  @media (max-width: 640px) {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 20px;
  }
}
.quizList {
  .quizItem {
    margin-bottom: 30px;
    @media (max-width: 640px) {
      margin-bottom: 20px;
    }
  }
  .question {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 15px;
    padding-left: 20px;
    .questionNo {
      position: absolute;
      top: 0;
      left: 0;
    }
    .astry {
      color: var(--errorColor);
      margin-left: 4px;
    }
  }
  .ansOptions {
    padding-left: 20px;
    :global {
      .MuiFormControl-root {
        max-width: 100%;
      }
      .MuiInputBase-root {
        width: 400px;
        max-width: 100%;
      }
      .MuiSelect-select {
        padding: 12px 32px 12px 10px;
        font-size: 14px;
      }
      .MuiButtonBase-root {
        &.Mui-checked {
          color: var(--secondaryOrange);
        }
        &:hover {
          background-color: transparent;
        }
      }
      .MuiFormHelperText-root.Mui-error {
        color: var(--inputError);
      }
    }
  }
}
.placeHolderText {
  color: #bfbcbc; // Placeholder text color
}
.ctaWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 45px -8px 0;
  @media (max-width: 640px) {
    justify-content: center;
    margin-top: 25px;
  }
  .ctaCol {
    flex: 0 0 224px;
    max-width: calc(50% - 16px);
    padding: 0 8px;
    button {
      width: 100%;
      height: 48px;
      border-radius: 100px;
      background-color: var(--primaryBlue);
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      border: 1px solid var(--primaryBlue);
      position: relative;
      &.back {
        background-color: transparent;
        color: var(--primaryBlue);
      }
      &:disabled {
        background-color: var(--neutral2);
        border-color: var(--neutral2);
        color: #fff;
        cursor: default;
      }
    }
  }
}

.ansOptions {
  :global {
    .MuiFormControlLabel-root {
      align-items: flex-start;
      margin-bottom: 18px;
      margin-left: 0;
      display: flex;
    }
    .MuiButtonBase-root {
      color: var(--secondaryOrange);
      padding: 0;
      margin-right: 9px;
      &:hover {
        background-color: transparent;
      }
    }
    .MuiFormHelperText-root.Mui-error {
      color: var(--inputError);
    }
  }
  .textArea {
    height: 100px;
    width: 100%;
    border: 1px solid #8080804d;
    border-radius: 6px;
    display: block;
    &:hover {
      border-color: rgba(0, 0, 0, 0.87);
    }
    &:focus-visible {
      border-color: rgb(25, 118, 210);
      outline: none;
    }
  }
}
.surveyTextArea {
  .textArea {
    height: 100px !important;
    font-family: var(--primaryFont);
    width: 100%;
    border: 1px solid rgba(128, 128, 128, 0.3019607843);
    border-radius: 6px;
    display: block;
    font-family: var(--primaryFont);
    font-size: 14px;
    padding: 6px 10px;
    max-width: 100%;
    &:hover {
      border-color: rgba(0, 0, 0, 0.87);
    }
    &:focus-visible {
      border-color: rgb(25, 118, 210);
      outline: none;
    }
    &::placeholder {
      color: #bfbcbc;
      font-family: var(--primaryFont);
      font-size: 14px;
    }
  }
}
:global {
  .MuiDialog-paper {
    border-radius: 16px;
  }
}
.thanksModal {
  width: 534px;
  max-width: 100%;
  padding: 35px 35px 24px;
  border-radius: 16px;
  text-align: center;
  img {
    max-width: 100%;
    margin-bottom: 12px;
  }
  .title {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    margin-bottom: 11px;
    color: var(--primaryBlue);
  }
  p {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 60px;
  }
  button {
    width: 100%;
    height: 48px;
    border-radius: 100px;
    font-size: 16px;
    font-weight: 600;
    background-color: var(--primaryBlue);
    color: #fff;
  }
}

.starRatingWrap {
  :global {
    .MuiRating-iconFilled {
      svg {
        fill: var(--secondaryOrange);
      }
    }
  }
}

.rangeWrap {
  margin-left: 12px;
  :global {
    .MuiSlider-root {
      color: var(--secondaryOrange);
    }
  }
}

.shalinaUploadCta {
  width: 400px;
  max-width: 100%;
  .fileNote {
    color: var(--neutral2);
    font-size: 13px;
    text-align: right;
    display: block;
    margin-top: 5px;
    margin-right: 5px;
  }
  p {
    margin-top: 6px;
    color: var(--errorColor);
  }
  .uploadWrapper {
    position: relative;
    border: 1px solid var(--primaryBlue);
    border-radius: 100px;
    height: 44px;
    display: flex;
    text-align: center;
    padding: 10px 10px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    color: var(--primaryBlue);
    font-size: 14px;
    font-weight: 500;

    input {
      opacity: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      cursor: pointer;
    }

    svg {
      margin-right: 10px;
    }
  }

  .uploadedImageWrapper {
    background-color: var(--lightBlue);
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px;
    font-size: 14px;
    border-radius: 6px;

    button {
      background-color: transparent;
      padding: 0;
      font-size: 24px;
      height: 16px;
      width: 16px;
      line-height: 21px;
    }
  }
}
