.pageTitle{
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    margin-bottom: 16px;
  }
.landingFilter{
    background-color: #fff;
    padding: 20px;
    border-radius: 100px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1490196078);
    margin-bottom: 25px;
    position: relative;
    .loaderDiv {
      position: absolute;
      width: calc(100% - 60px);
      left: 32px;
      bottom: -2px;
    }
}

.notFoundSec {
  padding: 16px;
  text-align: center;
  .notFoundWrapper {
    width: 384px;
    max-width: 100%;
    margin: 0 auto;
    img {
      max-width: 100%;
      max-height: 190px;
      height: auto;
      margin-bottom: 30px;
    }
    h2 {
      font-size: 24px;
      line-height: 36px;
      font-weight: 600;
      color: var(--primaryBlue);
      margin-bottom: 8px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 30px;
      color: var(--neutral2);
    }
    .cta {
      width: 343px;
      max-width: 100%;
      margin: 0 auto;
    }
  }
}

.listingWrapper {
  :global{
    .infinite-scroll-component {
      overflow: unset !important;
    }
  }
}
