.head {
  padding: 12px 35px 12px 16px;
  background-color: var(--primaryBlue);
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
}
.joinBody {
  padding: 20px 24px 30px;
  background-color: var(--lightBlue);
  overflow-y: auto;
}
.joinDetailsWrapper{
  background-color: #fff;
  margin-bottom: 30px;
  display: inline-block;
  width: 100%;
  border-radius: 16px;
}
.joinDetails {
  background-color: #fff;
  border-radius: 16px;
  margin: 10px 20px;
  max-height: 40vh;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: none;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--neutral2);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--textColor);
  }
  .joinIcon {
    display: block;
    height: 67px;
    width: auto;
    object-fit: cover;
    margin: 0 auto 10px;
  }
  .staticGuideline {
    display: block;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .detailList {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: -webkit-inline-box;
      align-items: flex-start;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 20px;
      strong {
        font-weight: 600;
      }
      svg {
        margin-right: 12px;
        height: auto;
        margin-top: 3px;
      }
    }
  }
}
.termCheck {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  .termText {
    font-size: 16px;
    line-height: 24px;
    flex: 1 0 0;
  }
  .checkError {
    color: var(--inputError);
    flex: 0 0 100%;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
  }
  :global {
    .MuiFormControlLabel-root {
      margin-right: 5px;
      margin-top: -6px;
    }
    .MuiButtonBase-root {
      &.Mui-checked {
        color: var(--secondaryOrange);
      }
    }
  }
}
.joinCta {
  margin: 20px auto 0;
  width: 343px;
  max-width: 100%;
}
