.registerFilter {
    margin-bottom: 23px;
}

.emptyEvent {
    text-align: center;

    img {
        margin: 0 auto 40px;
        width: 275px;
        max-width: 100%;
        @media (max-width: 640px) {
            margin: 20px auto 40px;
            width: 220px;
        }
    }

    .title {
        font-size: 24px;
        line-height: 36px;
        font-weight: 600;
        margin-bottom: 10px;
        color: var(--primaryBlue);
        @media (max-width: 640px) {
            font-size: 18px;
            line-height: 27px;
        }
    }

    .infoText {
        font-size: 16px;
        line-height: 24px;
        color: var(--neutral2);
        width: 488px;
        max-width: 100%;
        margin: 0 auto 32px;
        @media (max-width: 640px) {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .ctaExplore {
        width: 343px;
        max-width: 100%;
        margin: 0 auto;
    }
}

.bookmarkCards {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    :global {
        .infinite-scroll-component__outerdiv{
            width: 100%;;
        }
        .infinite-scroll-component {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -8px;
            overflow: unset !important;
        }
        .globalPaginationLoader {
            flex: 0 0 100%;
            width: 100%;
        }
    }
    .bookmarkCard {
        flex: 0 0 33.3333%;
        max-width: 50.3333%;
        padding: 0 8px;
        margin-bottom: 12px;

        @media (max-width: 640px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}