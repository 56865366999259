.whoWeAre{
    .title{
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 16px;
        @media (max-width: 640px) {
            font-size: 18px;
        }
    }
    .weAreInfo{
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: 40px;
        @media (max-width: 640px) {
            margin-bottom: 20px;
        }
        .infoText{
            flex: 0 0 100%;
            max-width: 100%;
            font-size: 16px;
            line-height: 24px;
            p{
                margin-bottom: 15px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        .weAreImg{
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 24px;
            @media (max-width: 640px) {
                margin-bottom: 10px;
            }
            img{
                display: block;
                width: 100%;
                max-height: 384px;
                object-fit: cover;
            }
        }
    }
}
.ourMission{
    margin-bottom: 24px;
    @media (max-width: 640px) {
        margin-bottom: 10px;
    }
    .infoText{
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
    }
}
.missionCardRow{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    .missionCardWrap{
        flex: 0 0 25%;
        max-width: 25%;
        padding: 0 8px;
        @media (max-width: 640px) {
            flex: 0 0 50%;
            max-width: 50%;
            margin-bottom: 16px;
        }
        .missionCard{
            box-shadow: 0px 4px 15px 0px #00000026;
            border-radius: 16px;
            padding: 16px;
            height: 100%;
            .omImg{
                height: 50px;
                width: 56px;
                margin-bottom: 16px;
            }
            .title{
                font-size: 14px;
                font-weight: 500;
                padding-bottom: 5px;
                margin-bottom: 25px;
                position: relative;
                &::after{
                    content: "";
                    position: absolute;
                    height: 1px;
                    width: 136px;
                    max-width: 100%;
                    bottom: 0;
                    left: 0;
                    background-color: #E0E0E0;
                }
            }
            .desc{
                font-size: 14px;
                line-height: 21px;
                color: var(--neutral2);
            }
        }
    }
}
.coreValues{
    .infoText{
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
    }
}
.coreValueTab{
    padding: 14px 24px 24px;
    background-color: #F5F5F7;
    border-radius: 16px;
    min-height: 240px;
    @media (max-width: 640px) {
        padding: 15px 15px 24px;
    }
}
.shalinaTabs{
    .custTabs{
        padding: 0;
        border-bottom: 1px solid #D7D7D7;
        :global{
            
            .MuiTab-root{
                padding: 0;
                text-transform: capitalize;
                font-family: inherit;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                margin-right: 60px;
                color: var(--primaryBlue);
                &.Mui-selected{
                  font-weight: 600;
                }
                &:last-child{
                    margin-right: 0;
                }
                @media (max-width: 640px) {
                    margin-right: 24px;
                }
            }
            .MuiTabs-indicator{
                height: 3px;
                background-color: var(--secondaryOrange);
            }
            .MuiTabs-flexContainer{
                overflow-x: auto;
            }
        }
    }
    .custTabContent{
        :global{
            .MuiTypography-root{
                font-family: var(--primaryFont);
            }
            .MuiBox-root{
                padding: 24px 0px;
            }
        }
    }
}

.whyShalina{
    margin-bottom: 24px;
    .title{
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 16px;
    }
    .whyShalinaSec{
        margin-bottom: 24px;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        background-color: var(--lightBlue);
        padding: 18px;
        border-radius: 12px;
        @media (max-width: 640px) {
            padding: 10px;
            margin-bottom: 12px;
        }
        .whyShalinacontent{
            flex: 0 0 75%;
            max-width: 75%;
            padding: 10px;
            padding-right: 30px;
            @media (max-width: 640px) {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0;
            }
        }
        .whyShalinaImg{
            flex: 0 0 25%;
            max-width: 25%;
            @media (max-width: 640px) {
                flex: 0 0 100%;
                max-width: 100%;
                order: -1;
                margin-bottom: 10px;
            }
            img{
                width: 100%;
                display: block;
                @media (max-width: 640px) {
                    height: 142px;
                    object-fit: cover;
                    border-radius: 4px;
                }
            }
        }
        .subTitle{
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
            margin-bottom: 8px;
            color: var(--primaryBlue);
            @media (max-width: 640px) {
                font-size: 16px;
                line-height: 21px;
            }
            
        }
        p{
            @media (max-width: 640px) {
                font-size: 14px;
                line-height: 21px;
            }
        }
    }
}