.shalinaToast{
    width: 406px;
    max-width: 100%;
    .toastTitle{
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 3px;
    }
    .toastMsg{
        font-size: 14px;
        line-height: 21px;
    }
    :global{
        .MuiAlert-root{
            border-radius: 12px;
        }
        .MuiAlert-filledSuccess{
            background-color: var(--green);
        }
        .MuiAlert-filledError{
            background-color: var(--errorColor);
        }
    }
}