.rssListing {
  padding-bottom: 30px;

  .pageTitle {
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    color: #fff;
    margin-bottom: 0px;
    @media (max-width: 640px) {
      font-size: 18px;
      line-height: 27px;
    }
  }

  .listingTop {
    padding-bottom: 24px;
    padding-top: 24px;
    background-color: var(--primaryBlue);
    @media (max-width: 640px) {
      padding: 20px 0;
    }
  }

  :global {
    .infinite-scroll-component {
      overflow: unset !important;
    }
  }
}

.rssRow {
  display: flex;
  flex-wrap: wrap;
  padding-top: 29px;
  margin: 0 -8px;
  @media (max-width: 640px) {
    padding-top: 20px;
  }

  .listingCol8 {
    flex: 0 0 74%;
    max-width: 74%;
    padding: 0 8px;
    @media (max-width: 640px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .listingCol4 {
    flex: 0 0 26%;
    max-width: 26%;
    padding: 0 8px;
    @media (max-width: 640px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .listingCol12{
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.listHeight {
  min-height: 500px;
}
.rssQuicklink{
  position: sticky;
  top: 16px;
  z-index: 2;
  margin-bottom: 16px;
}
.rssCol {
  margin: 0 -8px 16px;
}

.contentNotFound {
  width: 510px;
  max-width: 100%;
  margin: 20px auto;
  text-align: center;

  .notFoundImg {
    margin-bottom: 35px;

    img {
      max-width: 100%;
    }
  }

  h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: var(--primaryBlue);
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: var(--neutral2);
  }
}

.rssFilterWrapper {
  display: flex;
  // width: 960px;
  max-width: 100%;
  flex-wrap: wrap;
  @media (max-width: 640px) {
    flex-wrap: wrap;
    margin: 0 -4px;
  }
  .searchTitle{
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: #fff;
    flex: 0 0 100%;
    margin-top: 15px;
    @media (max-width: 640px) {
      font-size: 16px;
      line-height: 20px;
      padding: 0 4px;
    }
  }
  :global {
    .globalLabel {
      color: #fff;
    }
    .MuiAutocomplete-root {
      background-color: #fff;
      border-radius: 4px;
    }
  }
  .qualification {
    margin-top: 12px;
    margin-right: 16px;
    flex: 1 0 0;
    @media (max-width: 640px) {
      flex: 0 0 100%;
      margin-right: 0;
      padding: 0 4px;
    }
  }
  .startEndDate {
    margin-top: 12px;
    margin-right: 16px;
    @media (max-width: 640px) {
      flex: 0 0 50%;
      max-width: 50%;
      margin: 12px 0 0;
      padding: 0 4px;
    }
    label {
      margin-bottom: 8px;
      font-size: 14px;
      display: block;
      color: #fff;
    }
    input {
      height: 48px;
      padding: 0 10px;
      font-family: var(--primaryFont);
      font-size: 16px;
      border-radius: 4px;
      outline: none;
      width: 100%;
      max-width: 160px;
      border: 1px solid #8080804D;
      &:focus{
        border-color: #1976d2;
      }
    }
  }
  .submit {
    background-color: var(--secondaryOrange);
    border: 1px solid var(--secondaryOrange);
    border-radius: 100px;
    font-size: 14px;
    font-weight: 600;
    height: 48px;
    width: 133px;
    text-transform: capitalize;
    color: #fff;
    transition: 0.3s ease;
    margin-top: 38px;
    @media (max-width: 640px) {
      width: 100%;
      margin-top: 15px;
    }
    &:hover {
      background-color: var(--secondaryBtnHover);
    }
    &:disabled {
      opacity: 0.4;
      cursor: default;
      &:hover {
        background-color: var(--secondaryOrange);
      }
    }
  }
}
.rssSearch {
  position: relative;
  margin-right: 16px;
  margin-top: 12px;
  flex: 1 0;
  @media (max-width: 640px) {
    margin-right: 0;
    flex: 0 0 100%;
    padding: 0 4px;
  }
  
  label{
    margin-bottom: 8px;
    font-size: 14px;
    display: block;
    color: #fff;
  }
  svg {
    position: absolute;
    top: 15px;
    left: 10px;
    display: none;
  }
  input {
    height: 48px;
    border-radius: 6px;
    max-width: 100%;
    padding: 0 10px;
    font-size: 16px;
    outline: none;
    width: 100%;
    border: 1px solid #8080804D;
    &:focus{
      border-color: #1976d2;
    }
  }
}
