.countrySelectOption {
  margin-bottom: 8px !important;
  img {
    border-radius: 50%;
    margin-right: 12px;
    height: 24px;
    width: 24px;
    object-fit: cover;
  }
}
.footerLang{
  color: red;
}
.country {
  min-width: 90px;
  background-color: #F5F5F5;
  border-radius: 32px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-size: 12px;
  font-weight: 500;
  @media (max-width: 1185px) {
    margin-left: 0px;
  }
  &.footerLang{
    background-color: transparent;
    border: 1px solid #BFBFBF;
    margin: 0;
    svg path{fill: #fff;}
    :global{
      .MuiSelect-select{
        color: #fff;
      }
    }
  }
  img {
    display: inline-block;
    border-radius: 50%;
    margin-right: 10px;
    height: 24px;
    width: 24px;
    object-fit: cover;
  }
  svg {
    position: absolute;
    right: 0;
    path{
      fill: var(--primaryBlue);
    }
  }
  :global {
    .MuiSelect-select {
      padding: 0;
      display: flex;
      align-items: center;
      padding-right: 20px !important;
      text-transform: capitalize;
      font-family: var(--primaryFont);
      font-size: 16px;
      font-weight: 500;
      position: relative;
      z-index: 9;
    }
    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
  }
}

.noFlag {
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
  height: 24px;
  width: 24px;
  object-fit: cover;
  background-color: #eee;
}