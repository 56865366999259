.customDialog {
  :global {
    .MuiPaper-root {
      width: 612px;
      max-width: 100%;
      border-radius: 12px;
      margin: 0 16px;
    }

    .MuiDialogContent-root {
      padding: 24px;
      color: var(--textColor);

      @media (max-width: 640px) {
        padding: 16px;
      }
    }
  }
  .dialogContent {
    position: relative;
    .modalHeader {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .closeCta {
      background-color: transparent;
      font-size: 30px;
      height: 30px;
      line-height: 30px;
      padding: 0;
      right: -9px;
      top: -9px;
      width: 30px;
    }
    .fileName {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      text-align: center;
      margin-top: 8px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      flex: 1;
    }
    .dialogImage {
      max-width: 100%;
      max-height: 400px;
      margin: 0 auto;
      display: block;
      margin-top: 16px;
    }
  }
}
