.detailTop {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
}

.detailImg {
  flex: 0 0 calc(37% + 30px);
  max-width: calc(37% + 30px);
  padding-right: 30px;
  @media (max-width: 640px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 16px;
    padding-right: 0px;
  }

  img {
    width: 100%;
    border-radius: 8px;
    height: 194px;
    object-fit: cover;
    display: block;
    @media (max-width: 640px) {
      height: 145px;
      border-radius: 16px;
    }
  }
}

.shareAndCta{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 640px) {
    flex-direction: column;
  }
  .dateCtaWrapper{}
}
.dateViews {
  // margin-top: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 640px) {
    // margin-top: 16px;
    // font-size: 12px;
    // display: none;
  }

  span {
    display: inline-flex;
    align-items: center;
    color: var(--neutral2);

    strong {
      color: var(--textColor);
      font-weight: 500;
      margin-right: 4px;
    }

    svg {
      margin-right: 8px;
    }

    // &:first-child {
    //   border-right: 1px solid #e0e0e0;
    //   margin-right: 12px;
    //   padding-right: 12px;
    // }
  }
  &.MobileDateViews{
    display: none;
    @media (max-width: 640px) {
      display: flex;
    }
  }
}

.detailInfo {
  flex: 1;
  max-width: 100%;
  // padding-left: 30px;
  margin-bottom: 20px;
  &.noImg{
    // flex: 0 0 100%;
    // max-width: 100%;
    // padding-left: 0px;
  }
  @media (max-width: 640px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0px;
    margin-bottom: 10px;
  }

  .tag {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 12px;

    svg,
    img {
      margin-right: 8px;
      height: 14px;
      width: auto;
    }
  }

  .detailTitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 16px;
    // min-height: 120px;
    @media (max-width: 640px) {
      font-size: 16px;
      line-height: 24px;
      // min-height: unset;
      margin-bottom: 16px;
    }
  }

  .specialityWrap {
    margin: 0 0px 4px;

    .specialityItem {
      margin: 0 6px 5px;
      background-color: var(--lightBlue);
      font-size: 12px;
      padding: 3px 10px;
      border-radius: 12px;
      color: var(--primaryBlue);
    }
  }

  .publisher {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 16px;
    color: var(--neutral2);
    @media (max-width: 640px) {
      margin-bottom: 12px;
    }

    svg {
      margin-right: 8px;
      // @media (max-width: 640px) {
      //   margin-right: 8px;
      // }
    }
    .authorLabel{
      color: var(--textColor);
      font-weight: 500;
      margin-right: 6px;
    }
    .authorText{
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      max-width: calc(100% - 130px);
      overflow: hidden;
      @media (max-width: 640px) {
        display: block;
      }
    }
  }
}

.shareAndViews {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // margin-bottom: 30px;
  align-self: end;
  flex: 1;
  @media (max-width: 640px) {
    margin-top: 16px;
  }

  .shareWrap {
    display: flex;
    align-items: center;
    margin: 0 -8px;

    button {
      height: 40px;
      width: 40px;
      border-radius: 6px;
      background-color: #fff;
      margin: 0 8px;
    }
  }
}

.articalContent {
  font-size: 16px;
  line-height: 24px;
  position: relative;
  &.guestDesc{
    min-height: 140px;
  }

  p {
    margin-bottom: 10px;
  }

  .guestDesc {
    // background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.9), #fff);
    background: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.9), #fff);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 97px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 0px;
    border-radius: 16px;
    button {
      width: 343px;
      max-width: 100%;
      height: 45px;
      border-radius: 100px;
      background-color: var(--primaryBlue);
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      font-family: var(--primaryFont);
    }
  }
}

.recentArticle{
  display: flex;
  align-items: center;
  h4{
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    margin-right: 6px;
  }
  span{
    color: var(--primaryBlue);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    flex: 1;
    max-width: calc(100% - 400px);
    font-size: 14px;
    line-height: 21px;
    transition: 0.3s ease;
    text-decoration: underline;
    cursor: pointer;
    @media (max-width: 640px) {
      max-width: unset;
    }
  &:hover{
      color: var(--primaryBlue);
    }
  }
}

.detailCta {
  margin-top: 24px;
  width: 300px;
  max-width: 100%;
  position: relative;
  @media (max-width: 640px) {
    margin-top: 25px;
    width: 100%;
  }

  .disableSpan {
    display: none;
  }
}

.bookDetail {
  position: relative;
  &.guestDescWrapper {
      min-height: 140px;
      max-height: 140px;
      overflow: hidden;
  }
  p {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-bottom: 24px;

      &:last-child {
          margin-bottom: 0;
      }

      span {
          color: var(--neutral2);
          flex: 1 0 0;
      }

      label {
          width: 180px;
          padding-right: 10px;
          font-weight: 600;
          font-size: 16px;
      }
  }
}

/* tabs css start */
.shalinaTabs {
  .custTabs {
      background-color: #E6F0FA;
      padding: 16px 24px 0;
      margin: 0 -24px;
      @media (max-width: 640px) {
          margin: 0 -15px;
          padding: 16px 15px 0;
       }

      :global {
          .MuiTab-root {
              padding: 0;
              text-transform: capitalize;
              font-family: inherit;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              margin-right: 60px;
              color: var(--primaryBlue);
              
              &.Mui-selected {
                  font-weight: 600;
              }
              @media (max-width: 640px) {
                  margin-right: 70px;
              }
          }

          .MuiTabs-indicator {
              height: 3px;
              background-color: var(--secondaryOrange);
          }
          .MuiTabs-flexContainer{
            overflow-x: auto;
          }
      }
  }

  .custTabContent {
      position: relative;

      :global {
          .MuiBox-root {
              padding: 24px 0 0;
          }

          .MuiTypography-root {
              font-family: var(--primaryFont);
          }
      }
  }
}

/* tabs css start */

.detailPage.isLoggOut {
  .detailInfo {
      .detailCta {
          .disableSpan {
              display: block;
              position: absolute;
              background-color: #fff;
              width: 100%;
              height: 100%;
              z-index: 1;
              opacity: 0.5;
          }
      }
  }

  .detailActions {
      position: relative;

      .disableSpan {
          display: block;
          position: absolute;
          background-color: #fff;
          width: 100%;
          height: 100%;
          z-index: 1;
          opacity: 0.5;
      }
  }

  .ebookContent {
      max-height: 100px;
      overflow: hidden;
  }

  .guestDesc {
      display: block;
  }
}

.ebookContent {
  position: relative;
  p{
    margin-bottom: 10px;
  }
  strong{
    font-weight: 600;
  }
  :global{
    img.align-center{
      margin: 0 auto;
    }
    img.align-right{
      margin-left: auto;
    }
  }
  img{
    display: block;
    max-width: 100%;
  }
}

.ebookContent {
  &.guestDescWrapper {
      min-height: 140px;
  }
}
.guestDesc {
  // background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.9), #fff);
  background: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.9), #fff);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 97px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 0px;

  button {
      width: 343px;
      max-width: 100%;
      height: 45px;
      border-radius: 100px;
      background-color: var(--primaryBlue);
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      font-family: var(--primaryFont);
  }
}

.articalListWrapper{
  // height: 343px;
  overflow-x: visible;
  position: relative;
  :global{
    .infinite-scroll-component {
      overflow: unset !important;
    }
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 0;
  }
  &::-webkit-scrollbar-track {
    box-shadow: none;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--neutral2); 
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--textColor);
  }

  &.logoutArtical{
    height: 140px;
    overflow: hidden;
  }
  .guestDescWrapper{
    position: absolute;
    width: 100%;
  }
}

.articleSearch{
  background-color: #F5F5F5;
  border-radius: 100px;
  position: relative;
  margin-bottom: 24px;
  width: 343px;
  max-width: 100%;
  @media (max-width: 640px) {
    width: 100%;
  }
  .searchIcon{
    position: absolute;
    left: 11px;
    top: calc(50% - 7px);
    height: 14px;
    width: auto;
  }
  input{
    height: 36px;
    width: 100%;
    background-color: transparent;
    padding: 0 10px 0 38px;
    outline: none;
    font-size: 14px;
    &:-internal-autofill-selected,
    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill:active{
      -webkit-box-shadow: 0 0 0 30px #F5F5F5 inset !important;
      border-radius: 100px;
    }
  }
  .searchLoader {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 16px;
    background-color: #ffffff8c;
  }
}

.articleList{
  position: relative;
  .articleItem{
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #D3D3D3;
    padding-bottom: 12px;
    margin-bottom: 12px;
    img{
      flex: 0 0 28px;
      max-width: 28px;
      height: auto;
      display: block;
      margin-top: 2px;
      object-fit: cover;
    }
    .details{
      padding-left: 15px;
      flex: 1 0 0;
      .articleTitle{
        font-size: 18px;
        line-height: 27px;
        font-weight: 500;
        margin-bottom: 8px;
        cursor: pointer;
        color: var(--primaryBlue);
        @media (max-width: 640px) {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 6px;
        }
      }
      .articleAuthor{
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        font-size: 14px;
        @media (max-width: 640px) {
          margin-bottom: 6px;
          font-size: 12px;
        }
        svg{
          margin-right: 6px;
        }
        span{
          color: var(--neutral2);
          margin-right: 6px;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          display: -webkit-box;
          max-width: calc(100% - 36px);
          strong{
            font-weight: 500;
            color: var(--textColor);
            margin-right: 4px;
          }
        }
      }
      .articleDate{
        font-size: 14px;
        color: var(--neutral2);
        @media (max-width: 640px) {
          font-size: 12px;
        }
      }
    }
  }
}

.journalModal{
  .closeDiv{
    // text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0px;
    z-index: 4;
    span{
      cursor: pointer;
      font-size: 30px;
      height: 20px;
      width: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
    }
    .backBtn {
      margin-right: 18px;
      font-size: 14px;
      font-weight: 600;
      padding: 6px 10px;
      min-width: 150px;
      height: 32px;
      cursor: pointer;
      background-color: var(--primaryBlue);
      border-radius: 100px;
      color: #fff;
      transition: 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-right: 5px;
        transform: rotate(90deg);
        path {
          fill: #fff;
        }
      }
    }
  }
}

.contentNotFound{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 20px;
  margin: 0 auto;
  @media (max-width: 640px) {
      flex-wrap: wrap;
  }
  .notFoundImg{
      flex: 0 0 223px;
      max-width: 100%;
      @media (max-width: 640px) {
          margin-bottom: 10px;
      }
      img{
          max-width: 100%;
      }
  }
  .notFoundText{
      flex: 0 0 538px;
      max-width: 100%;
      padding-left: 40px;
      @media (max-width: 640px) {
          width: 376px;
          padding: 0 20px;
          text-align: center;
      }
      h3{
          font-size: 24px;
          font-weight: 600;
          line-height: 36px;
          margin-bottom: 10px;
          color: var(--primaryBlue);
      }
      p{
          font-size: 16px;
          line-height: 24px;
          color: var(--neutral2);
      }
  }
}

.articalContainer{
  position: relative;
}
.journalLoader{
  height: 100%;
  width: 100%;
  background-color: #ffffffb5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1400;
}
.journalLoaderWrapper {
  min-height: 200px;
}

.loaderDiv {
  margin: 0 -24px 0;
  position: relative;
  top: -24px;
}