.pageTitle{
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    margin-bottom: 16px;
  }
.landingFilter{
    background-color: #fff;
    padding: 20px;
    border-radius: 100px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1490196078);
    margin-bottom: 25px;
    position: relative;
    .loaderDiv {
      position: absolute;
      width: calc(100% - 60px);
      left: 32px;
      bottom: -2px;
    }
}

.statusWrapper{
    display: flex;
    align-items: center;
    margin: 0 -6px 22px;
    button{
        min-width: 95px;
        height: 32px;
        background-color: var(--primaryBlue);
        border: 1px solid var(--primaryBlue);
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        border-radius: 100px;
        transition: 0.3s ease;
        display: block;
        margin: 0 6px;
        &:hover{
            border-color: var(--btnHover);
            background-color: var(--btnHover);
        }
        &.active{
          background-color: var(--secondaryOrange);
          color: #fff;
          border-color: var(--secondaryOrange);
        }
    }
}

.notFoundSec {
    padding: 16px;
    text-align: center;
    .notFoundWrapper {
      width: 384px;
      max-width: 100%;
      margin: 0 auto;
      img {
        max-width: 100%;
        max-height: 190px;
        height: auto;
        margin-bottom: 30px;
      }
      h2 {
        font-size: 24px;
        line-height: 36px;
        font-weight: 600;
        color: var(--primaryBlue);
        margin-bottom: 8px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 30px;
        color: var(--neutral2);
      }
      .cta {
        width: 343px;
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }

  .requestWrapper {
    :global{
      .infinite-scroll-component {
        overflow: unset !important;
      }
    }
  }