.commnetItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  position: relative;

  .userImg {
    max-width: 40px;
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid var(--lightBlue);
    position: relative;
    z-index: 1;
  }

  .msgWrapper {
    background-color: #f6f6f6;
    padding: 12px 14px;
    border-radius: 6px;
    flex: 1 0;
    margin-left: 10px;
    font-size: 14px;
    line-height: 21px;

    .userName {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .commentMsg {
      margin: 0;
      :global {
        span {
          pointer-events: none;
          text-decoration: none;
          color: var(--primaryBlue);
          font-weight: 600;
        }  
        .wysiwyg-mention {
          pointer-events: none;
          text-decoration: none;
          color: var(--primaryBlue);
          font-weight: 600;
        }
      }
    }
    .attachedFile {
      display: flex;
      align-items: center;
      margin-top: 5px;
      color: var(--primaryBlue);
      font-weight: 600;
      svg {
        margin-right: 5px;
      }
      span {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        max-width: calc(100% - 30px);
        cursor: pointer;
      }
    }

    .commentDate {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 12px;
      font-weight: 500;
      color: var(--neutral2);

      .pendingWrapper {
        display: flex;
        align-items: center;
        .revokeCta {
          min-width: 86px;
          height: 27px;
          background-color: var(--primaryBlue);
          color: #fff;
          border-radius: 100px;
          margin-left: 12px;
          font-weight: 600;
          position: relative;
        }
      }

      .awaitLabel {
        display: flex;
        align-items: center;
        font-weight: 400;
        background-color: var(--secondaryOrange);
        padding: 3px 10px;
        color: #fff;
        border-radius: 100px;

        svg {
          margin-right: 6px;
        }
      }
      .rejectLabel {
        display: flex;
        align-items: center;
        font-weight: 400;
        background-color: var(--inputError);
        padding: 3px 10px;
        color: #fff;
        border-radius: 100px;

        svg {
          margin-right: 6px;
        }
      }
      .replyCta {
        background-color: transparent;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        color: var(--primaryBlue);
        padding: 0 5px;
        margin-right: 10px;
        &.disabled {
          opacity: 0.4;
          cursor: default;
        }
      }
    }
  }
  .replyLine {
    display: none;
    position: absolute;
    top: 36px;
    left: 20px;
    width: 40px;
    height: 100%;
    border-left: 1.5px solid #e0e0e0;
    border-bottom: 1.5px solid #e0e0e0;
    border-radius: 0px 0px 0px 20px;
    z-index: 0;
  }
  &.awaiting {
    .msgWrapper {
      background-color: var(--lightOrange);

      .commentDate {
        justify-content: space-between;
        margin-top: 12px;
      }
    }
  }
  &.rejected {
    .msgWrapper {
      background-color: var(--lightOrange);

      .commentDate {
        justify-content: space-between;
        margin-top: 12px;
      }
    }
  }
  &.openReply,
  &.commentReplay {
    .replyLine {
      display: block;
    }
  }
}

.replyInputWrapper {
  padding-left: 50px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  .userImg {
    max-width: 40px;
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid var(--lightBlue);
    position: relative;
    z-index: 1;
  }
  .replyBox {
    flex: 1 0;
    margin-left: 10px;
  }
  .replyInput {
    // height: 40px;
    border: 1px solid rgba(128, 128, 128, 0.3019607843);
    border-radius: 6px;
    display: flex;
    align-items: flex-end;

    .richTextEditor {
      border: 0 !important;
      flex: 1;
      &>div {
        border-bottom: 0 !important;
      }
    }
    textarea {
      height: 100%;
      padding: 0;
      background-color: transparent;
      flex: 1 0 0;
      outline: none;
      font-size: 14px;
      font-weight: 400;
      padding: 8px 4px 8px 14px;
      font-size: 14px;
      font-family: var(--primaryFont);
      resize: none;
      &::placeholder {
        color: var(--neutral2);
      }
    }
    .actionCtas {
      display: flex;
      align-items: center;
      .attachedButton {
        padding: 0 10px;
        height: 40px;
        display: flex;
        align-items: center;
        &.disabled {
          opacity: 0.4;
          cursor: default;
        }
        cursor: pointer;
        input[type="file"] {
          display: none;
        }
      }
    }
    .submitCta {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      background-color: transparent;
      position: relative;
      &.disabled {
        opacity: 0.4;
        cursor: default;
      }
    }
  }
  .fileNote {
    color: var(--neutral2);
    font-size: 13px;
    text-align: right;
    display: block;
    margin-top: 5px;
    margin-right: 5px;
  }
  .selectedFile {
    background-color: var(--lightBlue);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px;
    font-size: 14px;
    border-radius: 6px;
    margin-top: 10px;
    button {
      background-color: transparent;
      padding: 0;
      font-size: 24px;
      height: 16px;
      width: 16px;
      line-height: 21px;
      &.disabled {
        opacity: 0.4;
        cursor: default;
      }
    }
  }
  .uploadError {
    color: var(--inputError);
    font-size: 13px;
    text-align: right;
    display: block;
    margin-top: 5px;
    margin-right: 5px;
  }
}

.replyCommnent {
  padding-left: 50px;
  .commnetItem {
    .msgWrapper {
      background-color: var(--lightBlue);
    }
  }
}

.resourceModal {
  :global {
    .MuiDialogContent-root {
      padding: 20px 20px 20px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }

    .MuiPaper-root {
      width: 706px;
      max-width: 100%;
      border-radius: 12px;
    }
  }
  .modalHeader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .closeCta {
    font-size: 35px;
    cursor: pointer;
    color: var(--primaryBlue);
    background-color: transparent;
    height: 32px;
    width: 32px;
    line-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 16px;
    right: 16px;
  }
  .fileName {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
    margin-top: 8px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    flex: 1;
  }
  .pdfWrapper {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 16px;
    &::-webkit-scrollbar {
      width: 4px;
      height: 0;
    }

    &::-webkit-scrollbar-track {
      box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--neutral2);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--textColor);
    }
  }
  .imgWrapper {
    img {
      display: block;
      max-width: 80%;
      max-height: 410px;
      object-fit: contain;
      margin: 16px auto;
    }
  }
  .resourceLoader {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.replyInput {
  :global {
    .demo-wrapper {
      height: auto;
      width: 100%;
      padding: 0 16px;
      // .rdw-mention-link{
      //   color: var(--primaryBlue);
      //   background-color: transparent;
      //   font-weight: 600;
      // }
      // .rdw-suggestion-dropdown {
      //   min-width: 250px;
      // }
      // .rdw-editor-main {
      //   overflow: visible;
      // }
      .public-DraftEditorPlaceholder-root {
        height: 100%;
        width: 100%;
        // padding: 16px 0;
      }
    }
  }
}
