.detailsWrapper {
  box-shadow: 0px 4px 15px 0px #00000026;
  padding: 24px;
  background-color: #fff;
  border-radius: 16px;
}
.detailTopWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 16px;
}
.detailTop {
  display: flex;
  flex: 1;
  .detailIconWrapper{
    flex: 0 0 43px;
    max-width: 43px;
    height: 43px;
    
    .detailIcon {
      max-width: 43px;
      height: 43px;
      object-fit: cover;
      border-radius: 50%;
      border: 4px solid var(--lightBlue);
    }
  }
  

  .details {
    flex: 1 0 0;
    max-width: calc(100% - 43px);
    padding-left: 10px;
    padding-right: 10px;
  }

  .title {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 8px;
  }

  .speciality {
    :global {
      .globalspecialityWrapper {
        margin-bottom: 6px;
      }
    }
  }

  .members {
    align-items: center;
    color: var(--neutral2);
    display: flex;
    font-size: 12px;
    margin-bottom: 5px;

    .memberImages {
      display: flex;
      padding-right: 13px;

      .imageWrapper {
        border: 3px solid #fff;
        border-radius: 50%;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin-right: -7px;
        overflow: hidden;

        img {
          border-radius: inherit;
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
    }
  }
  
}
.actionCta {
  display: flex;
  align-items: center;
  margin: 0px -5px 0;
  button {
    background-color: transparent;
    border: 1px solid var(--primaryBlue);
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    color: var(--primaryBlue);
    min-width: 150px;
    height: 32px;
    border-radius: 100px;
    position: relative;
    margin: 0 5px;
    &:hover {
      border-color: var(--btnHover);
      color: var(--btnHover);
    }
    &.darkCta {
      background-color: var(--primaryBlue);
      color: #fff;
      &:hover {
        background-color: var(--btnHover);
        color: #fff;
      }
    }
  }
}
.detailTopDropdown{
  position: relative;
  .menuIcon{
    cursor: pointer;
  }
  .dropdownItem{
    position: absolute;
    padding: 4px 0;
    list-style: none;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1490196078);
    background-color: #fff;
    border-radius: 12px;
    top: 26px;
    right: 0;
    li{
      padding: 6px 15px;
      span{
        color: var(--textColor);
        font-weight: 500;
        white-space: nowrap;
        text-decoration: none;
        transition: 0.3s ease;
        cursor: pointer;
        &:hover{
          color: var(--primaryBlue);
        }
      }
    }
  }
}
.communityTabs {
  :global {
    .globalcustTabs {
      margin: 0 -24px;
    }
  }
}

.aboutDetails {
  p {
    margin-bottom: 12px;
  }
}

.detailexpertList {
  .itemWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 12px;
    margin-bottom: 12px;

    &:last-child {
      border: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    .expertTag {
      background-color: var(--lightOrange);
      padding: 3px 16px;
      color: var(--primaryBlue);
      border-radius: 100px;
      font-size: 12px;
    }
  }
  .detailexperItem {
    flex: 1 0 0;
    display: flex;
    align-items: flex-start;

    .expertImg {
      flex: 0 0 60px;
      max-width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 50%;
    }

    .details {
      flex: 1 0 0;
      padding-left: 15px;

      .name {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
      }

      .roleLocation {
        font-size: 12px;

        .role {
          margin-bottom: 2px;
          // display: block;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .location {
          display: flex;
          align-items: center;
          color: var(--neutral2);
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          svg {
            margin-right: 4px;
            width: 8px;
            height: auto;
          }
        }
      }
    }
  }
}

.resourcesWrapper {
  margin: 0 -10px;
  .resourcItem {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
    .name {
      word-break: break-all;
      flex: 0 0 340px;
      padding: 0 16px;
      display: flex;
      align-items: flex-start;
      cursor: pointer;
      svg, img {
        margin-right: 8px;
        flex: 0 0 13px;
        margin-top: 4px;
      }
    }
    .size {
      flex: 0 0 120px;
      padding: 0 16px;
      color: var(--neutral2);
    }
    .date {
      flex: 1 0;
      padding: 0 16px;
      min-width: 170px;
      color: var(--neutral2);
    }
    &.head {
      font-weight: 500;
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 10px;
      margin-bottom: 15px;
      .name,
      .size,
      .date {
        color: var(--textColor);
        cursor: default;
      }
    }
  }
}

.detailexpertList, .resourcesWrapper {
  :global{
    .infinite-scroll-component {
      overflow: unset !important;
    }
  }
}

.resourceModal {
  :global {
    .MuiDialogContent-root {
      padding: 20px 20px 20px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }

    .MuiPaper-root {
      width: 706px;
      max-width: 100%;
      border-radius: 12px;
    }
  }
  .modalHeader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .closeCta{
    font-size: 35px;
    cursor: pointer;
    color: var(--primaryBlue);
    background-color: transparent;
    height: 32px;
    width: 32px;
    line-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 16px;
    right: 16px;
  }
  .fileName{
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
    margin-top: 8px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    flex: 1;
  }
  .pdfWrapper{
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 16px;
    &::-webkit-scrollbar {
      width: 4px;
      height: 0;
    }

    &::-webkit-scrollbar-track {
      box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--neutral2);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--textColor);
    }
  }
  .imgWrapper{
    img{
      display: block;
      max-width: 80%;
      max-height: 410px;
      object-fit: contain;
      margin: 16px auto;
    }
  }
  .resourceLoader {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


.leaveDilog{
  :global {
    .MuiPaper-root {
      width: 512px;
      max-width: 100%;
      border-radius: 12px;
      margin: 16px;
    }

    .MuiDialogContent-root {
      padding: 24px;
      @media (max-width: 640px) {
        padding: 16px;
      }
    }
  }
  .leaveWrapper{
    .title{
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .msg{
      font-size: 16px;
      line-height: 24px;
    }
    .ctaWrapper{
      display: flex;
      align-items: center;
      margin: 30px -8px 0;
      .ctaItem{
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 8px;
        &.cancel{
          button{
            background-color: transparent;
            color: var(--primaryBlue);
          }
        }
      }
    }
  }
}

.noData{
  .noDataImg{
    display: block;
    margin: 0 auto 20px;
  }
  .noDataMsg{
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    margin-bottom: 10px;
    color: var(--primaryBlue);
    text-align: center;
  }
}

.custTabsWrapper {
  .custTabs {
      background-color: #E6F0FA;
      padding: 16px 24px 0;

      @media (max-width: 640px) {
          margin: 0 -15px;
          padding: 16px 15px 0;
      }

      :global {
          .MuiTab-root {
              padding: 0;
              text-transform: capitalize;
              font-family: inherit;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              margin-right: 60px;
              color: var(--primaryBlue);
              min-width: auto;

              &.Mui-selected {
                  font-weight: 600;
              }

              @media (max-width: 640px) {
                  margin-right: 70px;
              }
          }

          .MuiTabs-flexContainer {
              overflow-x: auto;
          }

          .MuiTabs-indicator {
              height: 3px;
              background-color: var(--secondaryOrange);
          }
      }
  }

  .custTabContent {
      position: relative;

      :global {
          .MuiBox-root {
              padding: 24px 0 0;
          }

          .MuiTypography-root {
              font-family: var(--primaryFont);
          }
      }
  }
}

.initialLoader {
  height: 270px;
  margin: -24px -24px 0;
}

.leave {
  display: none;
}

.joinModal{
  .closeCta{
      background-color: transparent;
      font-size: 30px;
      height: 30px;
      line-height: 30px;
      padding: 0;
      position: absolute;
      right: 9px;
      top: 13px;
      width: 30px;
      color: #fff;
  }
  :global {
    .MuiPaper-root {
      width: 700px;
      max-width: 100%;
      border-radius: 12px;
      margin: 16px;
    }

    .MuiDialogContent-root {
      padding: 0px;
      position: relative;
    }
  }
}

.shareWrapper {
  position: relative;
}

.shareAndCta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 53px;
  margin: 16px 0;
}

.detailActions {
  button {
    // height: 40px;
    // width: 40px;
    border-radius: 6px;
    background-color: #fff;
    // margin: 0 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &.disabled {
      opacity: 0.4;
      cursor: default;
    }

    &.likeDisabled {
      cursor: default;
    }
    &.likeActive {
      cursor: default;
    }

    &.active {
      svg path {
        fill: var(--secondaryOrange);
      }
    }
  }
}

.shareList {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  bottom: -45px;
  right: -8px;
  width: 100%;
  @media (max-width: 640px) {
    width: auto;
    margin: 0 -4px;
  }
  .shareListIcon {
    transition: 0.3s ease;
    padding: 0 6px;
    @media (max-width: 640px) {
      padding: 0 10px;
    }
    .copyIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      background-color: var(--primaryBlue);
      cursor: pointer;
      svg {
        height: 22px;
        width: auto;
        path {
          fill: white;
        }
      }
    }
    &:hover {
      transform: scale(1.1);
    }
  }
}