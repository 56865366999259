/* filter section start */
.filterContainer {
  display: flex;
  // flex-wrap: wrap;
  align-items: center;
  margin: 0 -5px 0px;

  @media (max-width: 640px) {
    margin: 0 0px 0 -5px;
  }

  .filterOption {
    color: #fff;
    border: 1px solid #fff;
    border-radius: 25px;
    padding: 6px 11px;
    margin: 0 5px;
    background-color: transparent;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    
    &.disabled {
      opacity: 0.4;
      cursor: default;
    }
    @media (max-width: 640px) {
      padding: 4px 4px;
    }

    &>svg path {
      fill: var(--primaryBlue);
    }

    .filterText {
      @media (max-width: 640px) {
        display: none;
      }
    }
  }

  .filterOptionLabel {
    span {
      display: inline-block;
      border: 1px solid #fff;
      color: #fff;
      border-radius: 25px;
      padding: 6px 11px;
      margin: 0 5px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      white-space: nowrap;

      @media (max-width: 640px) {
        font-size: 12px;
        padding: 5px 11px;
      }
    }

    input[type="checkbox"] {
      display: none;

      &:checked+span {
        background-color: var(--secondaryOrange);
        color: #fff;
        border-color: var(--secondaryOrange);
      }
    }
  }

  .sortFilter {
    display: flex;
    align-items: center;

    .filterOption {
      display: flex;
      align-items: center;
      background-color: #fafafa;
      position: relative;
      color: var(--primaryBlue);

      svg {
        margin-right: 5px;

        @media (max-width: 640px) {
          margin-right: 0;
        }
      }

      &.sort>svg path {
        stroke: var(--primaryBlue);
      }
    }
  }

  .quickFilter {
    display: flex;
    align-items: center;
    flex: 1;
    // flex: 0 0 calc(100% - 230px);
    max-width: calc(100% - 230px);
    overflow-x: auto;

    @media (max-width: 640px) {
      max-width: unset;
    }
    &.noFilter{
      max-width: 100%;
      padding-right: 5px;
    }
    &::-webkit-scrollbar {
      width: auto;
      height: 0;
    }

    &::-webkit-scrollbar-track {
      box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--neutral2);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--textColor);
    }
  }

  .filterSlider {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-right: 80px;
    @media (max-width: 640px) {
      padding-right: 0;
    }
    :global{
      .slick-track{
        display: flex;
      }
    }
    .sliderArrow {
      position: absolute;
      top: 0;
      right: 0;
      background-color: var(--primaryBlue);
      button {
        height: 32px;
        width: 32px;
        //border: 1px solid var(--primaryBlue);
        background-color: transparent;
        border-radius: 50%;
        cursor: pointer;
        padding: 0px;
        svg{
          overflow: visible;
          rect{fill: #fff;}
        }
        &.prev {
          margin-right: 7px;

          svg {
            transform: rotate(180deg);
          }
        }

        &:hover {
          svg {
            rect,
            path {
              stroke: var(--btnHover);
            }
          }
        }
        &:disabled {
          opacity: 0.2;
          cursor: not-allowed;
        }
      }

      @media (max-width: 640px) {
        display: none;
      }
    }
  }
}

.resetCta {
  height: 40px;
  width: 100%;
  border-radius: 100px;
  margin-top: 10px;
  background-color: transparent;
  color: var(--primaryBlue);
  border: 1px solid var(--primaryBlue);
  font-size: 14px;
  font-weight: 500;
}

.filterContainer {
  &.hasDark {
    .filterOption {
      border-color: var(--primaryBlue);
      color: var(--primaryBlue);
    }

    .filterOptionLabel {
      span {
        border-color: var(--primaryBlue);
        color: var(--primaryBlue);
        transition: 0.3s ease;

        &:hover {
          border-color: var(--btnHover);
        }
      }
    }

    .sortFilter {
      .filterOption {
        background-color: var(--primaryBlue);
        color: #fff;
        transition: 0.3s ease;

        &>svg path {
          fill: #fff;
        }

        &.sort>svg path {
          stroke: #fff;
        }

        &:hover {
          background-color: var(--btnHover);
        }
      }
    }
    .filterSlider{
      .sliderArrow{
        background-color: #fff;
        // button{
        //   &:hover{
        //     svg path{
        //       stroke: #fff;
        //     }
        //   }
        // }
        // svg{
        //   rect{
        //     fill: var(--primaryBlue);
        //   }
        //   path{
        //     stroke: #fff;
        //   }
        // }
      }
    }
  }
}

.sortDropdown {
  position: absolute;
  display: none;
  box-shadow: 0px 4px 4px 0px #00000040;
  min-width: 277px;
  padding: 20px 20px 10px;
  background-color: #fff;
  border-radius: 12px;
  z-index: 1;
  top: 35px;
  left: 0;

  &.open {
    display: block;
  }

  :global {
    .MuiRadio-root.Mui-checked {
      color: var(--secondaryOrange);
    }

    .MuiTypography-root {
      font-family: inherit;
      color: #000;
    }

    .MuiFormControlLabel-root {
      margin-right: 0;
    }
  }
}

.FilterByModal {
  :global {
    .MuiDialogContent-root {
      padding: 0;
    }

    .MuiPaper-root {
      width: 510px;
      max-width: 100%;
      border-radius: 12px;
    }
  }

  .FilterHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px 20px;

    .title {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
    }

    .closeCTA {
      font-size: 35px;
      cursor: pointer;
    }
  }

  .filterModalCTA {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px 16px;
    box-shadow: 4px 0px 10px 0px #00000040;

    button {
      width: 230px;
      max-width: calc(50% - 8px);
      height: 48px;
      color: #fff;
      border-radius: 100px;
      font-weight: 600;
      cursor: pointer;
      font-family: inherit;
      border: 1px solid #002E5B;
      background-color: var(--primaryBlue);
      font-size: 16px;

      &.reserCta {
        background-color: transparent;
        color: var(--primaryBlue);
      }
    }
  }
}

.filterTab {
  .custTabs {
    background-color: #E6F0FA;
    padding: 16px 24px 0;

    :global {
      .MuiTab-root {
        padding: 0;
        text-transform: capitalize;
        font-family: inherit;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-right: 60px;
        color: var(--primaryBlue);

        &.Mui-selected {
          font-weight: 600;
        }
      }

      .MuiTabs-indicator {
        height: 3px;
        background-color: var(--secondaryOrange);
      }
    }
  }

  .sortByTabContent {
    max-height: 250px;
    overflow-y: auto;
    padding: 10px 14px 20px 24px;
    margin-right: 10px;
    margin-top: 10px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--neutral2);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--textColor);
    }

    :global {
      .MuiBox-root {
        padding: 0;
        margin-right: 0;
      }

      .MuiFormControlLabel-root {
        margin-right: 0;
        margin-bottom: 12px;
      }
    }
  }
}

/* filter section end */