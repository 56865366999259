.mobileWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    .logNavSec{
        display: flex;
        align-items: center;
        .logo{
            height: 40px;
            width: auto;
            margin-right: 10px;
            cursor: pointer;
        }
    }
}

.moibileNav{
    display: flex;
    align-items: center;
    .hamburgerIcon{
        border: 0;
        margin-left: 20px;
        background-color: transparent;
        svg{
            display: block;
            path{
                fill: var(--primaryBlue);
            }
        }
    }
}

.mobileDrower{
    position: fixed;
    z-index: 99;
    width: 100%;
    height: calc(100% - 60px);
    background-color: #fff;
    top: 60px;
    left: 0;
    transform: translateX(100%);
    transition: 0.3s ease;
    overflow: auto;
    padding-top: 10px;
    &.open{
        transform: translateX(0);
    }
}
.menuLang{
    padding: 15px 15px;
    width: 100px;
}
.userMenuList{
    padding: 0 15px;
    ul{
        list-style: none;
        padding: 10px 0 0;
        margin: 10px 0 0;
        border-top: 1px solid #CBCBCB;
        li{
            padding: 13px 0px;
            .icon{
                display: none;
            }
            a, .userDropdownItem{
                color: var(--textColor);
                text-decoration: none;
                font-weight: 500;
                font-size: 16px;
            }
        }
    }
}