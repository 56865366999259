.kHub {
  background-color: green;
}

.loadingHeight {
  height: 500px;
}

/* search page start */
.searchRow {
  display: flex;
  flex-wrap: wrap;
  margin: 24px -8px 0px;

  @media (max-width: 640px) {
    margin: 20px -15px 0px;
  }

  .searchCol8 {
    flex: 0 0 74%;
    max-width: 74%;
    padding: 0 8px;

    @media (max-width: 640px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 15px;
    }
  }

  .searchCol4 {
    flex: 0 0 26%;
    max-width: 26%;
    padding: 0 8px;

    @media (max-width: 640px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 15px;
    }
  }
}

.searchContentWrapper {
  // width: 840px;
  // max-width: 100%;
  padding-bottom: 30px;

  :global {
    .infinite-scroll-component {
      overflow: unset !important;
    }
  }
}

.heading {
  font-size: 22px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 16px;

  @media (max-width: 640px) {
    font-size: 18px;
  }
}

.searchResult {
  padding-top: 36px;
  padding-bottom: 100px;

  @media (max-width: 640px) {
    padding-bottom: 30px;
    padding-top: 0;
  }

  .pageHead {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 16px;

    @media (max-width: 640px) {
      font-size: 18px;
    }
  }
}

.searchResults {
  padding-bottom: 50px;
}

.resultTop {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: var(--primaryBlue);

  @media (max-width: 640px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .resultHead {
    color: #fff;
    font-size: 22px;
    line-height: 33px;
    font-weight: 500;
    margin-bottom: 16px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;

    @media (max-width: 640px) {
      font-size: 18px;
      line-height: 27px;
    }
  }
}

.searchWrapper {
  background-color: #fff;
  padding: 25px;
  width: 990px;
  max-width: 100%;
  border-radius: 16px;

  @media (max-width: 640px) {
    padding: 20px 16px;
  }
}

.searchTag {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0 -4px;
  max-width: 100%;
  overflow-x: auto;

  @media (max-width: 640px) {
    margin: 0 -15px;
    padding-left: 15px;
    max-width: unset;
  }

  li {
    margin: 0 4px;

    label {
      display: block;

      span {
        display: inline-flex;
        align-items: center;
        height: 32px;
        padding: 0px 14px;
        border: 1px solid #ffffff;
        border-radius: 100px;
        color: #fff;
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
        cursor: pointer;
        white-space: nowrap;

        &.allText {
          cursor: default;
          background-color: #fff;
        }
      }
    }

    input[type="checkbox"] {
      display: none;

      &:checked+span {
        background-color: var(--secondaryOrange);
        border-color: var(--secondaryBtnHover);
        &.allText{
          background-color: #fff;
          border-color: #fff;
          color: var(--primaryBlue);
        }
      }
    }
  }

  &::-webkit-scrollbar {
    width: auto;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--neutral2);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--textColor);
  }
}

.searchSec {
  @media (max-width: 640px) {
    margin-bottom: 25px;
  }
}

.searchCard {
  border-radius: 16px;
  margin-bottom: 24px;
  padding: 24px;
  box-shadow: 0px 4px 15px 0px #00000026;
  background-color: #fff;

  // width: 840px;
  // max-width: 100%;
  @media (max-width: 640px) {
    padding: 16px;
    margin-bottom: 16px;
  }

  .tag {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-size: 12px;

    svg {
      margin-right: 10px;
      height: 15px;
      width: auto;

      path {
        fill: var(--primaryBlue);
      }
    }

    img {
      margin-right: 8px;
      max-height: 16px;
      width: auto;
    }
  }

  .searchCardTitle {
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    // span {
    //   display: block;
    // }
  }
}

.notFound {
  .notFoundImg {
    display: block;
    margin: 0 auto 30px;

    @media (max-width: 640px) {
      width: 240px;
      max-width: 100%;
      margin: 0 auto 20px;
    }
  }

  h4 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    margin-bottom: 10px;
    color: var(--primaryBlue);
    text-align: center;

    @media (max-width: 640px) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  p {
    font-size: 16px;
    line-height: 24px;
    width: 440px;
    max-width: 100%;
    text-align: center;
    margin: 0 auto 30px;
    color: var(--neutral2);
    word-break: break-word;

    @media (max-width: 640px) {
      margin-bottom: 0;
    }
  }
}

.filterSlider {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-right: 80px;

  @media (max-width: 640px) {
    padding-right: 0;
  }

  :global {
    .slick-track {
      display: flex;
    }
  }

  .sliderArrow {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--primaryBlue);

    button {
      height: 32px;
      width: 32px;
      //border: 1px solid var(--primaryBlue);
      background-color: transparent;
      border-radius: 50%;
      cursor: pointer;
      padding: 0px;

      svg {
        rect {
          fill: #fff;
        }
      }

      &.prev {
        margin-right: 7px;

        svg {
          transform: rotate(180deg);
        }
      }

      &:hover {
        svg {

          rect,
          path {
            stroke: var(--btnHover);
          }
        }
      }

      &:disabled {
        opacity: 0.2;
        cursor: not-allowed;
      }
    }

    @media (max-width: 640px) {
      display: none;
    }
  }
}

.searchDiv {
  min-height: 70vh;
}

/* search page end */

.title{
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--primaryBlue);
  text-align: center;
  @media (max-width: 640px) {
      font-size: 20px;
      line-height: 30px;
  }
}
.desc{
  font-size: 16px;
  line-height: 24px;
  width: 440px;
  max-width: 100%;
  text-align: center;
  margin: 0 auto 40px;
  color: var(--neutral2);
  word-break: break-word;
  @media (max-width: 640px) {
      margin-bottom: 30px;
  }
}
.unlockWrapper{
  background-color: #F6F6F6;
  padding: 40px 24px;
  border-radius: 12px;
  text-align: center;
  @media (max-width: 640px) {
      padding: 20px;
  }
  .goldImg{
      margin: 0 auto 24px;
  }
  .unlockHead{
      font-size: 24px;
      line-height: 36px;
      font-weight: 500;
      margin-bottom: 12px;
      color: var(--primaryBlue);
      @media (max-width: 640px) {
          font-size: 18px;
          line-height: 30px;
          font-weight: 600;
      }
  }
  .unlockDesc{
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      width: 600px;
      max-width: 100%;
      margin: 0 auto 30px;
      @media (max-width: 640px) {
          font-size: 16px;
          line-height: 21px;
      }
  }
  .unlockCta{
      width: 460px;
      margin: 0 auto;
      max-width: 100%;
  }
}