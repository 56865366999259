.expertRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
  @media (max-width: 640px) {
    margin: 0 -8px;
  }
  .expertCol {
    flex: 0 0 25%;
    max-width: 25%;
    @media (max-width: 640px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

.contentNotFound {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 20px;
  margin: 0 auto;
  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
  .notFoundImg {
    flex: 0 0 223px;
    max-width: 100%;
    @media (max-width: 640px) {
      margin-bottom: 10px;
    }
    img {
      max-width: 100%;
    }
  }
  .notFoundText {
    flex: 0 0 538px;
    max-width: 100%;
    padding-left: 40px;
    @media (max-width: 640px) {
      width: 376px;
      padding: 0 20px;
      text-align: center;
    }
    h3 {
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      margin-bottom: 10px;
      color: var(--primaryBlue);
    }
    p {
      font-size: 16px;
      line-height: 24px;
      color: var(--neutral2);
    }
  }
}

.loaderDiv {
  position: relative;
  top: -24px;
  margin: 0 -24px;
}
