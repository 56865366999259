.profileUpdateForm {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
  @media (max-width: 640px) {
    margin: 0;
  }
  .profileUpdateFormCol6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 8px;
    margin-bottom: 24px;
    position: relative;
    @media (max-width: 640px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 15px;
      margin-bottom: 16px;
    }
  }
  .profileUpdateFormCol12 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 8px;
    @media (max-width: 640px) {
      padding: 0 15px;
    }
  }
  .referralCode {
    label {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: 600;
      display: block;
    }
  }
  .profileUpdateFormCtas {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    margin: 80px -6px 0;
    @media (max-width: 640px) {
      margin: 16px -5px 0;
    }
    button {
      height: 45px;
      width: 165px;
      text-align: center;
      border: 1px solid var(--primaryBlue);
      border-radius: 100px;
      margin: 0 6px;
      background-color: transparent;
      font-weight: 600;
      color: var(--primaryBlue);
      cursor: pointer;
      font-size: 16px;
      &:disabled {
        cursor: not-allowed;
        border-color: var(--neutral2);
        color: var(--neutral2);
      }
      &.save {
        background-color: var(--primaryBlue);
        color: #fff;
        position: relative;
        &:disabled {
          background-color: var(--neutral2);
          color: #fff;
        }
      }
    }
  }
  .verifiedLabel {
    position: absolute;
    top: 0;
    right: 8px;
    color: #188423;
    display: flex;
    align-items: center;
    font-weight: 700;
    svg {
      margin-left: 5px;
    }
  }
  .verifiedCta {
    text-align: right;
    button {
      background-color: var(--primaryBlue);
      font-size: 12px;
      font-weight: 600;
      color: #fff;
      height: 28px;
      width: 64px;
      border-radius: 100px;
      margin-top: 8px;
      cursor: pointer;
    }
  }
}

.selectIntrest {
  margin-top: 25px;
  @media (max-width: 640px) {
    margin-top: 16px;
  }
  .siLabel {
    margin-bottom: 8px;
    font-size: 14px;
    display: block;
  }
  .selectIntrestList {
    margin: 0 -5px;
    @media (max-width: 640px) {
      max-height: 240px;
      overflow-y: auto;
    }
  }
}
