.shalinaUploadCta {
    .uploadLabel {
        display: block;
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 400;
    }

    .uploadWrapper {
        position: relative;
        border: 1px solid var(--primaryBlue);
        border-radius: 100px;
        height: 44px;
        display: flex;
        text-align: center;
        padding: 10px 10px;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        color: var(--primaryBlue);
        font-size: 14px;
        font-weight: 500;

        input {
            opacity: 0;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            cursor: pointer;
        }

        svg {
            margin-right: 10px;
        }
    }
    p {
        margin-top: 6px;
        color: var(--errorColor);;
    }
    .uploadedImageWrapper{
        background-color: var(--lightBlue);
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 12px;
        font-size: 14px;
        border-radius: 6px;
        // span {
        //     cursor: pointer;
        // }
        button{
            background-color: transparent;
            padding: 0;
            font-size: 24px;
            height: 16px;
            width: 16px;
            line-height: 21px;
        }
    }
}
