.footer {
  background-color: var(--primaryBlue);
}
.footerLogoWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 20px;
  @media (max-width: 640px) {
    padding-top: 20px;
  }
  .footerLang {
    height: 37px;
    width: 150px;
    background-color: #ddd;
  }
  .footerLogo {
    cursor: pointer;
  }
}
.footerWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0 -15px;
  // .footerTitle{
  //     margin-bottom: 18px;
  //     font-size: 18px;
  //     font-weight: 500;
  //     color: #fff;
  // }
  // .aboutShalina{
  //     flex: 0 0 30%;
  //     max-width: 30%;
  //     padding: 0 15px;

  // }
  // .footerItem{
  //     flex: 0 0 22%;
  //     max-width: 22%;
  //     padding: 0 15px;
  // }
  // .download{
  //     flex: 0 0 26%;
  //     max-width: 26%;
  //     padding: 0 15px;
  //     .downloadText{
  //         color: #C4C4C4;
  //         margin-bottom: 20px;
  //         line-height: 21px;
  //     }
  //     .appWrapper{
  //         display: flex;
  //         margin: 0 -15px;
  //         img{
  //             margin: 0 15px;
  //         }
  //     }
  // }
}
// .aboutShalinaText{
//     color: #CACACA;
//     margin-bottom: 26px;
//     padding-bottom: 28px;
//     padding-right: 30px;
//     line-height: 21px;
//     position: relative;
//     &::after{
//         content: '';
//         position: absolute;
//         height: 1px;
//         width: 60%;
//         background-color: #999;
//         left: 0;
//         bottom: 0;
//     }
// }
// .footerNavList{
//     padding: 0;
//     margin: 0 0 30px;
//     list-style: none;
//     li{
//         margin-bottom: 16px;
//         a{
//             font-size: 16px;
//             color: #fff;
//             text-decoration: none;
//         }
//     }
// }
// .footerSocial{
//     margin-bottom: 30px;
//     ul{
//         padding: 0;
//         margin: 0;
//         list-style: none;
//         display: flex;
//         li{
//             margin-right: 20px;
//         }
//     }
// }
.copyright {
  margin-top: 6px;
  padding-bottom: 30px;
  padding-top: 30px;
  color: #fff;
  border-top: 1px solid #e4e4e4;
  @media (max-width: 640px) {
    margin-top: 24px;
    padding-bottom: 20px;
    padding-top: 20px;
    padding-right: 60px;
  }
}
