.horizontalDivider{
    position: relative;
    border-top: 1px solid #9E9E9E;
    margin: 10px 0;
    span{
        position: absolute;
        top: -9px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
        padding: 0 10px;
        font-size: 16px;
        color: #606060;
        font-weight: 600;
    }
}