.buttonLoader {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    background-color: #ffffff6b;
    border-radius: 100px;                    
}