.pdfWrapper {
  min-height: 500px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  canvas {
    max-width: 100%;
    // margin: 0 auto;
  }
}

.pdfNavCta {
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 20px 0 0;
  padding: 10px 0 0;
  button {
    background-color: transparent;
    padding: 0;
    height: 40px;
    width: 40px;
    border: 1px solid var(--neutral2);
    font-size: 22px;
    border-radius: 50%;
    &:disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }
  .pageNav {
    font-size: 14px;
    font-weight: 500;
    margin: 0 20px;
    min-width: 40px;
  }
}
.totalPages {
  font-size: 14px;
  font-weight: 500;
}

.loaderWrapper {
  height: 100%;
  width: 100%;
  background-color: #ffffffb5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1400;
}

.pdfWrapper {
  :global {
    .react-pdf__Page__textContent {
      user-select: none;
    }
  }
}
