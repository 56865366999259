.userDetails {
  flex: 0 0 45%;
  max-width: 45%;
  margin-bottom: 30px;

  @media (max-width: 640px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 16px;
  }
}

.userDetailWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  @media (max-width: 640px) {
    background-color: #fff;
    margin: 0 -15px;
    padding: 20px 15px;
  }

  .userImg {
    flex: 0 0 190px;
    max-width: 190px;
    position: relative;

    @media (max-width: 640px) {
      flex: 0 0 73px;
      max-width: 73px;
    }

    img {
      height: 190px;
      width: 190px;
      border-radius: 50%;
      border: 8px solid #e6f0fa;
      object-fit: cover;

      @media (max-width: 640px) {
        height: 73px;
        width: 73px;
        border: 4px solid #E6F0FA;
      }
    }

    .editProfileImg {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 46px;
      width: 46px;
      border: 4px solid #e6f0fa;
      border-radius: 50%;
      bottom: 10px;
      right: 10px;
      background-color: var(--primaryBlue);
      cursor: pointer;

      @media (max-width: 640px) {
        height: 24px;
        width: 24px;
        border: 2px solid #e6f0fa;
        bottom: 8px;
        right: 0px;

        svg {
          width: 10px;
          height: auto;
        }
      }
    }
  }

  .userInfo {
    flex: 0 0 calc(100% - 190px);
    max-width: calc(100% - 190px);
    padding-left: 40px;

    @media (max-width: 640px) {
      flex: 0 0 calc(100% - 73px);
      max-width: calc(100% - 73px);
      padding-left: 15px;
    }

    .userName {
      font-size: 24px;
      line-height: 36px;
      font-weight: 600;
      margin-bottom: 4px;

      @media (max-width: 640px) {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 2px;
      }
    }

    .userDesignation {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      color: var(--neutral2);
      margin-bottom: 6px;
      min-height: 60px;

      @media (max-width: 640px) {
        font-size: 12px;
        line-height: 16px;
      }

      span {
        color: var(--textColor);
        font-weight: 500;
        display: block;

        @media (max-width: 640px) {
          font-size: 14px;
          margin-bottom: 4px;
        }
      }
    }

    // .userRole{
    //     font-size: 18px;
    //     font-weight: 500;
    //     line-height: 27px;
    //     color: #777777;
    //     margin-bottom: 22px;
    // }
  }

  .profileProgress {
    margin-bottom: 20px;

    @media (max-width: 640px) {
      margin-bottom: 6px;
    }

    .progressLabel {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      font-size: 14px;

      @media (max-width: 640px) {
        font-size: 11px;
        margin-bottom: 6px;
      }

      span {
        font-size: 16px;
        color: var(--primaryBlue);
        font-weight: 600;

        @media (max-width: 640px) {
          font-size: 11px;
        }
      }
    }

    .profileProgressBar {
      position: relative;
      height: 8px;
      border-radius: 18px;
      background-color: #ABC5DB;

      @media (max-width: 640px) {
        height: 4px;
      }

      .progressCompleted {
        position: absolute;
        border-radius: 18px;
        height: 100%;
        background-color: var(--secondaryOrange);
        left: 0;
        top: 0;
      }
    }
  }

  .updateCta {
    height: 32px;
    font-size: 14px;
    background-color: transparent;
    color: var(--primaryBlue);
    border: 1px solid var(--primaryBlue);
    border-radius: 100px;
    font-weight: 600;
    width: 100%;
    cursor: pointer;

    &:hover {
      border-color: var(--btnHover);
    }

    &.hide {
      display: none;
    }

    @media (max-width: 640px) {
      margin-top: 10px;
    }
  }
}

/* edit profile image modal start */
.editImgModal {
  :global {
    .MuiPaper-root {
      width: 612px;
      max-width: 100%;
      border-radius: 12px;
    }

    .MuiDialogContent-root {
      padding: 24px;
      color: var(--textColor);
      @media (max-width: 640px) {
        padding: 16px;
      }
    }
  }

  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    margin-bottom: 27px;
    @media (max-width: 640px) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px;
    }
  }

  .imgCta {
    display: flex;
    align-items: center;
    justify-content: center;

    .profileImg {
      flex: 0 0 225px;
      max-width: 225px;
      @media (max-width: 640px) {
        flex: 0 0 90px;
        max-width: 90px;
      }

      img {
        height: 225px;
        width: 225px;
        object-fit: cover;
        border-radius: 50%;
        border: 8px solid #e6f0fa;
        @media (max-width: 640px) {
          height: 90px;
          width: 90px;
        }
      }
    }

    .ctas {
      padding-left: 40px;
      flex: 0 0 254px;
      max-width: 254px;
      @media (max-width: 640px) {
        padding-left: 20px;
        flex: 1;
        max-width: 160px;
      }

      .uploadCta {
        display: block;
        height: 40px;
        width: 100%;
        background-color: transparent;
        border: 1px solid var(--primaryBlue);
        color: var(--primaryBlue);
        border-radius: 6px;
        margin-bottom: 18px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        @media (max-width: 640px) {
          height: 30px;
          line-height: 30px;
        }

        input[type="file"] {
          display: none;
        }
      }

      button {
        height: 40px;
        width: 100%;
        background-color: transparent;
        border: 1px solid var(--primaryBlue);
        color: var(--primaryBlue);
        border-radius: 6px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        @media (max-width: 640px) {
          height: 30px;
          line-height: 30px;
        }
      }
    }
  }

  .actionCtas {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    button {
      border: 1px solid var(--primaryBlue);
      background-color: transparent;
      width: 165px;
      height: 45px;
      border-radius: 100px;
      font-size: 16px;
      font-weight: 600;
      color: var(--primaryBlue);
      margin: 0 6px;

      &.save {
        background-color: var(--primaryBlue);
        color: #fff;
      }
    }
  }
}

/* edit profile image modal end */