.BadgeSec{
    margin-bottom: 50px;
    :global{
        .slick-track{
            margin-left: 0;
        }
    }
}
.head{
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    margin-bottom: 20px;
}
.earnItem{
    img{
        display: block;
        margin: 0 auto 15px;
        width: 76px;
        max-width: 100%;
        height: auto;
    }
    h4{
        font-size: 15px;
        line-height: 23px;
        font-weight: 500;
        text-align: center;
        max-width: 92px;
        margin: 0 auto;
    }
    &.noBadge{
        display: inline-block;
        width: 160px;
    }
}

.earnBadge{
    :global{
        .slick-slider{
            margin: 0 -8px;
        }
    }
}
.titleArrow{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    h4{
        font-size: 18px;
        line-height: 27px;
        font-weight: 500;
    }
}
.sliderArrow{
    button{
        height: 32px;
        width: 32px;
        //border: 1px solid var(--primaryBlue);
        background-color: transparent;
        border-radius: 50%;
        cursor: pointer;
        padding: 0px;
        svg{
            overflow: visible;
        }
        &.prev{
            margin-right: 7px;
            svg{
                transform: rotate(180deg);
            }
        }
        &:hover{
        svg{
            rect, path{stroke: var(--btnHover);}
        }
        }
        &:disabled {
            opacity: 0.2;
            cursor: not-allowed;
        }
    }
    @media (max-width: 640px) {
        display: none;
    }
}
.earnBoxWrap{
    padding: 0 8px 20px;
}
.earnBox{
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.1490196078);
    border-radius: 16px;
    padding: 0 15px 25px;
    text-align: center;
    margin-top: 40px;
    display: inline-block;
    width: 100%;
    img{
        display: block;
        margin: -30px auto 20px;
        width: 76px;
        max-width: 100%;
        height: auto;
    }
    h4{
        color: var(--primaryBlue);
        font-size: 15px;
        line-height: 22px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    p{
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        min-height: 63px;
        width: 220px;
        margin: 0 auto;
    }
}