.pageTitle{
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  margin-bottom: 16px;
}
.landingFilter{
  background-color: #fff;
  padding: 20px;
  border-radius: 100px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1490196078);
  margin-bottom: 25px;
  position: relative;
  @media (max-width: 640px) {
    border-radius: 0;
    padding: 20px 0 20px 15px;
    margin: 0 -15px 20px;
  }
  .loaderDiv {
    position: absolute;
    width: calc(100% - 60px);
    left: 32px;
    bottom: -2px;
  }
}

.welcomePage {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1490196078);
  padding: 70px 20px;
  text-align: center;
  .welcomeWrapper {
    width: 384px;
    max-width: 100%;
    margin: 0 auto;
    img {
      max-width: 100%;
      height: auto;
      margin-bottom: 45px;
    }
    h2 {
      font-size: 24px;
      line-height: 36px;
      font-weight: 600;
      color: var(--primaryBlue);
      margin-bottom: 8px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 45px;
      color: var(--neutral2);
    }
    .cta {
      width: 343px;
      max-width: 100%;
      margin: 0 auto;
    }
  }
}

.discussionList {
  :global{
    .infinite-scroll-component {
      overflow: unset !important;
    }
  }
}

// .filterLoader {
//   margin-bottom: 23px;
//   position: relative;
//   @media (max-width: 640px) {
//     margin-right: -15px;
//   }
 
// }

.noData{
  .noDataImg{
    display: block;
    margin: 0 auto 20px;
  }
  .noDataMsg{
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    margin-bottom: 10px;
    color: var(--primaryBlue);
    text-align: center;
  }
}

.initialLoader {
  height: 270px;
  margin: -24px -24px 0;
}