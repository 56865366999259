/* Expert Card start */
.expertsSec {
  background-color: var(--primaryBlue);
  padding: 20px 20px 20px;
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 16px;
  position: relative;
  @media (max-width: 640px) {
    margin: 0 -15px;
    border-radius: 0px;
    width: calc(100% + 30px);
    padding: 20px 15px;
  }
}

.expertBg{
  position: absolute;
  top: -20px;
  left: 0;
}

.experCardWrap {
  padding: 8px 8px;
  // flex: 0 0 25%;
  // max-width: 25%;
}
.experCard {
  padding: 16px 12px;
  border-radius: 16px;
  background-color: #fff;
  border: 1px solid #E5E5E5;
  .details{
    min-height: 115px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  img {
    height: 72px;
    width: 72px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  .expertName {
    margin-bottom: 6px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    min-height: 43px;
    transition: 0.3s ease;
    cursor: pointer;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    &:hover{
      color: var(--primaryBlue);
    }
  }
  .expertRole {
    font-size: 14px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    min-height: 19px;
    .qualification{
      line-height: 1.2;
      white-space: nowrap;
      width: 42%;
      overflow: hidden;
      text-overflow: ellipsis;
      &.isBorder {
        padding-right: 10px;
        margin-right: 10px;
        border-right: 1px solid #E0E0E0;
      }
    }
    .specialityItem{
      display: inline-block;
      padding: 0 5px;
      padding-left: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .moreSpeciality{
      position: relative;
      display: inline-block;
      .count{
        font-size: 12px;
        padding: 0 5px;
        font-weight: 600;
        color: var(--primaryBlue);
      }
      .specialityTooltipWrapper{
        display: none;
        position: absolute;
        background-color: #F0F3F5;
        padding: 8px 8px 8px;
        bottom: 50%;
        right: 100%;
        transform: translateY(50%);
        border-radius: 8px;
        &::after{
          content: "";
          position: absolute;
          top: calc(50% - 2px);
          right: -13px;
          margin-top: -6px;
          border-width: 7px;
          border-style: solid;
          border-color: transparent transparent transparent #f0f3f5;
        }
      }
      .specialityTooltip{
        font-size: 11px;
        line-height: 16px;
        min-width: 100px;
        max-width: 170px;
        max-height: 96px;
        overflow-x: hidden;
        background-color: #F0F3F5;
        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
        &::-webkit-scrollbar-track {
          box-shadow: none;
        }
        &::-webkit-scrollbar-thumb {
          background: var(--neutral2); 
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: var(--textColor);
        }
        .tooltiptext{
          display: block;
          color: var(--primaryBlue);
          font-weight: 500;
          margin-bottom: 4px;
          // white-space: nowrap;
        }
      }
      &:hover{
        .specialityTooltipWrapper{
          display: block;
        }
      }
    }
  }
  .geoLocation{
    font-size: 14px;
    color: var(--neutral2);
    margin-bottom: 5px;
  }
  .location{
    display: flex;
    align-items: center;
    color: var(--neutral2);
    font-size: 12px;
    min-height: 18px;
    svg{
      margin-right: 6px;
    }
    span{
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      max-width: calc(100% - 20px);
    }
  }
}

/* Expert Card end */


.expertDialog{
  :global {
    .MuiPaper-root {
      width: 510px;
      max-width: 100%;
      border-radius: 12px;
      margin: 16px;
      display: flex;
      flex-direction: column;
    }

    .MuiDialogContent-root {
      padding: 24px;
      position: relative;
      @media (max-width: 640px) {
        padding: 16px;
      }
    }
  }
}
.expertWrapper{
  position: relative;
  padding-top: 30px;
  max-height: 79vh;
  padding-bottom: 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  .closeCta{
    background-color: transparent;
    font-size: 30px;
    height: 30px;
    line-height: 30px;
    padding: 0;
    position: absolute;
    right: -5px;
    top: -5px;
    width: 30px;
  }
  .expertContent{

  }
  .expertImg{
    height: 120px;
    width: 120px;
    display: block;
    border-radius: 50%;
    margin: 0 auto 20px;
    object-fit: cover;
  }
  .dialogName{
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    color: var(--primaryBlue);
    width: 410px;
    max-width: 100%;
    margin: 0 auto 10px;
  }
  .dialogQualification{
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    width: 410px;
    max-width: 100%;
    margin: 0 auto 10px;
  }
  .dialogRole{
    font-size: 14px;
    line-height: 21px;
    margin: 0 auto 16px;
    width: 410px;
    max-width: 100%;
  }
  .dialogLocation{
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--neutral2);
    svg{
      margin-right: 8px;
    }
  }
  .dialogMsg{
    overflow-y: auto;
    font-size: 13px;
    line-height: 19px;
    width: 410px;
    max-width: 100%;
    margin: 16px auto 0;
    padding-right: 10px;
    flex: 1;
    &::-webkit-scrollbar {
      width: 10px; /* Width of the scrollbar */
    }
  
    &::-webkit-scrollbar-track {
      background: #f1f1f1; /* Color of the track */
    }
  
    &::-webkit-scrollbar-thumb {
      background: #888; /* Color of the scroll thumb */
      border-radius: 5px; /* Rounded corners */
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background: #555; /* Color of the scroll thumb on hover */
    }
  }
}