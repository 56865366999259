.headerContainer {
  padding: 0;
  box-shadow: 0px 4px 15px 0px #00000026;
  background-color: #fff;
  position: relative;
  @media (max-width: 1185px) {
    display: none;
    &.mobile {
      display: block;
    }
  }
}
.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1185px) {
    padding: 10px 0;
  }
}
.logNavSec {
  display: flex;
  align-items: center;
  .logo {
    height: 50px;
    width: auto;
    margin-right: 10px;
    cursor: pointer;
    @media (max-width: 767px) {
      height: 40px;
    }
  }
}
.searchUserSec {
  display: flex;
  align-items: center;

  .userSec {
    position: relative;
    img {
      display: block;
      border-radius: 50%;
    }
    // &::after{
    //     content: "";
    //     position: absolute;
    //     border-right: 7px solid transparent;
    //     border-left: 7px solid transparent;
    //     border-top: 8px solid #000;
    //     top: 16px;
    //     right: 0;
    // }
  }
}
.mobileMenu {
  display: none;
  @media (max-width: 1185px) {
    display: block;
  }
}
