.shalinaInpute{
    label{
        margin-bottom: 8px;
        font-size: 14px;
        span{
            color: var(--errorColor);
            margin-left: 4px;
        }
    }
    :global{
        .MuiInputBase-input{
            height: 48px;
            padding: 0 10px;
            font-family: var(--primaryFont);
            font-size: 16px;
            color: var(--textColor);
        }
        .MuiInputBase-root{
            &.Mui-error{
                .MuiOutlinedInput-notchedOutline{
                    border-color: var(--inputError);
                }
            }
        }
        .MuiFormHelperText-root.Mui-error{
            color: var(--inputError);
        }
    }
}

.shalinaMobileinputWrapper{
    label{
        margin-bottom: 8px;
        font-size: 14px;
        span{
            color: var(--errorColor);
            margin-left: 4px;
        }
    }
    :global{
        .MuiFormHelperText-root.Mui-error{
            color: var(--inputError);
        }
    }
}
.shalinaMobileinput{
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    :global{
        .MuiFormControl-root{
            flex: 0 0 calc(100% - 130px);
            max-width: calc(100% - 130px);
            font-family: var(--primaryFont);
        }
        .MuiInputBase-input{
            height: 48px;
            padding: 0 10px;
            font-family: var(--primaryFont);
            font-size: 16px;
            color: var(--textColor);
        }
        .MuiInputBase-root{
            &.Mui-error{
                .MuiOutlinedInput-notchedOutline{
                    border-color: var(--inputError);
                }
            }
        }
    }
}